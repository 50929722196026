body {
  background-color: #FFFFFF;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 14px;
  color: #26234e;
  margin: 0px;
}

body input {
  font-size: 14px;
  padding: 5px;
}

#header {
  margin: 0;
}

#header #headerTopContent {
  background-color: #fff;
}

#header #headerTopContent #logo {
  margin: 15px 20px;
  float: left;
}

#header #headerTopContent #logo img {
  height: 71px;
}

#header #headerTopContent .logoAnimate {
  width: 309px;
  height: 71px;
  /*
			
			&:hover{
				width: 100px;
				height: 50px;
			}
		*/
}

#header #headerTopContent #langSelect {
  float: right;
}

#header #headerTopContent #langSelect #dataContactHead {
  line-height: 44px;
}

#header #headerTopContent #langSelect #dataContactHead i {
  font-size: 20px;
  vertical-align: -3px;
}

#header #headerTopContent #langSelect #dataContactHead .fa-phone {
  transform: rotate(146deg);
}

#header #headerTopContent #langSelect #dataContactHead a {
  color: #26234e;
}

#header #headerTopContent #langSelect #dataContactHead a:hover {
  color: #87dbfb;
}

#header #headerTopContent #langSelect img {
  width: 40px;
  margin: 5px;
}

#header #banner {
  overflow: hidden;
  z-index: -1;
  position: relative;
}

#header #banner img {
  max-width: 100%;
  width: 100%;
  margin: auto;
  display: block;
  overflow: hidden;
  border-bottom: 2px solid #26234e;
}

.goog-te-combo {
  width: 100%;
}

.wrapperNav {
  background-color: #fff;
  width: 100%;
  margin: 0 0 3px 0;
  height: 40px;
}

.wrapperNav #mainNav {
  list-style-type: none;
  padding-inline-start: 0px;
  margin-block-start: 0em;
  margin-block-end: 0em;
  font-size: 16px;
}

.wrapperNav #mainNav li {
  float: left;
}

.wrapperNav #mainNav li .active {
  color: #26234e;
  background-color: #87dbfb;
}

.wrapperNav #mainNav li .active:hover {
  color: #26234e;
}

.wrapperNav #mainNav li a {
  border-radius: 18px;
  display: block;
  color: #26234e;
  text-align: center;
  padding: 9px 22px;
  text-decoration: none;
  text-transform: uppercase;
}

.wrapperNav #mainNav li a:hover {
  background-color: #87dbfb;
  color: #26234e;
}

.wrapperNav #mainNav .pageReload {
  float: right !important;
}

.wrapperNav #mainNav li .adminRed {
  padding: 9px 4px;
}

.wrapperUserNav {
  background-color: #87dbfb;
  width: 100%;
  height: 40px;
  margin: 0 0 3px 0;
}

.wrapperUserNav #mainSearchLeft {
  width: 300px;
  margin-top: -1px;
}

.wrapperUserNav #mainSearchLeft #userHistoryContainer {
  width: 100%;
  position: relative;
}

.wrapperUserNav #mainSearchLeft #userHistoryContainer #userHistoryDiv {
  background-color: #fff;
  border: 1px solid #26234e;
  margin: 6px 0 5px 20px;
  padding: 5px 0 5px 10px;
  cursor: pointer;
}

.wrapperUserNav #mainSearchLeft #userHistoryContainer #userHistoryDiv i {
  float: right;
  background: #eeeeee;
  height: 29px;
  width: 30px;
  text-align: center;
  line-height: 30px;
  margin-top: -5px;
  font-size: 20px;
  border-left: 1px solid #26234e;
}

.wrapperUserNav #mainSearchLeft #userHistoryContainer #userHistoryDiv:hover i {
  background-color: #26234e;
  color: #fff;
}

.wrapperUserNav #mainSearchLeft #userHistoryContentBoxContainer {
  position: relative;
  display: none;
}

.wrapperUserNav #mainSearchLeft #userHistoryContentBoxContainer .userHistoryAdminMenuSpinner {
  font-size: 20px;
  display: none;
}

.wrapperUserNav #mainSearchLeft #userHistoryContentBoxContainer #userHistoryContentBox {
  position: absolute;
  min-width: 1000px;
  max-width: 1000px;
  margin-left: 20px;
  margin-top: -5px;
  z-index: 100;
  background-color: #fff;
  border: 1px solid #26234e;
  border-top: none;
  padding: 10px;
}

.wrapperUserNav #mainSearchLeft #userHistoryContentBoxContainer #userHistoryContentBox #userHistoryAdmin div {
  margin-left: 10px;
}

.wrapperUserNav #mainSearchLeft #userHistoryContentBoxContainer #userHistoryContentBox #userHistoryAdmin .notDisplayAdmin {
  background-color: red;
}

.wrapperUserNav #mainSearchLeft #userHistoryContentBoxContainer #userHistoryContentBox .userHistoryResultContainerAdmin {
  max-height: 1500px !important;
}

.wrapperUserNav #mainSearchLeft #userHistoryContentBoxContainer #userHistoryContentBox #userHistoryResultContainer {
  border-top: 1px solid #26234e;
  max-height: 900px;
  overflow-y: scroll;
}

.wrapperUserNav #mainSearchLeft #userHistoryContentBoxContainer #userHistoryContentBox #userHistoryResultContainer .spinContainer {
  width: 100%;
  margin-top: 10px;
  font-size: 40px;
  text-align: center;
}

.wrapperUserNav #mainSearchLeft #userHistoryContentBoxContainer #userHistoryContentBox #userHistoryResultContainer table {
  width: 100%;
  font-size: 12px;
}

.wrapperUserNav #mainSearchLeft #userHistoryContentBoxContainer #userHistoryContentBox #userHistoryResultContainer table a {
  color: green;
}

.wrapperUserNav #mainSearchLeft #userHistoryContentBoxContainer #userHistoryContentBox #userHistoryResultContainer table .adminRed {
  color: red !important;
}

.wrapperUserNav #mainSearchLeft #userHistoryContentBoxContainer #userHistoryContentBox #userHistoryResultContainer .itemNotFound {
  color: #0072ff;
}

.wrapperUserNav #mainSearchRight {
  padding: 5px;
  margin-left: 300px;
  margin-right: 30px;
}

.wrapperUserNav #mainSearchRight .marginTop-1 {
  margin-top: -1px;
}

.wrapperUserNav #mainSearchRight .searchInputClear {
  display: inline-block;
  height: 30px;
  line-height: 30px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  margin-right: -5px;
  border: 1px solid #26234e;
  background: #eeeeee;
  color: #26234e;
  cursor: pointer;
}

.wrapperUserNav #mainSearchRight .searchInputClear:hover {
  background: #26234e;
  color: #fff;
}

.wrapperUserNav #mainSearchRight .searchInputClear .searchLoadingIcon {
  position: absolute;
  margin-left: 10px;
  margin-top: 7px;
  display: none;
  color: #17B40B;
}

.wrapperUserNav #mainSearchRight .searchInputGo {
  display: inline-block;
  height: 30px;
  line-height: 30px;
  width: 30px;
  text-align: center;
  margin-left: -4px;
  border: 1px solid #26234e;
  font-weight: bold;
  color: #26234e;
  cursor: pointer;
  background: url("./../../web/img/ico/ico_btnSearch16.png") no-repeat center center;
  background-color: #eeeeee;
}

.wrapperUserNav #mainSearchRight .searchInputGo:hover {
  background: url("./../../web/img/ico/ico_btnSearch16_hover.png") no-repeat center center;
  background-color: #26234e;
  color: #fff;
}

.wrapperUserNav #mainSearchRight #mainSearchInput {
  background-color: #fff;
  border: 1px solid #26234e;
  font-size: 16px;
  width: 80%;
  max-width: 1200px;
  padding: 0 10px 0 20px;
  height: 30px;
}

.wrapperUserNav #mainSearchRight ::placeholder {
  /* Most modern browsers support this now. */
  color: #26234e;
  opacity: 0.6;
}

.wrapperUserNav #mainSearchRight .searchCartIcon {
  margin-left: 20px;
  width: 32px;
  height: 32px;
  position: absolute;
  cursor: pointer;
  background: url("./../../web/img/ico/ico_cartTop.png") no-repeat top center;
  /*&:after{
					position: relative;
					margin-left: 20px;
					margin-top: -20px;
					width: 20px;
					height: 20px;
					displaY: block;
					background: $color-gray;
					//padding: 2px;
					-webkit-border-radius: 9px;
					-moz-border-radius: 9px;
					border-radius: 9px;
					text-align: center;
					
					content: '50';
				} */
}

.wrapperUserNav #mainSearchRight .searchCartIcon #countElement {
  position: relative;
  margin-left: 20px;
  margin-top: -20px;
  width: 25px;
  height: 25px;
  displaY: block;
  background: #eeeeee;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
  text-align: center;
  line-height: 25px;
}

.wrapperUserNav #mainSearchRight .searchCartIcon:hover {
  background-position: bottom;
  /*&:after {
						
					}*/
}

.wrapperUserNav #mainSearchRight .searchCartIcon:hover #countElement {
  background: #26234e;
  color: #fff;
}

.wrapperUserNav #mainSearchRight #searchResultContainer {
  z-index: 100;
  background-color: #fff;
  border: 1px solid #26234e;
  border-top: none;
  padding: 10px;
  max-width: 1200px;
  margin-left: 31px;
  position: relative;
}

.wrapperUserNav #mainSearchRight #searchResultContainer .searchResultHeadline {
  margin: -10px -10px 0 -10px;
  padding: 10px;
  background-color: #eeeeee;
  border-bottom: 1px solid #26234e;
  font-size: 15px;
}

.wrapperUserNav #mainSearchRight #searchResultContainer .searchResultSearchString {
  font-weight: bold;
}

.wrapperUserNav #mainSearchRight #searchResultContainer #searchResultGrid {
  display: grid;
  grid-template-columns: auto 30px auto 120px auto;
  grid-template-rows: [header-start] 50px [header-end row-start] 110px [row-end];
  grid-auto-rows: auto;
  grid-auto-columns: auto;
  overflow-y: scroll;
  max-height: 700px;
  background-color: #fff;
}

.wrapperUserNav #mainSearchRight #searchResultContainer #searchResultGrid .rowWrapper {
  display: contents;
  cursor: pointer;
}

.wrapperUserNav #mainSearchRight #searchResultContainer #searchResultGrid .rowWrapper:hover div {
  background-color: #87dbfb;
}

.wrapperUserNav #mainSearchRight #searchResultContainer #searchResultGrid .item,
.wrapperUserNav #mainSearchRight #searchResultContainer #searchResultGrid .header {
  padding: 5px;
  max-height: 110px;
  box-sizing: border-box;
}

.wrapperUserNav #mainSearchRight #searchResultContainer #searchResultGrid .item {
  height: 110px;
  cursor: pointer;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
}

.wrapperUserNav #mainSearchRight #searchResultContainer #searchResultGrid .headerName,
.wrapperUserNav #mainSearchRight #searchResultContainer #searchResultGrid .resultName {
  grid-column: 1;
  -ms-grid-column: 1;
}

.wrapperUserNav #mainSearchRight #searchResultContainer #searchResultGrid .headerName a,
.wrapperUserNav #mainSearchRight #searchResultContainer #searchResultGrid .resultName a {
  text-decoration: none;
  color: #26234e;
}

.wrapperUserNav #mainSearchRight #searchResultContainer #searchResultGrid .resultNoPrice:hover {
  text-decoration: underline;
}

.wrapperUserNav #mainSearchRight #searchResultContainer #searchResultGrid .itemType,
.wrapperUserNav #mainSearchRight #searchResultContainer #searchResultGrid .resultItemType {
  -ms-grid-column: 2;
  grid-column: 2;
}

.wrapperUserNav #mainSearchRight #searchResultContainer #searchResultGrid .itemType div,
.wrapperUserNav #mainSearchRight #searchResultContainer #searchResultGrid .resultItemType div {
  height: 19px;
}

.wrapperUserNav #mainSearchRight #searchResultContainer #searchResultGrid .headerPrice,
.wrapperUserNav #mainSearchRight #searchResultContainer #searchResultGrid .resultPrice {
  grid-column: 3;
  -ms-grid-column: 3;
  text-align: right;
}

.wrapperUserNav #mainSearchRight #searchResultContainer #searchResultGrid .headerPrice div,
.wrapperUserNav #mainSearchRight #searchResultContainer #searchResultGrid .resultPrice div {
  height: 19px;
}

.wrapperUserNav #mainSearchRight #searchResultContainer #searchResultGrid .headerImg,
.wrapperUserNav #mainSearchRight #searchResultContainer #searchResultGrid .resultImage {
  grid-column: 4;
  -ms-grid-column: 4;
}

.wrapperUserNav #mainSearchRight #searchResultContainer #searchResultGrid .headerDesc,
.wrapperUserNav #mainSearchRight #searchResultContainer #searchResultGrid .resultDesc {
  grid-column: 5;
  -ms-grid-column: 5;
  overflow: hidden;
}

.wrapperUserNav #mainSearchRight #searchResultContainer #searchResultGrid .resultImage {
  justify-self: center;
  width: 100%;
}

.wrapperUserNav #mainSearchRight #searchResultContainer #searchResultGrid .resultImage .itemImg {
  width: 100px;
}

.wrapperUserNav #mainSearchRight #searchResultContainer #searchResultGrid .resultPrice div,
.wrapperUserNav #mainSearchRight #searchResultContainer #searchResultGrid .headerPrice div {
  width: 100%;
  text-align: right;
}

.wrapperUserNav #mainSearchRight #searchResultContainer #searchResultGrid .hovered {
  border-top: 2px solid #87dbfb;
  border-bottom: 2px solid #87dbfb;
  cursor: pointer;
}

.wrapperUserNav #mainSearchRight #searchResultContainer #searchResultGrid .hoverThis {
  color: blue;
  text-decoration: underline;
}

.wrapperUserNav #mainSearchRight #searchResultContainer #searchResultGrid .hoverThis .itemLink {
  color: blue;
}

#fancyBoxNewDesign {
  width: 600px;
  display: none;
}

#fancyBoxNewDesign .newDesignTextArea {
  width: 100%;
  height: 200px;
}

#home {
  max-width: 1000px;
  position: relative;
}

#home .arrowUp {
  position: absolute;
  left: 380px;
  top: 0;
}

#home #homeContainer {
  position: relative;
}

#home #homeContainer h1 {
  font-size: 20px;
}

#home #homeContainer h1 .homeHide {
  float: right;
  color: #26234e;
  cursor: pointer;
}

#home #homeContainer h1 .homeHide:hover {
  color: #87dbfb;
}

#home #homeContainer .homeNewShop {
  background: url("./../../web/img/home_newbadge.png") no-repeat 0 0px;
  text-indent: 60px;
}

#home #homeContainer .homeNewShop h1 {
  color: #87dbfb;
  margin-bottom: 0px;
  margin-top: 5px;
}

#home #homeContainer .homeSlideContainer {
  max-width: 1000px;
}

#home #homeContainer .homeSlideContainer .main {
  max-width: 580px;
  hyphens: auto;
  text-align: justify;
  margin-left: 20px;
}

#home #homeContainer .homeSlideContainer .rowImg {
  width: 100%;
}

#home #homeContainer .homeSlideContainer .rowImg img {
  width: 100%;
}

#home #homeContainer .homeSlideContainer .action {
  margin: 10px auto;
  max-width: 330px;
}

#home #homeContainer .homeSlideContainer .action a {
  padding: 5px 20px;
  margin-bottom: 5px;
  text-decoration: none;
  border: none;
  font-size: 16px;
}

#home #homeContainer .homeSlideContainer .slick-next {
  right: 0;
}

#home #homeContainer .homeSlideContainer .slick-next:before {
  content: "\00276D" !important;
  font-size: 150px;
}

#home #homeContainer .homeSlideContainer .slick-prev {
  left: 0;
}

#home #homeContainer .homeSlideContainer .slick-prev:before {
  content: "\00276C" !important;
  font-size: 150px;
}

#home #homeContainer .homeSlideContainer .slick-arrow {
  top: 200px;
  width: 50px;
  height: 516px;
  color: black;
  z-index: 10;
}

#home #homeContainer .homeSlideContainer .activeSlide {
  background-color: #87dbfb !important;
}

#home #homeContainer .flex-container {
  max-width: 1000px;
  padding: 0;
  margin: 0;
  list-style: none;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
  justify-content: space-around;
}

#home #homeContainer .flex-item {
  background: #fff;
  width: 300px;
  height: 220px;
  text-align: center;
}

#home #homeContainer .flex-item i {
  width: 100%;
  font-size: 40px;
  text-align: center;
}

#home #homeContainer .flex-item h2 {
  text-align: left;
}

#home #homeContainer .flex-item .text {
  text-align: center;
  font-size: 16px;
  hyphens: auto;
  text-align: justify;
}

#home #homeContainer .homeRow {
  /*img {
				width: 400px;
				height: 200px;
				overflow: hidden;
			}*/
}

#home #homeContainer .homeRow .rowtext .textContainer {
  width: 100%;
}

#home #homeContainer .homeRow .rowtext .textContainer .text {
  font-size: 16px;
  width: 450px;
  hyphens: auto;
  text-align: justify;
}

#faq #faqContentContainer {
  max-width: 800px;
}

#faq #faqContentContainer .faqRow {
  border: 1px dotted #26234e;
  border-width: 1px 0 0 0;
}

#faq #faqContentContainer .faqRow .active .fas {
  transform: rotate(0deg) !important;
}

#faq #faqContentContainer .faqRow .active .fas:hover {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(-90deg) !important;
}

#faq #faqContentContainer .faqRow .faqHeadline {
  font-weight: normal;
  margin: 0;
  padding: 10px;
  cursor: pointer;
}

#faq #faqContentContainer .faqRow .faqHeadline .fas {
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  transform: rotate(-90deg);
}

#faq #faqContentContainer .faqRow .faqHeadline .chevRotateDown {
  transform: rotate(90deg);
}

#faq #faqContentContainer .faqRow .faqHeadline:hover {
  background-color: #eeeeee;
}

#faq #faqContentContainer .faqRow .faqHeadline:hover .fas {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(0deg);
}

#faq #faqContentContainer .faqRow .faqContent {
  display: none;
  margin-top: 10px;
  padding: 10px;
}

#faq #faqContentContainer .faqRow .faqContent h4 {
  margin-top: 0px;
}

#faq #faqContentContainer .faqRow .faqContent .colLeftLogin {
  min-width: 90px;
}

#faq #faqContentContainer .faqRow .faqContent .inputLogin {
  width: 300px;
  min-width: 200px;
}

#faq #faqContentContainer .faqRow .faqContent select {
  padding: 5px;
  width: 313px;
  min-width: 200px;
  font-size: 14px;
  border: 1px solid #bbb;
}

#faq #faqContentContainer .faqRow .faqContent input {
  border: 1px solid #bbb;
}

#faq #faqContentContainer .faqRow .faqContent input:required {
  background: #f8f1c2;
}

#faq #faqContentContainer .borderBot {
  border-width: 1px 0 1px 0;
}

.cartOrderSubmitContentBox {
  background: #eeeeee;
  max-width: 1000px;
  padding: 20px;
}

.cartOrderSubmitContentBox h1 {
  text-align: center;
  margin: 0;
}

.cartOrderSubmitContentBox h1 .contentHide {
  font-size: 16px;
  cursor: pointer;
  font-weight: auto;
}

.cartOrderSubmitContentBox h1 .contentHide:hover {
  color: #87dbfb;
}

.cartOrderSubmitContentBox .cartOrderSubmitText {
  text-align: center;
}

.cartOrderSubmitContentBox .cartOrderSubmitText .buttonEff {
  width: 200px;
  float: left;
}

.cartMainContainerNoCartBox {
  width: 100%;
  margin-bottom: 20px;
  position: relative;
}

.cartMainContainerNoCartBox .cartMainContainerNoCartBoxContent {
  margin: auto;
  padding: 10px;
  text-align: center;
  background: #eeeeee;
  border-radius: 20px;
}

.cartMainContainerNoCartBox .cartMainContainerNoCartBoxContent .cartMainContainerNoCartBoxHide {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 10px;
  font-weight: normal;
  color: #26234e;
  transform: rotate(45deg);
  font-size: 20px;
}

.cartMainContainerNoCartBox .cartMainContainerNoCartBoxContent .cartMainContainerNoCartBoxHide:hover {
  color: #87dbfb;
}

#itemDetailDisplay h1 {
  margin: 0;
}

#itemDetailDisplay h1 .headlineAvailability {
  font-size: 14px;
  line-height: 26px;
  margin-left: 10px;
  color: #17B40B;
}

#itemDetailDisplay h1 .headlineAvailability i {
  font-size: 16px;
}

#itemDetailDisplay h1 #headlineAvailabilityOnRequest {
  font-size: 14px;
  line-height: 26px;
  color: #ff9601;
}

#itemDetailDisplay h1 #headlineAvailabilityOnRequest i {
  font-size: 16px;
}

#itemDetailDisplay h1 #headlineAvailabilityOnRequest div {
  padding-left: 10px;
}

#itemDetailDisplay h1 #headlineAvailabilityOnRequest div:hover {
  text-decoration: underline;
}

#itemDetailDisplay h1 .itemDetailDayPrice {
  font-size: 14px;
  line-height: 26px;
  margin-left: 10px;
}

#itemDetailDisplay h1 .itemDetailDayPrice #itemDetailRequestPrice {
  color: blue;
  text-decoration: none;
}

#itemDetailDisplay h1 .itemDetailDayPrice #itemDetailRequestPrice:hover {
  text-decoration: underline;
}

#itemDetailDisplay h1 .itemDetailDayPrice #itemDetailRequestPrice:visited,
#itemDetailDisplay h1 .itemDetailDayPrice #itemDetailRequestPrice,
#itemDetailDisplay h1 .itemDetailDayPrice #itemDetailRequestPrice .resultNoPrice:focus,
#itemDetailDisplay h1 .itemDetailDayPrice #itemDetailRequestPrice:active {
  color: blue !important;
}

#itemDetailDisplay .itemDetailBtnContainerTopRight {
  margin-top: -10px;
  width: 465px;
}

#itemDetailDisplay #itemDetailGridTop {
  display: -ms-grid;
  -ms-grid-columns: 300px 2.5fr 320px;
  display: grid;
  grid-template-columns: 300px minmax(300px, auto) 320px;
  column-gap: 20px;
  margin-top: 10px;
}

#itemDetailDisplay #itemDetailGridTop div:nth-child(3n+1) {
  -ms-grid-column: 1;
}

#itemDetailDisplay #itemDetailGridTop div:nth-child(3n+2) {
  -ms-grid-column: 2;
}

#itemDetailDisplay #itemDetailGridTop div:nth-child(3n+ 3) {
  -ms-grid-column: 3;
}

#itemDetailDisplay #itemDetailGridTop .itemTableGrid {
  margin-top: 20px;
  display: -ms-grid;
  -ms-grid-columns: 110px auto;
  display: grid;
  grid-template-columns: 110px auto;
}

#itemDetailDisplay #itemDetailGridTop .itemTableGrid div:nth-child(2n + 1) {
  -ms-grid-column: 1;
}

#itemDetailDisplay #itemDetailGridTop .itemTableGrid div:nth-child(2n + 2) {
  -ms-grid-column: 2;
}

#itemDetailDisplay #itemDetailGridTop .itemRightColGrid {
  padding: 20px;
  background: #eeeeee;
  display: -ms-grid;
  -ms-grid-columns: 140px auto;
  display: grid;
  grid-template-columns: 140px auto;
  position: relative;
}

#itemDetailDisplay #itemDetailGridTop .itemRightColGrid div:nth-child(2n + 1) {
  -ms-grid-column: 1;
}

#itemDetailDisplay #itemDetailGridTop .itemRightColGrid div:nth-child(2n + 2) {
  -ms-grid-column: 2;
}

#itemDetailDisplay #itemDetailGridTop .itemRightColGrid .copyItemRightColIcon {
  position: absolute;
  right: 0;
  color: red;
  margin-right: 5px;
  margin-top: 5px;
  cursor: pointer;
}

#itemDetailDisplay #itemDetailGridTop .itemRightColGrid .copyItemRightColIcon:hover {
  color: #87dbfb;
}

#itemDetailDisplay #itemDetailGridItemTypes {
  display: -ms-grid;
  -ms-grid-columns: 275px 275px 275px 275px;
  display: grid;
  grid-template-columns: 275px 275px 275px 275px;
  column-gap: 20px;
  margin-top: 20px;
}

#itemDetailDisplay #itemDetailGridItemTypes .inactive {
  opacity: 0.7;
}

#itemDetailDisplay #itemDetailGridItemTypes .buttonEff {
  width: 100%;
  text-align: left;
}

#itemDetailDisplay #itemDetailGridItemTypes .itemTypeBox {
  border: 1px solid #dedede;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  /*	&:hover {
				background-color: $color-gray;
			}
		*/
}

#itemDetailDisplay #itemDetailGridItemTypes .itemTypeBox .headline {
  background-color: #fff;
}

#itemDetailDisplay #itemDetailGridItemTypes .itemTypeBox .headline h3 {
  margin: 5px 10px;
}

#itemDetailDisplay #itemDetailGridItemTypes .itemTypeBox .contentGrid {
  display: grid;
  grid-template-columns: 125px auto;
  padding: 5px 10px;
}

#itemDetailDisplay #itemDetailGridItemTypes .itemTypeBox .contentGrid .itemPrice {
  font-weight: bold;
  font-size: 20px;
}

#itemDetailDisplay #itemDetailGridItemTypes .itemTypeBox .contentGrid .itemPrice .itemDetailRequestPrice {
  font-size: 14px;
  color: blue;
  text-decoration: none;
}

#itemDetailDisplay #itemDetailGridItemTypes .itemTypeBox .contentGrid .itemPrice .itemDetailRequestPrice:hover {
  text-decoration: underline;
}

#itemDetailDisplay #itemDetailGridItemTypes .itemTypeBox .contentGrid .rowBtn {
  grid-column: 1 / span 2;
}

#itemDetailDisplay #itemDetailGridItemTypes .itemTypeBox .contentGrid .itemDetailQtyInput {
  width: 50px;
  text-align: center;
}

#itemDetailDisplay #itemDetailGridItemTypes .itemTypeBox .contentGrid .itemDetailSelectQty {
  padding: 5px;
  width: 65px;
}

#itemDetailDisplay #itemDetailGridItemTypes .itemTypeBox .contentGrid .disabled {
  background: #cccccc;
}

#itemDetailDisplay #itemDetailGridItemTypes .itemTypeBox .contentGrid .itemQtyLimit {
  border: 1px solid red;
}

#itemDetailDisplay #itemDetailGridItemTypes .itemTypeBox .itemBoxRep input {
  font-size: 14px !important;
  margin: 0px !important;
  padding: 0px !important;
}

#itemDetailDisplay #itemDetailGridItemTypes .itemTypeBox .itemBoxRep textarea {
  font-size: 14px;
  margin: 0px;
  padding: 0px;
  height: 20px;
}

#itemDetailDisplay #itemDetailSpareParts {
  margin-top: 20px;
}

#itemDetailDisplay #itemDetailSpareParts a {
  color: #26234e;
  font-size: 13px;
}

#itemDetailDisplay #itemDetailSpareParts .spareBox {
  width: 250px;
  margin-right: 10px;
  margin-top: 10px;
  float: left;
  cursor: pointer;
  border: 1px solid #dedede;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

#itemDetailDisplay #itemDetailSpareParts .spareBox .spareImg {
  padding: 5px;
  width: 70px;
  height: 50px;
}

#itemDetailDisplay #itemDetailSpareParts .spareBox .spareHeadline {
  padding: 5px;
  width: 160px;
}

#itemDetailDisplay #itemDetailSpareParts .spareBox .spareAvailability {
  padding: 5px;
}

#itemDetailDisplay #itemDetailSpareParts .spareBox .spareDesc {
  padding: 5px;
  height: 70px;
  overflow-y: hidden;
}

#itemDetailDisplay #itemDetailSpareParts .spareBox:hover {
  border: 1px solid #87dbfb;
}

#itemDetailDisplay #itemDetailSpareParts .spareBox:hover .spareHeadline {
  text-decoration: underline;
}

#cartDetailDisplay,
#offerMainContainer {
  /*	.cartDetailGridTop {
		display: grid;
		grid-template-columns: 150px 90px auto;
		column-gap: 0;
		row-gap: 20px;
		
		.btn-hover-effect {
			padding-right: 0 !important;
		}
				
		.cartSpanEdit {
			font-size: 14px;
		    //align-self: right;
		    //float: right;
		    margin-right: 20px;
		    font-weight: normal;
		}
		
		.colHeader {
			padding: 0 20px;
			height: 50px;
			background: $color-gray;
			box-shadow: 0 2px 0 0 #d1d4d3;
			
			h3 {
				margin-top: 15px;
				margin-bottom: 0;
				font-size: 16px;
					
			}

		}
		
		.colContent {
			padding: 0 20px;
			
			.cartSpanSelectedShipment{
				img {
					vertical-align: middle;
				}
			}
			
			.cartChangedShippingCountryWarning {
				//display: none;
				color: red;
			}
		}
	
		.btnDeleteFileDelNote, .btnDeleteFileDelProjectFile {
			cursor: pointer;
			&:hover {
				//olor: $color-orange;
			}
		}
		
		
	} */
}

#cartDetailDisplay h1,
#offerMainContainer h1 {
  margin: 0;
}

#cartDetailDisplay h1 .cartImg,
#offerMainContainer h1 .cartImg {
  margin-bottom: -6px;
  margin-right: 10px;
  width: 55px;
}

#cartDetailDisplay h1 .lineHeight50,
#offerMainContainer h1 .lineHeight50 {
  line-height: 50px;
}

#cartDetailDisplay h1 .lineHeight38,
#offerMainContainer h1 .lineHeight38 {
  line-height: 38px;
}

#cartDetailDisplay h1 .cartDetailHeadlineChangeName,
#offerMainContainer h1 .cartDetailHeadlineChangeName {
  line-height: 0;
  font-size: 14px;
  font-weight: normal;
}

#cartDetailDisplay h1 .cartDetailHeadlineChangeName:hover,
#offerMainContainer h1 .cartDetailHeadlineChangeName:hover {
  color: #17B40B;
  cursor: pointer;
}

#cartDetailDisplay h1 .cartDetailFurtherCartsContainer,
#offerMainContainer h1 .cartDetailFurtherCartsContainer {
  float: right;
  font-size: 14px;
  font-weight: normal;
  margin-top: -15px;
  position: relative;
}

#cartDetailDisplay h1 .cartDetailFurtherCartsContainer .cartLoopHeadline,
#offerMainContainer h1 .cartDetailFurtherCartsContainer .cartLoopHeadline {
  padding-top: 22px;
  padding-right: 10px;
}

#cartDetailDisplay h1 .cartDetailFurtherCartsContainer .cartLoopContainer,
#offerMainContainer h1 .cartDetailFurtherCartsContainer .cartLoopContainer {
  width: 100px;
  cursor: pointer;
  text-align: center;
  padding: 5px;
  text-decoration: none;
  color: #26234e;
  /*&:after{
					position: relative;
					margin-left: 20px;
					margin-top: -20px;
					width: 20px;
					height: 20px;
					displaY: block;
					background: $color-gray;
					//padding: 2px;
					-webkit-border-radius: 9px;
					-moz-border-radius: 9px;
					border-radius: 9px;
					text-align: center;
					
					content: '50';
				} */
}

#cartDetailDisplay h1 .cartDetailFurtherCartsContainer .cartLoopContainer #countElement,
#offerMainContainer h1 .cartDetailFurtherCartsContainer .cartLoopContainer #countElement {
  position: relative;
  margin-left: 59px;
  margin-top: -40px;
  margin-bottom: 15px;
  width: 25px;
  height: 25px;
  display: block;
  background: #eeeeee;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
  text-align: center;
  line-height: 25px;
}

#cartDetailDisplay h1 .cartDetailFurtherCartsContainer .cartLoopContainer:hover,
#offerMainContainer h1 .cartDetailFurtherCartsContainer .cartLoopContainer:hover {
  background-color: #87dbfb;
}

#cartDetailDisplay h1 .cartDetailFurtherCartsContainer .cartLoopContainer:hover #countElement,
#offerMainContainer h1 .cartDetailFurtherCartsContainer .cartLoopContainer:hover #countElement {
  background: #26234e;
  color: #fff;
}

#cartDetailDisplay h1 .cartDetailFurtherCartsContainer .cartOverViewBox,
#offerMainContainer h1 .cartDetailFurtherCartsContainer .cartOverViewBox {
  border: 1px solid #26234e;
  border-radius: 5px;
  background-color: #fff;
  position: absolute;
  top: 80px;
  right: 0;
  z-index: 2;
  width: 350px;
}

#cartDetailDisplay h1 .cartDetailFurtherCartsContainer .cartOverViewBox h4,
#offerMainContainer h1 .cartDetailFurtherCartsContainer .cartOverViewBox h4 {
  border-radius: 5px;
  margin: 0;
  padding: 10px;
  border-bottom: 1px solid #26234e;
  background-color: #fff;
}

#cartDetailDisplay h1 .cartDetailFurtherCartsContainer .cartOverViewBox .cartOverViewBoxItemList,
#offerMainContainer h1 .cartDetailFurtherCartsContainer .cartOverViewBox .cartOverViewBoxItemList {
  border-radius: 0 0 5px 5px;
  background-color: #eeeeee;
  padding: 10px;
}

#cartDetailDisplay h1 .cartDetailFurtherCartsContainer .cartOverViewBox .cartOverViewBoxItemList .width25px,
#offerMainContainer h1 .cartDetailFurtherCartsContainer .cartOverViewBox .cartOverViewBoxItemList .width25px {
  width: 25px;
}

#cartDetailDisplay h1 .cartDetailFurtherCartsContainer .cartOverViewBox .cartOverViewBoxItemList .padding5,
#offerMainContainer h1 .cartDetailFurtherCartsContainer .cartOverViewBox .cartOverViewBoxItemList .padding5 {
  padding: 3px;
}

#cartDetailDisplay h1 .cartDetailFurtherCartsContainer .cartOverViewBox .cartOverViewBoxItemList a,
#offerMainContainer h1 .cartDetailFurtherCartsContainer .cartOverViewBox .cartOverViewBoxItemList a {
  color: #26234e;
  text-decoration: underline;
}

#cartDetailDisplay h1 .cartDetailFurtherCartsContainer .cartOverViewBox .cartOverViewBoxItemList a:hover,
#offerMainContainer h1 .cartDetailFurtherCartsContainer .cartOverViewBox .cartOverViewBoxItemList a:hover {
  color: #87dbfb;
}

#cartDetailDisplay h1 .itemDetailBtnContainerTopRight,
#offerMainContainer h1 .itemDetailBtnContainerTopRight {
  float: right;
}

#cartDetailDisplay #adminCartCommentDelete,
#offerMainContainer #adminCartCommentDelete {
  cursor: pointer;
}

#cartDetailDisplay #cartMenuContainer,
#offerMainContainer #cartMenuContainer {
  position: relative;
  width: 450px;
}

#cartDetailDisplay #cartMenuContainer .cartMenuMainBtn,
#offerMainContainer #cartMenuContainer .cartMenuMainBtn {
  cursor: pointer;
  border: 1px solid #26234e;
  padding: 5px 10px;
  width: 100%;
}

#cartDetailDisplay #cartMenuContainer .cartMenuMainBtn .fa-cog,
#offerMainContainer #cartMenuContainer .cartMenuMainBtn .fa-cog {
  font-size: 20px;
  margin-top: -5px;
  margin-bottom: -5px;
  padding: 0;
  line-height: 27px;
}

#cartDetailDisplay #cartMenuContainer .cartMenuMainBtn .fa-chevron-down,
#offerMainContainer #cartMenuContainer .cartMenuMainBtn .fa-chevron-down {
  background: #eeeeee;
  height: 29px;
  width: 30px;
  text-align: center;
  line-height: 30px;
  margin-top: -5px;
  margin-right: -10px;
  font-size: 20px;
  border-left: 1px solid #26234e;
}

#cartDetailDisplay #cartMenuContainer .cartMenuMainBtn:hover .fa-chevron-down,
#offerMainContainer #cartMenuContainer .cartMenuMainBtn:hover .fa-chevron-down {
  color: #fff;
  background-color: #26234e;
}

#cartDetailDisplay #cartMenuContainer .cartMenuDropDownContainer,
#offerMainContainer #cartMenuContainer .cartMenuDropDownContainer {
  display: none;
  position: absolute;
  z-index: 50;
  padding: 5px 10px;
  background: #fff;
  border: 1px solid #26234e;
  border-top: none;
  width: 100%;
}

#cartDetailDisplay #cartMenuContainer .cartMenuDropDownContainer div,
#offerMainContainer #cartMenuContainer .cartMenuDropDownContainer div {
  margin-top: 5px;
}

#cartDetailDisplay #cartMenuContainer .cartMenuDropDownContainer div button,
#offerMainContainer #cartMenuContainer .cartMenuDropDownContainer div button {
  width: 100%;
}

#cartDetailDisplay #cartMenuContainer .cartMenuDropDownContainer div .btnIconOrderCartArrow,
#offerMainContainer #cartMenuContainer .cartMenuDropDownContainer div .btnIconOrderCartArrow {
  line-height: inherit;
}

#cartDetailDisplay #cartMenuBtnShort,
#offerMainContainer #cartMenuBtnShort {
  margin-left: 30px;
}

#cartDetailDisplay #cartMenuBtnShort .buttonEff,
#offerMainContainer #cartMenuBtnShort .buttonEff {
  width: 42px;
  height: 31px;
  margin-left: 10px;
}

#cartDetailDisplay #cartMenuBtnShort .btnIconPrint:after,
#cartDetailDisplay #cartMenuBtnShort .btnIconEdit:after,
#cartDetailDisplay #cartMenuBtnShort .btnIconEmail:after,
#cartDetailDisplay #cartMenuBtnShort .btnIconDelete:after,
#cartDetailDisplay #cartMenuBtnShort .btnIconClipboard:after,
#cartDetailDisplay #cartMenuBtnShort .btnIconOrderCartArrow:after,
#cartDetailDisplay #cartMenuBtnShort .btnIconUpload:after,
#offerMainContainer #cartMenuBtnShort .btnIconPrint:after,
#offerMainContainer #cartMenuBtnShort .btnIconEdit:after,
#offerMainContainer #cartMenuBtnShort .btnIconEmail:after,
#offerMainContainer #cartMenuBtnShort .btnIconDelete:after,
#offerMainContainer #cartMenuBtnShort .btnIconClipboard:after,
#offerMainContainer #cartMenuBtnShort .btnIconOrderCartArrow:after,
#offerMainContainer #cartMenuBtnShort .btnIconUpload:after {
  font-size: 20px !important;
}

#cartDetailDisplay .cartDetailGridTop,
#offerMainContainer .cartDetailGridTop {
  display: -ms-grid;
  -ms-grid-columns: 1fr 1fr 1fr;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 20px;
  row-gap: 20px;
  margin-top: 20px;
}

#cartDetailDisplay .cartDetailGridTop .gridColItem,
#offerMainContainer .cartDetailGridTop .gridColItem {
  background: #eeeeee;
}

#cartDetailDisplay .cartDetailGridTop .buttonEff,
#offerMainContainer .cartDetailGridTop .buttonEff {
  background-color: #fff;
}

#cartDetailDisplay .cartDetailGridTop .btn-hover-effect,
#offerMainContainer .cartDetailGridTop .btn-hover-effect {
  padding-left: 20px !important;
  padding-right: 0 !important;
}

#cartDetailDisplay .cartDetailGridTop .btn-hover-effect--effect-1:before,
#offerMainContainer .cartDetailGridTop .btn-hover-effect--effect-1:before {
  background-color: #87dbfb;
  opacity: 0.2;
}

#cartDetailDisplay .cartDetailGridTop .btn-hover-effect--effect-1:after,
#offerMainContainer .cartDetailGridTop .btn-hover-effect--effect-1:after {
  left: auto;
  right: 0 !important;
}

#cartDetailDisplay .cartDetailGridTop .cartSpanEdit,
#offerMainContainer .cartDetailGridTop .cartSpanEdit {
  font-size: 14px;
  margin-right: 20px;
  font-weight: normal;
}

#cartDetailDisplay .cartDetailGridTop .cartSpanEdit:hover,
#offerMainContainer .cartDetailGridTop .cartSpanEdit:hover {
  text-decoration: underline;
}

#cartDetailDisplay .cartDetailGridTop .colHeader,
#offerMainContainer .cartDetailGridTop .colHeader {
  padding: 0 20px;
  height: 50px;
  background: #eeeeee;
  box-shadow: 0 2px 0 0 #d1d4d3;
}

#cartDetailDisplay .cartDetailGridTop .colHeader h3,
#offerMainContainer .cartDetailGridTop .colHeader h3 {
  margin-top: 15px;
  margin-bottom: 0;
  font-size: 16px;
}

#cartDetailDisplay .cartDetailGridTop .colContent,
#offerMainContainer .cartDetailGridTop .colContent {
  padding: 10px 20px;
  background-color: #eeeeee;
}

#cartDetailDisplay .cartDetailGridTop .colContent .cartSpanSelectedShipment img,
#offerMainContainer .cartDetailGridTop .colContent .cartSpanSelectedShipment img {
  vertical-align: middle;
}

#cartDetailDisplay .cartDetailGridTop .colContent .cartSpanSelectedShipment .shipmentSelectRow,
#offerMainContainer .cartDetailGridTop .colContent .cartSpanSelectedShipment .shipmentSelectRow {
  opacity: 0.6;
}

#cartDetailDisplay .cartDetailGridTop .colContent .cartSpanSelectedShipment .shipmentSelectRow:hover,
#offerMainContainer .cartDetailGridTop .colContent .cartSpanSelectedShipment .shipmentSelectRow:hover {
  opacity: 1;
}

#cartDetailDisplay .cartDetailGridTop .colContent .cartSpanSelectedShipment .selectedShipmentRow,
#offerMainContainer .cartDetailGridTop .colContent .cartSpanSelectedShipment .selectedShipmentRow {
  opacity: 1 !important;
}

#cartDetailDisplay .cartDetailGridTop .colContent .cartSpanSelectedShipment .shimpentSelectContainer,
#offerMainContainer .cartDetailGridTop .colContent .cartSpanSelectedShipment .shimpentSelectContainer {
  max-width: 500px;
}

#cartDetailDisplay .cartDetailGridTop .colContent .test1234,
#offerMainContainer .cartDetailGridTop .colContent .test1234 {
  background: red;
}

#cartDetailDisplay .cartDetailGridTop .colContent .cartChangedShippingCountryWarning,
#offerMainContainer .cartDetailGridTop .colContent .cartChangedShippingCountryWarning {
  color: red;
}

#cartDetailDisplay .cartDetailGridTop .colContent .fa-check-circle,
#offerMainContainer .cartDetailGridTop .colContent .fa-check-circle {
  display: none;
  float: left;
  margin: 0 20px 0 0;
  font-size: 24px;
}

#cartDetailDisplay .cartDetailGridTop .changed,
#offerMainContainer .cartDetailGridTop .changed {
  color: #17B40B;
}

#cartDetailDisplay .cartDetailGridTop .changed .fa-check-circle,
#offerMainContainer .cartDetailGridTop .changed .fa-check-circle {
  display: block;
}

#cartDetailDisplay .cartDetailGridTop .btnDeleteFileDelNote,
#cartDetailDisplay .cartDetailGridTop .btnDeleteFileDelProjectFile,
#offerMainContainer .cartDetailGridTop .btnDeleteFileDelNote,
#offerMainContainer .cartDetailGridTop .btnDeleteFileDelProjectFile {
  cursor: pointer;
}

#cartDetailDisplay .cartDetailGridTop .adminERPData #adminCartDetailErpInfoTableSave,
#cartDetailDisplay .cartDetailGridTop .adminERPData #adminCartDetailErpInfoTableShowAllItemPos,
#offerMainContainer .cartDetailGridTop .adminERPData #adminCartDetailErpInfoTableSave,
#offerMainContainer .cartDetailGridTop .adminERPData #adminCartDetailErpInfoTableShowAllItemPos {
  margin-left: 20px;
  padding: 3px;
  font-weight: normal;
  cursor: pointer;
}

#cartDetailDisplay .cartDetailGridTop .adminERPData #adminERPNotizenVorgang,
#offerMainContainer .cartDetailGridTop .adminERPData #adminERPNotizenVorgang {
  width: 90%;
  height: 21px;
  font-size: 16px;
}

#cartDetailDisplay .cartDetailGridTop #adminCartDetailErpInfoTableSave,
#offerMainContainer .cartDetailGridTop #adminCartDetailErpInfoTableSave {
  padding-right: 20px !important;
}

#cartDetailDisplay .cartDetailGridTop #adminCartDetailErpInfoTableShowAllItemPos,
#offerMainContainer .cartDetailGridTop #adminCartDetailErpInfoTableShowAllItemPos {
  padding-right: 20px !important;
}

#cartDetailDisplay .cartDetailAdress h3,
#offerMainContainer .cartDetailAdress h3 {
  background-color: #87dbfb;
  padding: 10px;
}

#cartDetailDisplay .cartDetailAdress h3 .button,
#offerMainContainer .cartDetailAdress h3 .button {
  margin: -10px 0 -16px 40px;
}

#cartDetailDisplay .cartDetailAdress .cartDetailAdressContent,
#offerMainContainer .cartDetailAdress .cartDetailAdressContent {
  padding: 10px;
}

#cartDetailDisplay .cartDetailItemListGrid,
#offerMainContainer .cartDetailItemListGrid {
  display: grid;
  grid-template-columns: 80px 50px 250px auto auto 75px 130px 160px auto;
  grid-auto-rows: 60px;
  font-size: 18px;
}

#cartDetailDisplay .cartDetailItemListGrid .rowWrapper,
#offerMainContainer .cartDetailItemListGrid .rowWrapper {
  display: contents;
}

#cartDetailDisplay .cartDetailItemListGrid .bgGray,
#offerMainContainer .cartDetailItemListGrid .bgGray {
  background-color: #eeeeee;
}

#cartDetailDisplay .cartDetailItemListGrid .col,
#offerMainContainer .cartDetailItemListGrid .col {
  padding: 10px;
}

#cartDetailDisplay .cartDetailItemListGrid .colImg,
#offerMainContainer .cartDetailItemListGrid .colImg {
  background-color: #fff !important;
}

#cartDetailDisplay .cartDetailItemListGrid .cartDetailItemImg,
#offerMainContainer .cartDetailItemListGrid .cartDetailItemImg {
  width: 50px;
}

#cartDetailDisplay .cartDetailItemListGrid .numbers,
#offerMainContainer .cartDetailItemListGrid .numbers {
  width: 100%;
  text-align: right;
}

#cartDetailDisplay .cartDetailItemListGrid input,
#offerMainContainer .cartDetailItemListGrid input {
  padding: 8px 0;
  width: 90%;
  font-size: 16px;
}

#adminCartDetailErpInfoTable,
.adminItemListPosErpTable {
  width: 100%;
  text-align: left;
  border-spacing: 5px;
  border-collapse: seperate;
}

#adminCartDetailErpInfoTable tr th input,
#adminCartDetailErpInfoTable tr td input,
.adminItemListPosErpTable tr th input,
.adminItemListPosErpTable tr td input {
  width: 100%;
}

#adminCartDetailErpInfoTable tr .colPrio,
.adminItemListPosErpTable tr .colPrio {
  width: 40px;
}

#adminCartDetailErpInfoTable tr .colOrt,
#adminCartDetailErpInfoTable tr .colBesorgen,
#adminCartDetailErpInfoTable tr .colTesten,
#adminCartDetailErpInfoTable tr .colBearbeiter,
#adminCartDetailErpInfoTable tr .colKistenID,
.adminItemListPosErpTable tr .colOrt,
.adminItemListPosErpTable tr .colBesorgen,
.adminItemListPosErpTable tr .colTesten,
.adminItemListPosErpTable tr .colBearbeiter,
.adminItemListPosErpTable tr .colKistenID {
  width: 40px;
}

#adminCartDetailErpInfoTable tr .cartInputVerpackungsart,
.adminItemListPosErpTable tr .cartInputVerpackungsart {
  width: 120px;
}

#adminCartDetailErpInfoTable tr .H20,
.adminItemListPosErpTable tr .H20 {
  background-color: #ffa500;
}

#adminCartDetailErpInfoTable tr .H-0,
.adminItemListPosErpTable tr .H-0 {
  background-color: #add8e6;
}

#adminCartDetailErpInfoTable tr .R,
.adminItemListPosErpTable tr .R {
  background-color: #80ff80;
}

#adminCartDetailErpInfoTable tr .B,
.adminItemListPosErpTable tr .B {
  background-color: #ff80ff;
}

#adminCartDetailErpInfoTable tr .HR,
.adminItemListPosErpTable tr .HR {
  background-color: #ffbfff;
}

#adminCartDetailErpInfoTable tr .HF,
.adminItemListPosErpTable tr .HF {
  background-color: #bdf7fb;
}

#adminCartDetailErpInfoTable tr .BT,
.adminItemListPosErpTable tr .BT {
  background-color: #9fb3fd;
}

#adminCartDetailErpInfoTable tr .DS,
.adminItemListPosErpTable tr .DS {
  background-color: #80ff00;
}

#adminCartDetailErpInfoTable tr .SF,
.adminItemListPosErpTable tr .SF {
  background-color: #ffff80;
}

#adminCartDetailErpInfoTable tr .GP,
.adminItemListPosErpTable tr .GP {
  background-color: #cfff88;
}

#adminCartDetailErpInfoTable tr .TJ,
.adminItemListPosErpTable tr .TJ {
  background-color: #b0A6c6;
}

.fancybox-content {
  border-radius: 20px;
}

.colCommissionNo .adminBesonderheiten::placeholder {
  color: red !important;
}

.cartDetailItemList,
.offerDetailItemList {
  margin-top: 40px;
}

.cartDetailItemList .board-column-header .buttonEff,
.offerDetailItemList .board-column-header .buttonEff {
  cursor: pointer;
  line-height: 26px;
  font-size: 16px;
  border: none;
  outline: none;
  font-weight: bold;
}

.cartDetailItemList .hideColDesc .board-item-content,
.offerDetailItemList .hideColDesc .board-item-content {
  padding: 10px 20px;
}

.cartDetailItemList .hideColDesc .board-item-content .rowGrid,
.offerDetailItemList .hideColDesc .board-item-content .rowGrid {
  grid-template-columns: 20px 0 50px 190px 180px auto 110px 130px 50px;
}

.cartDetailItemList .hideColDesc .board-item-content .colPosition,
.offerDetailItemList .hideColDesc .board-item-content .colPosition {
  margin-top: 0;
}

.cartDetailItemList .hideColDesc .board-item-content .colItemDesc,
.offerDetailItemList .hideColDesc .board-item-content .colItemDesc {
  display: none;
}

.cartDetailItemList .hideColDesc .board-item-content .colImg,
.offerDetailItemList .hideColDesc .board-item-content .colImg {
  display: none;
  width: 0;
}

.cartDetailItemList .itemLiostPosCommissionNoMissing,
.offerDetailItemList .itemLiostPosCommissionNoMissing {
  font-weight: bold;
}

.cartDetailItemList .itemLiostPosCommissionNoMissing i,
.offerDetailItemList .itemLiostPosCommissionNoMissing i {
  font-size: 28px;
  vertical-align: middle;
}

.cartDelNotePreviewContainer {
  padding: 40px;
  border: 1px solid #000;
  -webkit-box-shadow: 7px 5px 10px 2px rgba(0, 0, 0, 0.4);
  box-shadow: 7px 5px 10px 2px rgba(0, 0, 0, 0.4);
}

.inputAnimated {
  width: 100%;
  color: #26234e;
}

.inputAnimated:focus + label {
  top: -10px;
  font-size: 12px;
  color: #26234e;
}

.inputAnimated:not(:placeholder-shown) + label {
  top: -10px;
  font-size: 12px;
  color: #26234e;
}

.inputAnimated:placeholder {
  opacity: 0;
}

.inputAnimated:not(:-ms-input-placeholder) + label {
  top: -10px;
  font-size: 12px;
  color: #26234e;
}

.inputAnimated:-ms-input-placeholder {
  color: #fff;
}

.labelAnimated {
  z-index: 0;
  top: 6px;
  left: 5px;
  position: absolute;
  cursor: text;
  font-size: 14px;
  padding-left: 2px;
  padding-right: 2px;
  background-color: #fff;
  color: #a0a0a0;
  transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in, color 200ms;
}

.labelAnimated .fa-check {
  color: green !important;
}

.labelAnimated .fa-exclamation-triangle {
  color: red !important;
}

.posRelative {
  position: relative;
}

#cartDetailPriceCotnainer {
  width: 300px;
  margin-top: 20px;
  font-size: 16px;
}

#cartDetailPriceCotnainer .pricetxt {
  margin-bottom: 5px;
}

#cartDetailPriceCotnainer .priceNumber {
  text-align: right;
  margin-right: 78px;
  margin-bottom: 5px;
}

#cartDetailPriceCotnainer .borderBottom {
  border-bottom: 1px solid #87dbfb;
}

.muuriListHeaderRow .rowGrid {
  grid-template-columns: 30px 70px 50px 190px 180px auto 110px 130px 50px;
  -ms-grid-template-columns: 30px 70px 50px 190px 180px auto 110px 130px 50px;
}

.muuriListHeaderRow .rowGrid .ms-Col1 {
  -ms-grid-column: 1;
}

.muuriListHeaderRow .rowGrid .ms-Col2 {
  -ms-grid-column: 2;
}

.muuriListHeaderRow .rowGrid .ms-Col3 {
  -ms-grid-column: 3;
}

.muuriListHeaderRow .rowGrid .ms-Col4 {
  -ms-grid-column: 4;
}

.muuriListHeaderRow .rowGrid .ms-Col5 {
  -ms-grid-column: 5;
}

.muuriListHeaderRow .rowGrid .ms-Col6 {
  -ms-grid-column: 6;
}

.muuriListHeaderRow .rowGrid .ms-Col7 {
  -ms-grid-column: 7;
}

.muuriListHeaderRow .rowGrid .ms-Col8 {
  -ms-grid-column: 8;
}

.muuriListHeaderRow .rowGrid .ms-Col9 {
  -ms-grid-column: 9;
}

.rowGrid {
  display: grid;
  grid-template-columns: 30px 70px 50px 190px 180px auto 110px 130px 50px;
  align-items: center;
  width: 100%;
}

.rowGrid input {
  padding: 5px;
  font-size: 16px;
  border: 1px solid #87dbfb;
}

.rowGrid .cartDetailItemImg {
  width: 50px !important;
}

.rowGrid .colImg {
  grid-column: 2;
  grid-row: 1 / span 3;
}

.rowGrid .colPosition {
  width: 40px;
  grid-column: 1;
  grid-row: 1 / span 3;
  margin-left: -10px;
  font-size: 12px;
}

.rowGrid .colItemName {
  grid-column: 4;
  grid-row: 1;
  color: #26234e;
}

.rowGrid .colItemName a {
  text-decoration: none;
  color: #26234e;
}

.rowGrid .colItemName a:hover {
  color: #87dbfb;
  text-decoration: underline;
}

.rowGrid .colItemType {
  grid-column: 5;
  grid-row: 1;
}

.rowGrid .colItemComment {
  max-width: 98%;
  grid-column: 6;
  grid-row: 1;
  position: relative;
}

.rowGrid .colItemComment input {
  width: 100%;
}

.rowGrid .colItemQty {
  grid-column: 3;
  grid-row: 1 / span 3;
}

.rowGrid .colItemQty input {
  width: 26px;
  text-align: right;
}

.rowGrid .colPrice {
  text-align: right;
}

.rowGrid .colPriceItem {
  grid-column: 7;
  grid-row: 1 /span 3;
}

.rowGrid .colPriceSum {
  grid-column: 8;
  grid-row: 1 /span 3;
}

.rowGrid .colDel {
  text-align: right;
  grid-column: 9;
  grid-row: 1 /span 3;
}

.rowGrid .colItemDesc {
  grid-column: 4 / span 3;
  grid-row: 2;
  font-size: 14px;
  padding-top: 5px;
}

.rowGrid .itemType {
  padding: 5px;
  width: 180px;
  font-size: 16px;
}

.rowGrid .itemQty {
  padding: 5px;
  width: 50px;
  font-size: 16px;
  margin-left: -10px;
}

.rowGrid .colWarrenty {
  grid-row: 3;
  grid-column: 4;
  font-size: 14px;
  margin-top: 10px;
}

.rowGrid .colCommissionNo {
  grid-row: 3;
  grid-column: 6 / span 3;
  font-size: 14px;
  position: relative;
  margin-top: 10px;
}

.wrapper .wrapperInner {
  margin: auto;
  position: relative;
}

#tabContentMenu {
  width: 100%;
  background-color: #eeeeee;
  padding: 10px;
  margin-bottom: 20px;
  margin-right: 20px;
}

.navListContainer {
  font-size: 16px;
}

#content {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

#content #contentMain {
  float: left;
  width: 100%;
}

#content #contentMain #contentMainMargin {
  margin-left: 410px;
  max-width: 1660px;
  margin-right: 50px;
}

#content #contentLeft {
  float: left;
  width: 370px;
  z-index: 1;
  position: sticky;
  top: 100px;
  margin-left: -100%;
  /*
	#boxChat{
		#boxChatContainer {
			background-color: $color-gray;
			padding: 20px;
			margin: 0 20px 0 0;
			position: relative;
			//border: 1px solid #000;
			
			.boxChatLink {
				color: $color-darkBlue;
				h1 {
				line-height: 32px;
				
					.fa-comments{ 
						font-size: 32px;
						margin-right: 10px;
					}
				
					.iconArrow {
						display: none;
						margin: 0 0 0 50px;
						text-decoration: none;
						font-size: 20px;
						line-height: 30px;
						padding: 0;
						//max-height: 20px;
					}
				
				}
				&:hover {
					//color: $color-orange;
					.iconArrow {
						display: inline-block;
					}
				}
			}
			
		
		}
	}
*/
}

#content #contentLeft #leftContainer {
  border-right: 1px dotted #26234e;
  width: 370px;
  margin-left: 20px;
}

#content #contentLeft .boxLeftBorderRound {
  border-radius: 5px;
}

#content #contentLeft .boxLeft {
  margin: 0 0 20px 0;
  width: 100%;
}

#content #contentLeft .boxLeft .collapseBtn {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 20px;
}

#content #contentLeft .boxLeft .collapseBtn:hover {
  color: #87dbfb;
}

#content #contentLeft h1 {
  font-size: 16px;
  margin: 0;
  font-weight: bold;
}

#content #contentLeft #boxLogin #boxLogInContainer {
  padding: 20px;
  margin: 0 20px 0 0;
}

#content #contentLeft #boxLogin #boxLogInContainer h1 {
  font-size: 24px;
}

#content #contentLeft #boxLogin #boxLogInContainer label {
  font-size: 14px;
  font-weight: bold;
}

#content #contentLeft #boxLogin #boxLogInContainer input {
  height: 31px;
  padding: 2px 4px;
  width: 100%;
  margin: 5px 0 20px 0;
  border: 1px solid #a6a6a6;
  border-top-color: #949494;
  border-radius: 3px;
  outline: 0;
}

#content #contentLeft #boxLogin #boxLogInContainer input:focus {
  border-color: #87dbfb;
  box-shadow: 0 0 3px 2px rgba(228, 121, 17, 0.5);
}

#content #contentLeft #boxLogin #boxLogInContainer .field-icon {
  float: right;
  margin-top: -45px;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

#content #contentLeft #boxLogin #boxLogInContainer .checkbox label {
  cursor: pointer;
}

#content #contentLeft #boxLogin #boxLogInContainer .checkbox #boxLoginRememberMe {
  width: 16px;
  height: 16px;
  padding: 1px;
  margin-right: 5px;
  vertical-align: sub;
}

#content #contentLeft #boxLogin #boxLogInContainer button {
  cursor: pointer;
  -webkit-appearance: button;
  margin-top: 10px;
  width: 100%;
  border-radius: 3px;
  border-width: 1px;
  border-color: #a88734 #9c7e31 #846a29;
  background-color: #eff0f3;
  height: 31px;
}

#content #contentLeft #boxLogin #boxLogInContainer button:hover {
  background-color: #87dbfb;
  border-color: #26234e;
  color: #fff;
}

#content #contentLeft #boxLogin #boxLogInContainer .divider {
  text-align: center;
  position: relative;
  top: 2px;
  padding-top: 1px;
  margin-bottom: 14px;
  line-height: 0;
}

#content #contentLeft #boxLogin #boxLogInContainer .divider:after {
  content: "";
  width: 100%;
  background-color: transparent;
  display: block;
  height: 1px;
  border-top: 1px solid #e7e7e7;
  position: absolute;
  top: 50%;
  margin-top: -1px;
  z-index: 1;
}

#content #contentLeft #boxLogin #boxLogInContainer .divider h5 {
  line-height: 1;
  font-size: 14px;
  color: #767676;
  font-weight: 400;
  z-index: 2;
  position: relative;
  display: inline-block;
  background-color: #fff;
  padding: 0 8px 0 7px;
  margin: 0;
}

#content #contentLeft #boxLogin #boxLogInContainer #registerBtn {
  margin: 0;
  margin-top: 10px;
}

#content #contentLeft #boxLogin #boxLogInContainer #registerBtn a {
  text-decoration: none !important;
  color: #26234e;
}

#content #contentLeft #boxLogin #boxLogInContainer #registerBtn:hover {
  background-color: #adb1b8;
}

#content #contentLeft #boxFaq #boxFaqContainer {
  background-color: #eeeeee;
  padding: 20px;
  margin: 0 20px 0 0;
  position: relative;
}

#content #contentLeft #boxFaq #boxFaqContainer .boxFaqLink {
  color: #26234e;
}

#content #contentLeft #boxFaq #boxFaqContainer .boxFaqLink h1 {
  line-height: 32px;
}

#content #contentLeft #boxFaq #boxFaqContainer .boxFaqLink h1 .fa-question-circle {
  font-size: 32px;
  margin-right: 10px;
}

#content #contentLeft #boxFaq #boxFaqContainer .boxFaqLink h1 .iconArrow {
  display: none;
  margin: 0 0 0 50px;
  text-decoration: none;
  font-size: 20px;
  line-height: 30px;
  padding: 0;
}

#content #contentLeft #boxFaq #boxFaqContainer .boxFaqLink:hover .iconArrow {
  display: inline-block;
}

#content #contentLeft #boxLoggedIn #boxLoggedInContainer {
  background-color: #eeeeee;
  padding: 20px;
  margin: 0 20px 0 0;
  position: relative;
}

#content #contentLeft #boxLoggedIn #boxLoggedInContainer h1 {
  line-height: 30px;
}

#content #contentLeft #boxLoggedIn #boxLoggedInContainer #boxLoggedInImgHeadline {
  background: url("./../../web/img/ico_boxLoggedIn32.png") no-repeat 0 -2px;
  width: 40px;
  height: 32px;
}

#content #contentLeft #boxCarts #boxCartsContainer,
#content #contentLeft #boxCarts #boxOffersContainer,
#content #contentLeft #boxOffers #boxCartsContainer,
#content #contentLeft #boxOffers #boxOffersContainer {
  background-color: #eeeeee;
  margin: 0 20px 0 0;
  position: relative;
}

#content #contentLeft #boxCarts #boxCartsContainer a
h1,
#content #contentLeft #boxCarts #boxOffersContainer a
h1,
#content #contentLeft #boxOffers #boxCartsContainer a
h1,
#content #contentLeft #boxOffers #boxOffersContainer a
h1 {
  background-color: #fff;
  color: #26234e;
  border: 1px solid #26234e;
  padding: 0 !important;
  font-size: 16px;
  font-weight: bold;
}

#content #contentLeft #boxCarts #boxCartsContainer a
h1 .boxLeftPosCount,
#content #contentLeft #boxCarts #boxOffersContainer a
h1 .boxLeftPosCount,
#content #contentLeft #boxOffers #boxCartsContainer a
h1 .boxLeftPosCount,
#content #contentLeft #boxOffers #boxOffersContainer a
h1 .boxLeftPosCount {
  font-weight: normal;
  font-size: 14px;
}

#content #contentLeft #boxCarts #boxCartsContainer a
h1 .boxCartName,
#content #contentLeft #boxCarts #boxCartsContainer a
h1 .boxOfferName,
#content #contentLeft #boxCarts #boxOffersContainer a
h1 .boxCartName,
#content #contentLeft #boxCarts #boxOffersContainer a
h1 .boxOfferName,
#content #contentLeft #boxOffers #boxCartsContainer a
h1 .boxCartName,
#content #contentLeft #boxOffers #boxCartsContainer a
h1 .boxOfferName,
#content #contentLeft #boxOffers #boxOffersContainer a
h1 .boxCartName,
#content #contentLeft #boxOffers #boxOffersContainer a
h1 .boxOfferName {
  font-size: 14px;
  font-weight: normal;
}

#content #contentLeft #boxCarts #boxCartsContainer a
h1:hover .boxCartName span,
#content #contentLeft #boxCarts #boxCartsContainer a
h1:hover .boxOfferName span,
#content #contentLeft #boxCarts #boxOffersContainer a
h1:hover .boxCartName span,
#content #contentLeft #boxCarts #boxOffersContainer a
h1:hover .boxOfferName span,
#content #contentLeft #boxOffers #boxCartsContainer a
h1:hover .boxCartName span,
#content #contentLeft #boxOffers #boxCartsContainer a
h1:hover .boxOfferName span,
#content #contentLeft #boxOffers #boxOffersContainer a
h1:hover .boxCartName span,
#content #contentLeft #boxOffers #boxOffersContainer a
h1:hover .boxOfferName span {
  color: #17B40B;
  text-decoration: underline;
}

#content #contentLeft #boxCarts #boxCartsContainer a
h1 #boxCartsImgHeadline,
#content #contentLeft #boxCarts #boxOffersContainer a
h1 #boxCartsImgHeadline,
#content #contentLeft #boxOffers #boxCartsContainer a
h1 #boxCartsImgHeadline,
#content #contentLeft #boxOffers #boxOffersContainer a
h1 #boxCartsImgHeadline {
  background: url("./../../web/img/ico/ico_CartHeadline.png") no-repeat 0 7px;
  width: 40px;
  height: 42px;
}

#content #contentLeft #boxCarts #boxCartsContainer a
h1 #boxOffersImgHeadline,
#content #contentLeft #boxCarts #boxOffersContainer a
h1 #boxOffersImgHeadline,
#content #contentLeft #boxOffers #boxCartsContainer a
h1 #boxOffersImgHeadline,
#content #contentLeft #boxOffers #boxOffersContainer a
h1 #boxOffersImgHeadline {
  width: 40px;
  height: 42px;
  font-size: 30px;
}

#content #contentLeft #boxCarts #boxCartsContainer .boxCartHeadlineContentContainer,
#content #contentLeft #boxCarts #boxOffersContainer .boxCartHeadlineContentContainer,
#content #contentLeft #boxOffers #boxCartsContainer .boxCartHeadlineContentContainer,
#content #contentLeft #boxOffers #boxOffersContainer .boxCartHeadlineContentContainer {
  padding: 10px 20px 10px 20px;
  border-radius: 5px;
  /*	&:hover {
						background-color: $color-darkBlue;
						color: #fff;
						
						#boxCartsImgHeadline, #boxOffersImgHeadline {
							background-position: 0 -60px;
						}
						
					} */
}

#content #contentLeft #boxCarts #boxCartsContainer #boxCartContent,
#content #contentLeft #boxCarts #boxCartsContainer #boxOfferContent,
#content #contentLeft #boxCarts #boxOffersContainer #boxCartContent,
#content #contentLeft #boxCarts #boxOffersContainer #boxOfferContent,
#content #contentLeft #boxOffers #boxCartsContainer #boxCartContent,
#content #contentLeft #boxOffers #boxCartsContainer #boxOfferContent,
#content #contentLeft #boxOffers #boxOffersContainer #boxCartContent,
#content #contentLeft #boxOffers #boxOffersContainer #boxOfferContent {
  margin-top: 5px;
  padding: 0 20px 20px 20px;
}

#content #contentLeft #boxCarts #boxCartsContainer #boxCartContent .adminRefreshCartCreated,
#content #contentLeft #boxCarts #boxCartsContainer #boxOfferContent .adminRefreshCartCreated,
#content #contentLeft #boxCarts #boxOffersContainer #boxCartContent .adminRefreshCartCreated,
#content #contentLeft #boxCarts #boxOffersContainer #boxOfferContent .adminRefreshCartCreated,
#content #contentLeft #boxOffers #boxCartsContainer #boxCartContent .adminRefreshCartCreated,
#content #contentLeft #boxOffers #boxCartsContainer #boxOfferContent .adminRefreshCartCreated,
#content #contentLeft #boxOffers #boxOffersContainer #boxCartContent .adminRefreshCartCreated,
#content #contentLeft #boxOffers #boxOffersContainer #boxOfferContent .adminRefreshCartCreated {
  color: red;
  cursor: pointer;
}

#content #contentLeft #boxCarts #boxCartsContainer #boxCartContent .adminRefreshCartCreated:hover,
#content #contentLeft #boxCarts #boxCartsContainer #boxOfferContent .adminRefreshCartCreated:hover,
#content #contentLeft #boxCarts #boxOffersContainer #boxCartContent .adminRefreshCartCreated:hover,
#content #contentLeft #boxCarts #boxOffersContainer #boxOfferContent .adminRefreshCartCreated:hover,
#content #contentLeft #boxOffers #boxCartsContainer #boxCartContent .adminRefreshCartCreated:hover,
#content #contentLeft #boxOffers #boxCartsContainer #boxOfferContent .adminRefreshCartCreated:hover,
#content #contentLeft #boxOffers #boxOffersContainer #boxCartContent .adminRefreshCartCreated:hover,
#content #contentLeft #boxOffers #boxOffersContainer #boxOfferContent .adminRefreshCartCreated:hover {
  color: #87dbfb;
}

#content #contentLeft #boxCarts #boxCartsContainer .boxCartsActiveCartName,
#content #contentLeft #boxCarts #boxOffersContainer .boxCartsActiveCartName,
#content #contentLeft #boxOffers #boxCartsContainer .boxCartsActiveCartName,
#content #contentLeft #boxOffers #boxOffersContainer .boxCartsActiveCartName {
  margin-left: 41px;
  margin-top: -15px;
}

#content #contentLeft #boxCarts #boxCartsContainer .boxCartsActiveCartName a,
#content #contentLeft #boxCarts #boxOffersContainer .boxCartsActiveCartName a,
#content #contentLeft #boxOffers #boxCartsContainer .boxCartsActiveCartName a,
#content #contentLeft #boxOffers #boxOffersContainer .boxCartsActiveCartName a {
  margin-top: -5px;
}

#content #contentLeft #boxCarts #boxCartsContainer .showCartDeleteBox,
#content #contentLeft #boxCarts #boxOffersContainer .showCartDeleteBox,
#content #contentLeft #boxOffers #boxCartsContainer .showCartDeleteBox,
#content #contentLeft #boxOffers #boxOffersContainer .showCartDeleteBox {
  font-size: 20px;
  color: #26234e;
}

#content #contentLeft #boxCarts #boxCartsContainer #boxCartItemListContainer,
#content #contentLeft #boxCarts #boxCartsContainer #boxOfferItemListContainer,
#content #contentLeft #boxCarts #boxOffersContainer #boxCartItemListContainer,
#content #contentLeft #boxCarts #boxOffersContainer #boxOfferItemListContainer,
#content #contentLeft #boxOffers #boxCartsContainer #boxCartItemListContainer,
#content #contentLeft #boxOffers #boxCartsContainer #boxOfferItemListContainer,
#content #contentLeft #boxOffers #boxOffersContainer #boxCartItemListContainer,
#content #contentLeft #boxOffers #boxOffersContainer #boxOfferItemListContainer {
  border-top: 1px dotted #87dbfb;
  border-bottom: 1px dotted #87dbfb;
  margin-top: 10px;
}

#content #contentLeft #boxCarts #boxCartsContainer #boxCartItemListContainer #boxCartItemList,
#content #contentLeft #boxCarts #boxCartsContainer #boxCartItemListContainer #boxOfferItemList,
#content #contentLeft #boxCarts #boxCartsContainer #boxOfferItemListContainer #boxCartItemList,
#content #contentLeft #boxCarts #boxCartsContainer #boxOfferItemListContainer #boxOfferItemList,
#content #contentLeft #boxCarts #boxOffersContainer #boxCartItemListContainer #boxCartItemList,
#content #contentLeft #boxCarts #boxOffersContainer #boxCartItemListContainer #boxOfferItemList,
#content #contentLeft #boxCarts #boxOffersContainer #boxOfferItemListContainer #boxCartItemList,
#content #contentLeft #boxCarts #boxOffersContainer #boxOfferItemListContainer #boxOfferItemList,
#content #contentLeft #boxOffers #boxCartsContainer #boxCartItemListContainer #boxCartItemList,
#content #contentLeft #boxOffers #boxCartsContainer #boxCartItemListContainer #boxOfferItemList,
#content #contentLeft #boxOffers #boxCartsContainer #boxOfferItemListContainer #boxCartItemList,
#content #contentLeft #boxOffers #boxCartsContainer #boxOfferItemListContainer #boxOfferItemList,
#content #contentLeft #boxOffers #boxOffersContainer #boxCartItemListContainer #boxCartItemList,
#content #contentLeft #boxOffers #boxOffersContainer #boxCartItemListContainer #boxOfferItemList,
#content #contentLeft #boxOffers #boxOffersContainer #boxOfferItemListContainer #boxCartItemList,
#content #contentLeft #boxOffers #boxOffersContainer #boxOfferItemListContainer #boxOfferItemList {
  margin: 5px 0 5px 20px;
  width: 90%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 40px 3fr 30px 1fr;
  grid-template-columns: 40px [col-start] 3fr [col-start] 30px [col-start] 1fr [col-start];
  align-items: center;
}

#content #contentLeft #boxCarts #boxCartsContainer #boxCartItemListContainer #boxCartItemList div:nth-child(4n + 1),
#content #contentLeft #boxCarts #boxCartsContainer #boxCartItemListContainer #boxOfferItemList div:nth-child(4n + 1),
#content #contentLeft #boxCarts #boxCartsContainer #boxOfferItemListContainer #boxCartItemList div:nth-child(4n + 1),
#content #contentLeft #boxCarts #boxCartsContainer #boxOfferItemListContainer #boxOfferItemList div:nth-child(4n + 1),
#content #contentLeft #boxCarts #boxOffersContainer #boxCartItemListContainer #boxCartItemList div:nth-child(4n + 1),
#content #contentLeft #boxCarts #boxOffersContainer #boxCartItemListContainer #boxOfferItemList div:nth-child(4n + 1),
#content #contentLeft #boxCarts #boxOffersContainer #boxOfferItemListContainer #boxCartItemList div:nth-child(4n + 1),
#content #contentLeft #boxCarts #boxOffersContainer #boxOfferItemListContainer #boxOfferItemList div:nth-child(4n + 1),
#content #contentLeft #boxOffers #boxCartsContainer #boxCartItemListContainer #boxCartItemList div:nth-child(4n + 1),
#content #contentLeft #boxOffers #boxCartsContainer #boxCartItemListContainer #boxOfferItemList div:nth-child(4n + 1),
#content #contentLeft #boxOffers #boxCartsContainer #boxOfferItemListContainer #boxCartItemList div:nth-child(4n + 1),
#content #contentLeft #boxOffers #boxCartsContainer #boxOfferItemListContainer #boxOfferItemList div:nth-child(4n + 1),
#content #contentLeft #boxOffers #boxOffersContainer #boxCartItemListContainer #boxCartItemList div:nth-child(4n + 1),
#content #contentLeft #boxOffers #boxOffersContainer #boxCartItemListContainer #boxOfferItemList div:nth-child(4n + 1),
#content #contentLeft #boxOffers #boxOffersContainer #boxOfferItemListContainer #boxCartItemList div:nth-child(4n + 1),
#content #contentLeft #boxOffers #boxOffersContainer #boxOfferItemListContainer #boxOfferItemList div:nth-child(4n + 1) {
  -ms-grid-column: 1;
}

#content #contentLeft #boxCarts #boxCartsContainer #boxCartItemListContainer #boxCartItemList div:nth-child(4n + 2),
#content #contentLeft #boxCarts #boxCartsContainer #boxCartItemListContainer #boxOfferItemList div:nth-child(4n + 2),
#content #contentLeft #boxCarts #boxCartsContainer #boxOfferItemListContainer #boxCartItemList div:nth-child(4n + 2),
#content #contentLeft #boxCarts #boxCartsContainer #boxOfferItemListContainer #boxOfferItemList div:nth-child(4n + 2),
#content #contentLeft #boxCarts #boxOffersContainer #boxCartItemListContainer #boxCartItemList div:nth-child(4n + 2),
#content #contentLeft #boxCarts #boxOffersContainer #boxCartItemListContainer #boxOfferItemList div:nth-child(4n + 2),
#content #contentLeft #boxCarts #boxOffersContainer #boxOfferItemListContainer #boxCartItemList div:nth-child(4n + 2),
#content #contentLeft #boxCarts #boxOffersContainer #boxOfferItemListContainer #boxOfferItemList div:nth-child(4n + 2),
#content #contentLeft #boxOffers #boxCartsContainer #boxCartItemListContainer #boxCartItemList div:nth-child(4n + 2),
#content #contentLeft #boxOffers #boxCartsContainer #boxCartItemListContainer #boxOfferItemList div:nth-child(4n + 2),
#content #contentLeft #boxOffers #boxCartsContainer #boxOfferItemListContainer #boxCartItemList div:nth-child(4n + 2),
#content #contentLeft #boxOffers #boxCartsContainer #boxOfferItemListContainer #boxOfferItemList div:nth-child(4n + 2),
#content #contentLeft #boxOffers #boxOffersContainer #boxCartItemListContainer #boxCartItemList div:nth-child(4n + 2),
#content #contentLeft #boxOffers #boxOffersContainer #boxCartItemListContainer #boxOfferItemList div:nth-child(4n + 2),
#content #contentLeft #boxOffers #boxOffersContainer #boxOfferItemListContainer #boxCartItemList div:nth-child(4n + 2),
#content #contentLeft #boxOffers #boxOffersContainer #boxOfferItemListContainer #boxOfferItemList div:nth-child(4n + 2) {
  -ms-grid-column: 2;
}

#content #contentLeft #boxCarts #boxCartsContainer #boxCartItemListContainer #boxCartItemList div:nth-child(4n + 3),
#content #contentLeft #boxCarts #boxCartsContainer #boxCartItemListContainer #boxOfferItemList div:nth-child(4n + 3),
#content #contentLeft #boxCarts #boxCartsContainer #boxOfferItemListContainer #boxCartItemList div:nth-child(4n + 3),
#content #contentLeft #boxCarts #boxCartsContainer #boxOfferItemListContainer #boxOfferItemList div:nth-child(4n + 3),
#content #contentLeft #boxCarts #boxOffersContainer #boxCartItemListContainer #boxCartItemList div:nth-child(4n + 3),
#content #contentLeft #boxCarts #boxOffersContainer #boxCartItemListContainer #boxOfferItemList div:nth-child(4n + 3),
#content #contentLeft #boxCarts #boxOffersContainer #boxOfferItemListContainer #boxCartItemList div:nth-child(4n + 3),
#content #contentLeft #boxCarts #boxOffersContainer #boxOfferItemListContainer #boxOfferItemList div:nth-child(4n + 3),
#content #contentLeft #boxOffers #boxCartsContainer #boxCartItemListContainer #boxCartItemList div:nth-child(4n + 3),
#content #contentLeft #boxOffers #boxCartsContainer #boxCartItemListContainer #boxOfferItemList div:nth-child(4n + 3),
#content #contentLeft #boxOffers #boxCartsContainer #boxOfferItemListContainer #boxCartItemList div:nth-child(4n + 3),
#content #contentLeft #boxOffers #boxCartsContainer #boxOfferItemListContainer #boxOfferItemList div:nth-child(4n + 3),
#content #contentLeft #boxOffers #boxOffersContainer #boxCartItemListContainer #boxCartItemList div:nth-child(4n + 3),
#content #contentLeft #boxOffers #boxOffersContainer #boxCartItemListContainer #boxOfferItemList div:nth-child(4n + 3),
#content #contentLeft #boxOffers #boxOffersContainer #boxOfferItemListContainer #boxCartItemList div:nth-child(4n + 3),
#content #contentLeft #boxOffers #boxOffersContainer #boxOfferItemListContainer #boxOfferItemList div:nth-child(4n + 3) {
  -ms-grid-column: 3;
}

#content #contentLeft #boxCarts #boxCartsContainer #boxCartItemListContainer #boxCartItemList div:nth-child(4n + 4),
#content #contentLeft #boxCarts #boxCartsContainer #boxCartItemListContainer #boxOfferItemList div:nth-child(4n + 4),
#content #contentLeft #boxCarts #boxCartsContainer #boxOfferItemListContainer #boxCartItemList div:nth-child(4n + 4),
#content #contentLeft #boxCarts #boxCartsContainer #boxOfferItemListContainer #boxOfferItemList div:nth-child(4n + 4),
#content #contentLeft #boxCarts #boxOffersContainer #boxCartItemListContainer #boxCartItemList div:nth-child(4n + 4),
#content #contentLeft #boxCarts #boxOffersContainer #boxCartItemListContainer #boxOfferItemList div:nth-child(4n + 4),
#content #contentLeft #boxCarts #boxOffersContainer #boxOfferItemListContainer #boxCartItemList div:nth-child(4n + 4),
#content #contentLeft #boxCarts #boxOffersContainer #boxOfferItemListContainer #boxOfferItemList div:nth-child(4n + 4),
#content #contentLeft #boxOffers #boxCartsContainer #boxCartItemListContainer #boxCartItemList div:nth-child(4n + 4),
#content #contentLeft #boxOffers #boxCartsContainer #boxCartItemListContainer #boxOfferItemList div:nth-child(4n + 4),
#content #contentLeft #boxOffers #boxCartsContainer #boxOfferItemListContainer #boxCartItemList div:nth-child(4n + 4),
#content #contentLeft #boxOffers #boxCartsContainer #boxOfferItemListContainer #boxOfferItemList div:nth-child(4n + 4),
#content #contentLeft #boxOffers #boxOffersContainer #boxCartItemListContainer #boxCartItemList div:nth-child(4n + 4),
#content #contentLeft #boxOffers #boxOffersContainer #boxCartItemListContainer #boxOfferItemList div:nth-child(4n + 4),
#content #contentLeft #boxOffers #boxOffersContainer #boxOfferItemListContainer #boxCartItemList div:nth-child(4n + 4),
#content #contentLeft #boxOffers #boxOffersContainer #boxOfferItemListContainer #boxOfferItemList div:nth-child(4n + 4) {
  -ms-grid-column: 4;
}

#content #contentLeft #boxCarts #boxCartsContainer #boxCartItemListContainer #boxCartItemList .boxCartItemLink,
#content #contentLeft #boxCarts #boxCartsContainer #boxCartItemListContainer #boxOfferItemList .boxCartItemLink,
#content #contentLeft #boxCarts #boxCartsContainer #boxOfferItemListContainer #boxCartItemList .boxCartItemLink,
#content #contentLeft #boxCarts #boxCartsContainer #boxOfferItemListContainer #boxOfferItemList .boxCartItemLink,
#content #contentLeft #boxCarts #boxOffersContainer #boxCartItemListContainer #boxCartItemList .boxCartItemLink,
#content #contentLeft #boxCarts #boxOffersContainer #boxCartItemListContainer #boxOfferItemList .boxCartItemLink,
#content #contentLeft #boxCarts #boxOffersContainer #boxOfferItemListContainer #boxCartItemList .boxCartItemLink,
#content #contentLeft #boxCarts #boxOffersContainer #boxOfferItemListContainer #boxOfferItemList .boxCartItemLink,
#content #contentLeft #boxOffers #boxCartsContainer #boxCartItemListContainer #boxCartItemList .boxCartItemLink,
#content #contentLeft #boxOffers #boxCartsContainer #boxCartItemListContainer #boxOfferItemList .boxCartItemLink,
#content #contentLeft #boxOffers #boxCartsContainer #boxOfferItemListContainer #boxCartItemList .boxCartItemLink,
#content #contentLeft #boxOffers #boxCartsContainer #boxOfferItemListContainer #boxOfferItemList .boxCartItemLink,
#content #contentLeft #boxOffers #boxOffersContainer #boxCartItemListContainer #boxCartItemList .boxCartItemLink,
#content #contentLeft #boxOffers #boxOffersContainer #boxCartItemListContainer #boxOfferItemList .boxCartItemLink,
#content #contentLeft #boxOffers #boxOffersContainer #boxOfferItemListContainer #boxCartItemList .boxCartItemLink,
#content #contentLeft #boxOffers #boxOffersContainer #boxOfferItemListContainer #boxOfferItemList .boxCartItemLink {
  text-decoration: none;
  color: #26234e;
}

#content #contentLeft #boxCarts #boxCartsContainer #boxCartItemListContainer #boxCartItemList .boxCartItemLink:hover,
#content #contentLeft #boxCarts #boxCartsContainer #boxCartItemListContainer #boxOfferItemList .boxCartItemLink:hover,
#content #contentLeft #boxCarts #boxCartsContainer #boxOfferItemListContainer #boxCartItemList .boxCartItemLink:hover,
#content #contentLeft #boxCarts #boxCartsContainer #boxOfferItemListContainer #boxOfferItemList .boxCartItemLink:hover,
#content #contentLeft #boxCarts #boxOffersContainer #boxCartItemListContainer #boxCartItemList .boxCartItemLink:hover,
#content #contentLeft #boxCarts #boxOffersContainer #boxCartItemListContainer #boxOfferItemList .boxCartItemLink:hover,
#content #contentLeft #boxCarts #boxOffersContainer #boxOfferItemListContainer #boxCartItemList .boxCartItemLink:hover,
#content #contentLeft #boxCarts #boxOffersContainer #boxOfferItemListContainer #boxOfferItemList .boxCartItemLink:hover,
#content #contentLeft #boxOffers #boxCartsContainer #boxCartItemListContainer #boxCartItemList .boxCartItemLink:hover,
#content #contentLeft #boxOffers #boxCartsContainer #boxCartItemListContainer #boxOfferItemList .boxCartItemLink:hover,
#content #contentLeft #boxOffers #boxCartsContainer #boxOfferItemListContainer #boxCartItemList .boxCartItemLink:hover,
#content #contentLeft #boxOffers #boxCartsContainer #boxOfferItemListContainer #boxOfferItemList .boxCartItemLink:hover,
#content #contentLeft #boxOffers #boxOffersContainer #boxCartItemListContainer #boxCartItemList .boxCartItemLink:hover,
#content #contentLeft #boxOffers #boxOffersContainer #boxCartItemListContainer #boxOfferItemList .boxCartItemLink:hover,
#content #contentLeft #boxOffers #boxOffersContainer #boxOfferItemListContainer #boxCartItemList .boxCartItemLink:hover,
#content #contentLeft #boxOffers #boxOffersContainer #boxOfferItemListContainer #boxOfferItemList .boxCartItemLink:hover {
  text-decoration: underline;
}

#content #contentLeft #boxCarts #boxCartsContainer #boxCartItemListContainer #boxCartItemList .delete,
#content #contentLeft #boxCarts #boxCartsContainer #boxCartItemListContainer #boxOfferItemList .delete,
#content #contentLeft #boxCarts #boxCartsContainer #boxOfferItemListContainer #boxCartItemList .delete,
#content #contentLeft #boxCarts #boxCartsContainer #boxOfferItemListContainer #boxOfferItemList .delete,
#content #contentLeft #boxCarts #boxOffersContainer #boxCartItemListContainer #boxCartItemList .delete,
#content #contentLeft #boxCarts #boxOffersContainer #boxCartItemListContainer #boxOfferItemList .delete,
#content #contentLeft #boxCarts #boxOffersContainer #boxOfferItemListContainer #boxCartItemList .delete,
#content #contentLeft #boxCarts #boxOffersContainer #boxOfferItemListContainer #boxOfferItemList .delete,
#content #contentLeft #boxOffers #boxCartsContainer #boxCartItemListContainer #boxCartItemList .delete,
#content #contentLeft #boxOffers #boxCartsContainer #boxCartItemListContainer #boxOfferItemList .delete,
#content #contentLeft #boxOffers #boxCartsContainer #boxOfferItemListContainer #boxCartItemList .delete,
#content #contentLeft #boxOffers #boxCartsContainer #boxOfferItemListContainer #boxOfferItemList .delete,
#content #contentLeft #boxOffers #boxOffersContainer #boxCartItemListContainer #boxCartItemList .delete,
#content #contentLeft #boxOffers #boxOffersContainer #boxCartItemListContainer #boxOfferItemList .delete,
#content #contentLeft #boxOffers #boxOffersContainer #boxOfferItemListContainer #boxCartItemList .delete,
#content #contentLeft #boxOffers #boxOffersContainer #boxOfferItemListContainer #boxOfferItemList .delete {
  justify-self: end;
}

#content #contentLeft #boxCarts #boxCartsContainer #boxCartItemListContainer #boxCartItemList .boxCartNoItems,
#content #contentLeft #boxCarts #boxCartsContainer #boxCartItemListContainer #boxOfferItemList .boxCartNoItems,
#content #contentLeft #boxCarts #boxCartsContainer #boxOfferItemListContainer #boxCartItemList .boxCartNoItems,
#content #contentLeft #boxCarts #boxCartsContainer #boxOfferItemListContainer #boxOfferItemList .boxCartNoItems,
#content #contentLeft #boxCarts #boxOffersContainer #boxCartItemListContainer #boxCartItemList .boxCartNoItems,
#content #contentLeft #boxCarts #boxOffersContainer #boxCartItemListContainer #boxOfferItemList .boxCartNoItems,
#content #contentLeft #boxCarts #boxOffersContainer #boxOfferItemListContainer #boxCartItemList .boxCartNoItems,
#content #contentLeft #boxCarts #boxOffersContainer #boxOfferItemListContainer #boxOfferItemList .boxCartNoItems,
#content #contentLeft #boxOffers #boxCartsContainer #boxCartItemListContainer #boxCartItemList .boxCartNoItems,
#content #contentLeft #boxOffers #boxCartsContainer #boxCartItemListContainer #boxOfferItemList .boxCartNoItems,
#content #contentLeft #boxOffers #boxCartsContainer #boxOfferItemListContainer #boxCartItemList .boxCartNoItems,
#content #contentLeft #boxOffers #boxCartsContainer #boxOfferItemListContainer #boxOfferItemList .boxCartNoItems,
#content #contentLeft #boxOffers #boxOffersContainer #boxCartItemListContainer #boxCartItemList .boxCartNoItems,
#content #contentLeft #boxOffers #boxOffersContainer #boxCartItemListContainer #boxOfferItemList .boxCartNoItems,
#content #contentLeft #boxOffers #boxOffersContainer #boxOfferItemListContainer #boxCartItemList .boxCartNoItems,
#content #contentLeft #boxOffers #boxOffersContainer #boxOfferItemListContainer #boxOfferItemList .boxCartNoItems {
  grid-row: 1;
  grid-column: 1 / span 3;
  -ms-grid-column-span: 3;
}

#content #contentLeft #boxCarts #boxCartsContainer #boxCartItemListContainer #boxCartItemList .addedItemCheck,
#content #contentLeft #boxCarts #boxCartsContainer #boxCartItemListContainer #boxOfferItemList .addedItemCheck,
#content #contentLeft #boxCarts #boxCartsContainer #boxOfferItemListContainer #boxCartItemList .addedItemCheck,
#content #contentLeft #boxCarts #boxCartsContainer #boxOfferItemListContainer #boxOfferItemList .addedItemCheck,
#content #contentLeft #boxCarts #boxOffersContainer #boxCartItemListContainer #boxCartItemList .addedItemCheck,
#content #contentLeft #boxCarts #boxOffersContainer #boxCartItemListContainer #boxOfferItemList .addedItemCheck,
#content #contentLeft #boxCarts #boxOffersContainer #boxOfferItemListContainer #boxCartItemList .addedItemCheck,
#content #contentLeft #boxCarts #boxOffersContainer #boxOfferItemListContainer #boxOfferItemList .addedItemCheck,
#content #contentLeft #boxOffers #boxCartsContainer #boxCartItemListContainer #boxCartItemList .addedItemCheck,
#content #contentLeft #boxOffers #boxCartsContainer #boxCartItemListContainer #boxOfferItemList .addedItemCheck,
#content #contentLeft #boxOffers #boxCartsContainer #boxOfferItemListContainer #boxCartItemList .addedItemCheck,
#content #contentLeft #boxOffers #boxCartsContainer #boxOfferItemListContainer #boxOfferItemList .addedItemCheck,
#content #contentLeft #boxOffers #boxOffersContainer #boxCartItemListContainer #boxCartItemList .addedItemCheck,
#content #contentLeft #boxOffers #boxOffersContainer #boxCartItemListContainer #boxOfferItemList .addedItemCheck,
#content #contentLeft #boxOffers #boxOffersContainer #boxOfferItemListContainer #boxCartItemList .addedItemCheck,
#content #contentLeft #boxOffers #boxOffersContainer #boxOfferItemListContainer #boxOfferItemList .addedItemCheck {
  display: none;
  margin-left: -17px;
}

#content #contentLeft #boxCarts #boxCartsContainer #boxCartItemListContainer #boxCartItemList .addedItemDelay .addedItemCheck,
#content #contentLeft #boxCarts #boxCartsContainer #boxCartItemListContainer #boxOfferItemList .addedItemDelay .addedItemCheck,
#content #contentLeft #boxCarts #boxCartsContainer #boxOfferItemListContainer #boxCartItemList .addedItemDelay .addedItemCheck,
#content #contentLeft #boxCarts #boxCartsContainer #boxOfferItemListContainer #boxOfferItemList .addedItemDelay .addedItemCheck,
#content #contentLeft #boxCarts #boxOffersContainer #boxCartItemListContainer #boxCartItemList .addedItemDelay .addedItemCheck,
#content #contentLeft #boxCarts #boxOffersContainer #boxCartItemListContainer #boxOfferItemList .addedItemDelay .addedItemCheck,
#content #contentLeft #boxCarts #boxOffersContainer #boxOfferItemListContainer #boxCartItemList .addedItemDelay .addedItemCheck,
#content #contentLeft #boxCarts #boxOffersContainer #boxOfferItemListContainer #boxOfferItemList .addedItemDelay .addedItemCheck,
#content #contentLeft #boxOffers #boxCartsContainer #boxCartItemListContainer #boxCartItemList .addedItemDelay .addedItemCheck,
#content #contentLeft #boxOffers #boxCartsContainer #boxCartItemListContainer #boxOfferItemList .addedItemDelay .addedItemCheck,
#content #contentLeft #boxOffers #boxCartsContainer #boxOfferItemListContainer #boxCartItemList .addedItemDelay .addedItemCheck,
#content #contentLeft #boxOffers #boxCartsContainer #boxOfferItemListContainer #boxOfferItemList .addedItemDelay .addedItemCheck,
#content #contentLeft #boxOffers #boxOffersContainer #boxCartItemListContainer #boxCartItemList .addedItemDelay .addedItemCheck,
#content #contentLeft #boxOffers #boxOffersContainer #boxCartItemListContainer #boxOfferItemList .addedItemDelay .addedItemCheck,
#content #contentLeft #boxOffers #boxOffersContainer #boxOfferItemListContainer #boxCartItemList .addedItemDelay .addedItemCheck,
#content #contentLeft #boxOffers #boxOffersContainer #boxOfferItemListContainer #boxOfferItemList .addedItemDelay .addedItemCheck {
  color: #17B40B;
  display: inline-block;
}

#content #contentLeft #boxCarts #boxCartsContainer #boxCartItemListContainer #boxCartItemList .addedItemDelay,
#content #contentLeft #boxCarts #boxCartsContainer #boxCartItemListContainer #boxOfferItemList .addedItemDelay,
#content #contentLeft #boxCarts #boxCartsContainer #boxOfferItemListContainer #boxCartItemList .addedItemDelay,
#content #contentLeft #boxCarts #boxCartsContainer #boxOfferItemListContainer #boxOfferItemList .addedItemDelay,
#content #contentLeft #boxCarts #boxOffersContainer #boxCartItemListContainer #boxCartItemList .addedItemDelay,
#content #contentLeft #boxCarts #boxOffersContainer #boxCartItemListContainer #boxOfferItemList .addedItemDelay,
#content #contentLeft #boxCarts #boxOffersContainer #boxOfferItemListContainer #boxCartItemList .addedItemDelay,
#content #contentLeft #boxCarts #boxOffersContainer #boxOfferItemListContainer #boxOfferItemList .addedItemDelay,
#content #contentLeft #boxOffers #boxCartsContainer #boxCartItemListContainer #boxCartItemList .addedItemDelay,
#content #contentLeft #boxOffers #boxCartsContainer #boxCartItemListContainer #boxOfferItemList .addedItemDelay,
#content #contentLeft #boxOffers #boxCartsContainer #boxOfferItemListContainer #boxCartItemList .addedItemDelay,
#content #contentLeft #boxOffers #boxCartsContainer #boxOfferItemListContainer #boxOfferItemList .addedItemDelay,
#content #contentLeft #boxOffers #boxOffersContainer #boxCartItemListContainer #boxCartItemList .addedItemDelay,
#content #contentLeft #boxOffers #boxOffersContainer #boxCartItemListContainer #boxOfferItemList .addedItemDelay,
#content #contentLeft #boxOffers #boxOffersContainer #boxOfferItemListContainer #boxCartItemList .addedItemDelay,
#content #contentLeft #boxOffers #boxOffersContainer #boxOfferItemListContainer #boxOfferItemList .addedItemDelay {
  color: #17B40B;
  font-weight: bold;
}

#content #contentLeft #boxCarts #boxCartsContainer #boxCartItemListContainer #boxCartItemList .addedItemDelay .boxCartItemLink,
#content #contentLeft #boxCarts #boxCartsContainer #boxCartItemListContainer #boxOfferItemList .addedItemDelay .boxCartItemLink,
#content #contentLeft #boxCarts #boxCartsContainer #boxOfferItemListContainer #boxCartItemList .addedItemDelay .boxCartItemLink,
#content #contentLeft #boxCarts #boxCartsContainer #boxOfferItemListContainer #boxOfferItemList .addedItemDelay .boxCartItemLink,
#content #contentLeft #boxCarts #boxOffersContainer #boxCartItemListContainer #boxCartItemList .addedItemDelay .boxCartItemLink,
#content #contentLeft #boxCarts #boxOffersContainer #boxCartItemListContainer #boxOfferItemList .addedItemDelay .boxCartItemLink,
#content #contentLeft #boxCarts #boxOffersContainer #boxOfferItemListContainer #boxCartItemList .addedItemDelay .boxCartItemLink,
#content #contentLeft #boxCarts #boxOffersContainer #boxOfferItemListContainer #boxOfferItemList .addedItemDelay .boxCartItemLink,
#content #contentLeft #boxOffers #boxCartsContainer #boxCartItemListContainer #boxCartItemList .addedItemDelay .boxCartItemLink,
#content #contentLeft #boxOffers #boxCartsContainer #boxCartItemListContainer #boxOfferItemList .addedItemDelay .boxCartItemLink,
#content #contentLeft #boxOffers #boxCartsContainer #boxOfferItemListContainer #boxCartItemList .addedItemDelay .boxCartItemLink,
#content #contentLeft #boxOffers #boxCartsContainer #boxOfferItemListContainer #boxOfferItemList .addedItemDelay .boxCartItemLink,
#content #contentLeft #boxOffers #boxOffersContainer #boxCartItemListContainer #boxCartItemList .addedItemDelay .boxCartItemLink,
#content #contentLeft #boxOffers #boxOffersContainer #boxCartItemListContainer #boxOfferItemList .addedItemDelay .boxCartItemLink,
#content #contentLeft #boxOffers #boxOffersContainer #boxOfferItemListContainer #boxCartItemList .addedItemDelay .boxCartItemLink,
#content #contentLeft #boxOffers #boxOffersContainer #boxOfferItemListContainer #boxOfferItemList .addedItemDelay .boxCartItemLink {
  color: #17B40B;
}

#content #contentLeft #boxCarts #boxCartsContainer #boxCartsCartList,
#content #contentLeft #boxCarts #boxCartsContainer #boxOffersOfferList,
#content #contentLeft #boxCarts #boxOffersContainer #boxCartsCartList,
#content #contentLeft #boxCarts #boxOffersContainer #boxOffersOfferList,
#content #contentLeft #boxOffers #boxCartsContainer #boxCartsCartList,
#content #contentLeft #boxOffers #boxCartsContainer #boxOffersOfferList,
#content #contentLeft #boxOffers #boxOffersContainer #boxCartsCartList,
#content #contentLeft #boxOffers #boxOffersContainer #boxOffersOfferList {
  margin: 20px 0 0 0;
  opacity: 0.6;
}

#content #contentLeft #boxCarts #boxCartsContainer #boxCartsCartList #boxCartsCartListGrid,
#content #contentLeft #boxCarts #boxCartsContainer #boxCartsCartList #boxOffersOfferListGrid,
#content #contentLeft #boxCarts #boxCartsContainer #boxOffersOfferList #boxCartsCartListGrid,
#content #contentLeft #boxCarts #boxCartsContainer #boxOffersOfferList #boxOffersOfferListGrid,
#content #contentLeft #boxCarts #boxOffersContainer #boxCartsCartList #boxCartsCartListGrid,
#content #contentLeft #boxCarts #boxOffersContainer #boxCartsCartList #boxOffersOfferListGrid,
#content #contentLeft #boxCarts #boxOffersContainer #boxOffersOfferList #boxCartsCartListGrid,
#content #contentLeft #boxCarts #boxOffersContainer #boxOffersOfferList #boxOffersOfferListGrid,
#content #contentLeft #boxOffers #boxCartsContainer #boxCartsCartList #boxCartsCartListGrid,
#content #contentLeft #boxOffers #boxCartsContainer #boxCartsCartList #boxOffersOfferListGrid,
#content #contentLeft #boxOffers #boxCartsContainer #boxOffersOfferList #boxCartsCartListGrid,
#content #contentLeft #boxOffers #boxCartsContainer #boxOffersOfferList #boxOffersOfferListGrid,
#content #contentLeft #boxOffers #boxOffersContainer #boxCartsCartList #boxCartsCartListGrid,
#content #contentLeft #boxOffers #boxOffersContainer #boxCartsCartList #boxOffersOfferListGrid,
#content #contentLeft #boxOffers #boxOffersContainer #boxOffersOfferList #boxCartsCartListGrid,
#content #contentLeft #boxOffers #boxOffersContainer #boxOffersOfferList #boxOffersOfferListGrid {
  width: 90%;
  display: -ms-grid;
  -ms-grid-columns: 2fr 1fr 40px;
  display: grid;
  grid-template-columns: 2fr [col-start] 1fr [col-start] 40px [col-start];
  align-items: center;
}

#content #contentLeft #boxCarts #boxCartsContainer #boxCartsCartList #boxCartsCartListGrid div:nth-child(3n + 1),
#content #contentLeft #boxCarts #boxCartsContainer #boxCartsCartList #boxOffersOfferListGrid div:nth-child(3n + 1),
#content #contentLeft #boxCarts #boxCartsContainer #boxOffersOfferList #boxCartsCartListGrid div:nth-child(3n + 1),
#content #contentLeft #boxCarts #boxCartsContainer #boxOffersOfferList #boxOffersOfferListGrid div:nth-child(3n + 1),
#content #contentLeft #boxCarts #boxOffersContainer #boxCartsCartList #boxCartsCartListGrid div:nth-child(3n + 1),
#content #contentLeft #boxCarts #boxOffersContainer #boxCartsCartList #boxOffersOfferListGrid div:nth-child(3n + 1),
#content #contentLeft #boxCarts #boxOffersContainer #boxOffersOfferList #boxCartsCartListGrid div:nth-child(3n + 1),
#content #contentLeft #boxCarts #boxOffersContainer #boxOffersOfferList #boxOffersOfferListGrid div:nth-child(3n + 1),
#content #contentLeft #boxOffers #boxCartsContainer #boxCartsCartList #boxCartsCartListGrid div:nth-child(3n + 1),
#content #contentLeft #boxOffers #boxCartsContainer #boxCartsCartList #boxOffersOfferListGrid div:nth-child(3n + 1),
#content #contentLeft #boxOffers #boxCartsContainer #boxOffersOfferList #boxCartsCartListGrid div:nth-child(3n + 1),
#content #contentLeft #boxOffers #boxCartsContainer #boxOffersOfferList #boxOffersOfferListGrid div:nth-child(3n + 1),
#content #contentLeft #boxOffers #boxOffersContainer #boxCartsCartList #boxCartsCartListGrid div:nth-child(3n + 1),
#content #contentLeft #boxOffers #boxOffersContainer #boxCartsCartList #boxOffersOfferListGrid div:nth-child(3n + 1),
#content #contentLeft #boxOffers #boxOffersContainer #boxOffersOfferList #boxCartsCartListGrid div:nth-child(3n + 1),
#content #contentLeft #boxOffers #boxOffersContainer #boxOffersOfferList #boxOffersOfferListGrid div:nth-child(3n + 1) {
  -ms-grid-column: 1;
}

#content #contentLeft #boxCarts #boxCartsContainer #boxCartsCartList #boxCartsCartListGrid div:nth-child(3n + 2),
#content #contentLeft #boxCarts #boxCartsContainer #boxCartsCartList #boxOffersOfferListGrid div:nth-child(3n + 2),
#content #contentLeft #boxCarts #boxCartsContainer #boxOffersOfferList #boxCartsCartListGrid div:nth-child(3n + 2),
#content #contentLeft #boxCarts #boxCartsContainer #boxOffersOfferList #boxOffersOfferListGrid div:nth-child(3n + 2),
#content #contentLeft #boxCarts #boxOffersContainer #boxCartsCartList #boxCartsCartListGrid div:nth-child(3n + 2),
#content #contentLeft #boxCarts #boxOffersContainer #boxCartsCartList #boxOffersOfferListGrid div:nth-child(3n + 2),
#content #contentLeft #boxCarts #boxOffersContainer #boxOffersOfferList #boxCartsCartListGrid div:nth-child(3n + 2),
#content #contentLeft #boxCarts #boxOffersContainer #boxOffersOfferList #boxOffersOfferListGrid div:nth-child(3n + 2),
#content #contentLeft #boxOffers #boxCartsContainer #boxCartsCartList #boxCartsCartListGrid div:nth-child(3n + 2),
#content #contentLeft #boxOffers #boxCartsContainer #boxCartsCartList #boxOffersOfferListGrid div:nth-child(3n + 2),
#content #contentLeft #boxOffers #boxCartsContainer #boxOffersOfferList #boxCartsCartListGrid div:nth-child(3n + 2),
#content #contentLeft #boxOffers #boxCartsContainer #boxOffersOfferList #boxOffersOfferListGrid div:nth-child(3n + 2),
#content #contentLeft #boxOffers #boxOffersContainer #boxCartsCartList #boxCartsCartListGrid div:nth-child(3n + 2),
#content #contentLeft #boxOffers #boxOffersContainer #boxCartsCartList #boxOffersOfferListGrid div:nth-child(3n + 2),
#content #contentLeft #boxOffers #boxOffersContainer #boxOffersOfferList #boxCartsCartListGrid div:nth-child(3n + 2),
#content #contentLeft #boxOffers #boxOffersContainer #boxOffersOfferList #boxOffersOfferListGrid div:nth-child(3n + 2) {
  -ms-grid-column: 2;
}

#content #contentLeft #boxCarts #boxCartsContainer #boxCartsCartList #boxCartsCartListGrid div:nth-child(3n + 3),
#content #contentLeft #boxCarts #boxCartsContainer #boxCartsCartList #boxOffersOfferListGrid div:nth-child(3n + 3),
#content #contentLeft #boxCarts #boxCartsContainer #boxOffersOfferList #boxCartsCartListGrid div:nth-child(3n + 3),
#content #contentLeft #boxCarts #boxCartsContainer #boxOffersOfferList #boxOffersOfferListGrid div:nth-child(3n + 3),
#content #contentLeft #boxCarts #boxOffersContainer #boxCartsCartList #boxCartsCartListGrid div:nth-child(3n + 3),
#content #contentLeft #boxCarts #boxOffersContainer #boxCartsCartList #boxOffersOfferListGrid div:nth-child(3n + 3),
#content #contentLeft #boxCarts #boxOffersContainer #boxOffersOfferList #boxCartsCartListGrid div:nth-child(3n + 3),
#content #contentLeft #boxCarts #boxOffersContainer #boxOffersOfferList #boxOffersOfferListGrid div:nth-child(3n + 3),
#content #contentLeft #boxOffers #boxCartsContainer #boxCartsCartList #boxCartsCartListGrid div:nth-child(3n + 3),
#content #contentLeft #boxOffers #boxCartsContainer #boxCartsCartList #boxOffersOfferListGrid div:nth-child(3n + 3),
#content #contentLeft #boxOffers #boxCartsContainer #boxOffersOfferList #boxCartsCartListGrid div:nth-child(3n + 3),
#content #contentLeft #boxOffers #boxCartsContainer #boxOffersOfferList #boxOffersOfferListGrid div:nth-child(3n + 3),
#content #contentLeft #boxOffers #boxOffersContainer #boxCartsCartList #boxCartsCartListGrid div:nth-child(3n + 3),
#content #contentLeft #boxOffers #boxOffersContainer #boxCartsCartList #boxOffersOfferListGrid div:nth-child(3n + 3),
#content #contentLeft #boxOffers #boxOffersContainer #boxOffersOfferList #boxCartsCartListGrid div:nth-child(3n + 3),
#content #contentLeft #boxOffers #boxOffersContainer #boxOffersOfferList #boxOffersOfferListGrid div:nth-child(3n + 3) {
  -ms-grid-column: 3;
}

#content #contentLeft #boxCarts #boxCartsContainer #boxCartsCartList #boxCartsCartListGrid .cartDel,
#content #contentLeft #boxCarts #boxCartsContainer #boxCartsCartList #boxOffersOfferListGrid .cartDel,
#content #contentLeft #boxCarts #boxCartsContainer #boxOffersOfferList #boxCartsCartListGrid .cartDel,
#content #contentLeft #boxCarts #boxCartsContainer #boxOffersOfferList #boxOffersOfferListGrid .cartDel,
#content #contentLeft #boxCarts #boxOffersContainer #boxCartsCartList #boxCartsCartListGrid .cartDel,
#content #contentLeft #boxCarts #boxOffersContainer #boxCartsCartList #boxOffersOfferListGrid .cartDel,
#content #contentLeft #boxCarts #boxOffersContainer #boxOffersOfferList #boxCartsCartListGrid .cartDel,
#content #contentLeft #boxCarts #boxOffersContainer #boxOffersOfferList #boxOffersOfferListGrid .cartDel,
#content #contentLeft #boxOffers #boxCartsContainer #boxCartsCartList #boxCartsCartListGrid .cartDel,
#content #contentLeft #boxOffers #boxCartsContainer #boxCartsCartList #boxOffersOfferListGrid .cartDel,
#content #contentLeft #boxOffers #boxCartsContainer #boxOffersOfferList #boxCartsCartListGrid .cartDel,
#content #contentLeft #boxOffers #boxCartsContainer #boxOffersOfferList #boxOffersOfferListGrid .cartDel,
#content #contentLeft #boxOffers #boxOffersContainer #boxCartsCartList #boxCartsCartListGrid .cartDel,
#content #contentLeft #boxOffers #boxOffersContainer #boxCartsCartList #boxOffersOfferListGrid .cartDel,
#content #contentLeft #boxOffers #boxOffersContainer #boxOffersOfferList #boxCartsCartListGrid .cartDel,
#content #contentLeft #boxOffers #boxOffersContainer #boxOffersOfferList #boxOffersOfferListGrid .cartDel {
  justify-self: end;
}

#content #contentLeft #boxCarts #boxCartsContainer #boxCartsCartList #boxCartsCartListGrid .cartName,
#content #contentLeft #boxCarts #boxCartsContainer #boxCartsCartList #boxOffersOfferListGrid .cartName,
#content #contentLeft #boxCarts #boxCartsContainer #boxOffersOfferList #boxCartsCartListGrid .cartName,
#content #contentLeft #boxCarts #boxCartsContainer #boxOffersOfferList #boxOffersOfferListGrid .cartName,
#content #contentLeft #boxCarts #boxOffersContainer #boxCartsCartList #boxCartsCartListGrid .cartName,
#content #contentLeft #boxCarts #boxOffersContainer #boxCartsCartList #boxOffersOfferListGrid .cartName,
#content #contentLeft #boxCarts #boxOffersContainer #boxOffersOfferList #boxCartsCartListGrid .cartName,
#content #contentLeft #boxCarts #boxOffersContainer #boxOffersOfferList #boxOffersOfferListGrid .cartName,
#content #contentLeft #boxOffers #boxCartsContainer #boxCartsCartList #boxCartsCartListGrid .cartName,
#content #contentLeft #boxOffers #boxCartsContainer #boxCartsCartList #boxOffersOfferListGrid .cartName,
#content #contentLeft #boxOffers #boxCartsContainer #boxOffersOfferList #boxCartsCartListGrid .cartName,
#content #contentLeft #boxOffers #boxCartsContainer #boxOffersOfferList #boxOffersOfferListGrid .cartName,
#content #contentLeft #boxOffers #boxOffersContainer #boxCartsCartList #boxCartsCartListGrid .cartName,
#content #contentLeft #boxOffers #boxOffersContainer #boxCartsCartList #boxOffersOfferListGrid .cartName,
#content #contentLeft #boxOffers #boxOffersContainer #boxOffersOfferList #boxCartsCartListGrid .cartName,
#content #contentLeft #boxOffers #boxOffersContainer #boxOffersOfferList #boxOffersOfferListGrid .cartName {
  padding: 0 0 0 25px;
}

#content #contentLeft #boxCarts #boxCartsContainer #boxCartsCartList #boxCartsCartListGrid .cartName i,
#content #contentLeft #boxCarts #boxCartsContainer #boxCartsCartList #boxOffersOfferListGrid .cartName i,
#content #contentLeft #boxCarts #boxCartsContainer #boxOffersOfferList #boxCartsCartListGrid .cartName i,
#content #contentLeft #boxCarts #boxCartsContainer #boxOffersOfferList #boxOffersOfferListGrid .cartName i,
#content #contentLeft #boxCarts #boxOffersContainer #boxCartsCartList #boxCartsCartListGrid .cartName i,
#content #contentLeft #boxCarts #boxOffersContainer #boxCartsCartList #boxOffersOfferListGrid .cartName i,
#content #contentLeft #boxCarts #boxOffersContainer #boxOffersOfferList #boxCartsCartListGrid .cartName i,
#content #contentLeft #boxCarts #boxOffersContainer #boxOffersOfferList #boxOffersOfferListGrid .cartName i,
#content #contentLeft #boxOffers #boxCartsContainer #boxCartsCartList #boxCartsCartListGrid .cartName i,
#content #contentLeft #boxOffers #boxCartsContainer #boxCartsCartList #boxOffersOfferListGrid .cartName i,
#content #contentLeft #boxOffers #boxCartsContainer #boxOffersOfferList #boxCartsCartListGrid .cartName i,
#content #contentLeft #boxOffers #boxCartsContainer #boxOffersOfferList #boxOffersOfferListGrid .cartName i,
#content #contentLeft #boxOffers #boxOffersContainer #boxCartsCartList #boxCartsCartListGrid .cartName i,
#content #contentLeft #boxOffers #boxOffersContainer #boxCartsCartList #boxOffersOfferListGrid .cartName i,
#content #contentLeft #boxOffers #boxOffersContainer #boxOffersOfferList #boxCartsCartListGrid .cartName i,
#content #contentLeft #boxOffers #boxOffersContainer #boxOffersOfferList #boxOffersOfferListGrid .cartName i {
  margin-left: -25px;
  font-size: 16px;
  margin-right: 4px;
}

#content #contentLeft #boxCarts #boxCartsContainer #boxCartsCartList #boxCartsCartListGrid .cartImgSmall,
#content #contentLeft #boxCarts #boxCartsContainer #boxCartsCartList #boxOffersOfferListGrid .cartImgSmall,
#content #contentLeft #boxCarts #boxCartsContainer #boxOffersOfferList #boxCartsCartListGrid .cartImgSmall,
#content #contentLeft #boxCarts #boxCartsContainer #boxOffersOfferList #boxOffersOfferListGrid .cartImgSmall,
#content #contentLeft #boxCarts #boxOffersContainer #boxCartsCartList #boxCartsCartListGrid .cartImgSmall,
#content #contentLeft #boxCarts #boxOffersContainer #boxCartsCartList #boxOffersOfferListGrid .cartImgSmall,
#content #contentLeft #boxCarts #boxOffersContainer #boxOffersOfferList #boxCartsCartListGrid .cartImgSmall,
#content #contentLeft #boxCarts #boxOffersContainer #boxOffersOfferList #boxOffersOfferListGrid .cartImgSmall,
#content #contentLeft #boxOffers #boxCartsContainer #boxCartsCartList #boxCartsCartListGrid .cartImgSmall,
#content #contentLeft #boxOffers #boxCartsContainer #boxCartsCartList #boxOffersOfferListGrid .cartImgSmall,
#content #contentLeft #boxOffers #boxCartsContainer #boxOffersOfferList #boxCartsCartListGrid .cartImgSmall,
#content #contentLeft #boxOffers #boxCartsContainer #boxOffersOfferList #boxOffersOfferListGrid .cartImgSmall,
#content #contentLeft #boxOffers #boxOffersContainer #boxCartsCartList #boxCartsCartListGrid .cartImgSmall,
#content #contentLeft #boxOffers #boxOffersContainer #boxCartsCartList #boxOffersOfferListGrid .cartImgSmall,
#content #contentLeft #boxOffers #boxOffersContainer #boxOffersOfferList #boxCartsCartListGrid .cartImgSmall,
#content #contentLeft #boxOffers #boxOffersContainer #boxOffersOfferList #boxOffersOfferListGrid .cartImgSmall {
  background: url("./../../web/img/ico/ico_btnCart20.png") no-repeat 0 0;
}

#content #contentLeft #boxCarts #boxCartsContainer #boxCartsCartList #boxCartsCartListGrid .boxCartSwitchLink,
#content #contentLeft #boxCarts #boxCartsContainer #boxCartsCartList #boxOffersOfferListGrid .boxCartSwitchLink,
#content #contentLeft #boxCarts #boxCartsContainer #boxOffersOfferList #boxCartsCartListGrid .boxCartSwitchLink,
#content #contentLeft #boxCarts #boxCartsContainer #boxOffersOfferList #boxOffersOfferListGrid .boxCartSwitchLink,
#content #contentLeft #boxCarts #boxOffersContainer #boxCartsCartList #boxCartsCartListGrid .boxCartSwitchLink,
#content #contentLeft #boxCarts #boxOffersContainer #boxCartsCartList #boxOffersOfferListGrid .boxCartSwitchLink,
#content #contentLeft #boxCarts #boxOffersContainer #boxOffersOfferList #boxCartsCartListGrid .boxCartSwitchLink,
#content #contentLeft #boxCarts #boxOffersContainer #boxOffersOfferList #boxOffersOfferListGrid .boxCartSwitchLink,
#content #contentLeft #boxOffers #boxCartsContainer #boxCartsCartList #boxCartsCartListGrid .boxCartSwitchLink,
#content #contentLeft #boxOffers #boxCartsContainer #boxCartsCartList #boxOffersOfferListGrid .boxCartSwitchLink,
#content #contentLeft #boxOffers #boxCartsContainer #boxOffersOfferList #boxCartsCartListGrid .boxCartSwitchLink,
#content #contentLeft #boxOffers #boxCartsContainer #boxOffersOfferList #boxOffersOfferListGrid .boxCartSwitchLink,
#content #contentLeft #boxOffers #boxOffersContainer #boxCartsCartList #boxCartsCartListGrid .boxCartSwitchLink,
#content #contentLeft #boxOffers #boxOffersContainer #boxCartsCartList #boxOffersOfferListGrid .boxCartSwitchLink,
#content #contentLeft #boxOffers #boxOffersContainer #boxOffersOfferList #boxCartsCartListGrid .boxCartSwitchLink,
#content #contentLeft #boxOffers #boxOffersContainer #boxOffersOfferList #boxOffersOfferListGrid .boxCartSwitchLink {
  text-decoration: none;
  color: #26234e;
}

#content #contentLeft #boxCarts #boxCartsContainer #boxCartsCartList #boxCartsCartListGrid .boxCartSwitchLink:hover,
#content #contentLeft #boxCarts #boxCartsContainer #boxCartsCartList #boxOffersOfferListGrid .boxCartSwitchLink:hover,
#content #contentLeft #boxCarts #boxCartsContainer #boxOffersOfferList #boxCartsCartListGrid .boxCartSwitchLink:hover,
#content #contentLeft #boxCarts #boxCartsContainer #boxOffersOfferList #boxOffersOfferListGrid .boxCartSwitchLink:hover,
#content #contentLeft #boxCarts #boxOffersContainer #boxCartsCartList #boxCartsCartListGrid .boxCartSwitchLink:hover,
#content #contentLeft #boxCarts #boxOffersContainer #boxCartsCartList #boxOffersOfferListGrid .boxCartSwitchLink:hover,
#content #contentLeft #boxCarts #boxOffersContainer #boxOffersOfferList #boxCartsCartListGrid .boxCartSwitchLink:hover,
#content #contentLeft #boxCarts #boxOffersContainer #boxOffersOfferList #boxOffersOfferListGrid .boxCartSwitchLink:hover,
#content #contentLeft #boxOffers #boxCartsContainer #boxCartsCartList #boxCartsCartListGrid .boxCartSwitchLink:hover,
#content #contentLeft #boxOffers #boxCartsContainer #boxCartsCartList #boxOffersOfferListGrid .boxCartSwitchLink:hover,
#content #contentLeft #boxOffers #boxCartsContainer #boxOffersOfferList #boxCartsCartListGrid .boxCartSwitchLink:hover,
#content #contentLeft #boxOffers #boxCartsContainer #boxOffersOfferList #boxOffersOfferListGrid .boxCartSwitchLink:hover,
#content #contentLeft #boxOffers #boxOffersContainer #boxCartsCartList #boxCartsCartListGrid .boxCartSwitchLink:hover,
#content #contentLeft #boxOffers #boxOffersContainer #boxCartsCartList #boxOffersOfferListGrid .boxCartSwitchLink:hover,
#content #contentLeft #boxOffers #boxOffersContainer #boxOffersOfferList #boxCartsCartListGrid .boxCartSwitchLink:hover,
#content #contentLeft #boxOffers #boxOffersContainer #boxOffersOfferList #boxOffersOfferListGrid .boxCartSwitchLink:hover {
  text-decoration: underline;
}

#content #contentLeft #boxCarts #boxCartsContainer #boxCartsCartList .boxContentBtn,
#content #contentLeft #boxCarts #boxCartsContainer #boxOffersOfferList .boxContentBtn,
#content #contentLeft #boxCarts #boxOffersContainer #boxCartsCartList .boxContentBtn,
#content #contentLeft #boxCarts #boxOffersContainer #boxOffersOfferList .boxContentBtn,
#content #contentLeft #boxOffers #boxCartsContainer #boxCartsCartList .boxContentBtn,
#content #contentLeft #boxOffers #boxCartsContainer #boxOffersOfferList .boxContentBtn,
#content #contentLeft #boxOffers #boxOffersContainer #boxCartsCartList .boxContentBtn,
#content #contentLeft #boxOffers #boxOffersContainer #boxOffersOfferList .boxContentBtn {
  font-size: 14px !important;
}

#content #contentLeft #boxOrderStatus #boxOrderStatusContainer {
  position: relative;
  background-color: #eeeeee;
  padding: 20px;
  margin: 0 20px 0 0;
}

#content #contentLeft #boxOrderStatus #boxOrderStatusContainer .boxOrderStatusNavContainer .marginTop10 {
  margin-top: 10px;
}

#content #contentLeft #boxOrderStatus #boxOrderStatusContainer .boxOrderStatusNavContainer i {
  font-size: 20px;
  width: 30px;
  text-align: center;
}

#content #contentLeft #boxOrderStatus #boxOrderStatusContainer .boxOrderStatusNavContainer a {
  color: #26234e;
}

#content #contentLeft #boxOrderStatus #boxOrderStatusContainer .boxOrderStatusNavContainer a:hover {
  color: #87dbfb;
  text-decoration: underline;
}

#loginPageContainer .inputFormLabel {
  width: 150px;
  display: block;
}

#loginPageContainer .form-control {
  width: 80%;
}

#upsContainer .inputRow,
#repairShipmentContainer .inputRow {
  margin-top: 5px;
}

#upsContainer #upsShippmentType,
#repairShipmentContainer #upsShippmentType {
  padding: 5px;
  font-size: 16px;
  width: 314px;
  margin-bottom: 10px;
  height: 44px;
}

#upsContainer input,
#repairShipmentContainer input {
  margin-bottom: 10px;
  width: 300px;
  height: 30px;
  font-size: 16px;
}

#upsContainer textarea,
#repairShipmentContainer textarea {
  font-size: 16px;
  color: #0030ff !important;
}

#upsContainer .select .labelAnimated,
#repairShipmentContainer .select .labelAnimated {
  margin-top: -16px;
  font-size: 12px;
}

#upsContainer #upsNo,
#repairShipmentContainer #upsNo {
  width: 165px;
}

#upsContainer .fa-exclamation-triangle,
#repairShipmentContainer .fa-exclamation-triangle {
  color: #efd001 !important;
}

#upsContainer .upsHeadline,
#repairShipmentContainer .upsHeadline {
  background-image: url("/img/ups_logo.gif");
  background-repeat: no-repeat;
  background-size: 40px;
  background-position: 0 50%;
  height: 50px;
}

#upsContainer .upsHeadline span,
#repairShipmentContainer .upsHeadline span {
  padding-left: 50px;
  padding-top: 13px;
  display: inline-block;
}

#upsContainer #imgUpsLabel,
#repairShipmentContainer #imgUpsLabel {
  height: 672px;
  margin-left: 40px;
  margin-top: -40px;
}

#upsContainer #repairShippingItemNoInput,
#repairShipmentContainer #repairShippingItemNoInput {
  width: 200px;
}

#upsContainer #itemQtyInput,
#repairShipmentContainer #itemQtyInput {
  width: 40px;
}

#upsContainer .upsItemQty,
#repairShipmentContainer .upsItemQty {
  height: 46px;
  font-size: 16px;
}

#upsContainer #repairShippingItemSearchResultContainer,
#repairShipmentContainer #repairShippingItemSearchResultContainer {
  width: 192px;
  overflow: hidden;
  border: 1px solid #0088BB;
  padding: 10px;
  position: absolute;
  z-index: 100;
  background-color: #fff;
  top: 44px;
  display: none;
}

#upsContainer #repairShippingItemSearchResultContainer .upsShippingSearchResultListItem,
#repairShipmentContainer #repairShippingItemSearchResultContainer .upsShippingSearchResultListItem {
  padding: 3px;
  border-bottom: 1px solid #0088BB;
  cursor: pointer;
  width: 100%;
}

#upsContainer #repairShippingItemSearchResultContainer .upsShippingSearchResultListItem:hover,
#repairShipmentContainer #repairShippingItemSearchResultContainer .upsShippingSearchResultListItem:hover {
  background-color: #87dbfb;
}

#upsContainer .rowGridRepairShipment,
#repairShipmentContainer .rowGridRepairShipment {
  display: grid;
  grid-template-columns: 70px 200px 320px 320px 50px !important;
  width: 100%;
  align-items: center;
}

#upsContainer .rowGridRepairShipment .listItems,
#repairShipmentContainer .rowGridRepairShipment .listItems {
  position: relative;
}

#upsContainer .rowGridRepairShipment .listItems .repShipmentItemQty,
#repairShipmentContainer .rowGridRepairShipment .listItems .repShipmentItemQty {
  height: 44px;
  font-size: 16px;
  margin-top: -9px;
}

#upsContainer .rowGridRepairShipment .listItems .repShipmentItemName,
#repairShipmentContainer .rowGridRepairShipment .listItems .repShipmentItemName {
  width: 180px !important;
}

#offersOpenContainer .listContainer,
#pendingOrdersContainer .listContainer,
#shippedOrdersContainer .listContainer {
  border: 1px solid #87dbfb;
  margin-bottom: 20px;
}

#offersOpenContainer .listContainer h3,
#pendingOrdersContainer .listContainer h3,
#shippedOrdersContainer .listContainer h3 {
  background-color: #87dbfb;
  padding: 10px;
  margin: 0;
}

#offersOpenContainer .listContainer h3 button,
#pendingOrdersContainer .listContainer h3 button,
#shippedOrdersContainer .listContainer h3 button {
  margin-top: -10px;
  border: 0;
}

#offersOpenContainer .listContainer .listDetail,
#pendingOrdersContainer .listContainer .listDetail,
#shippedOrdersContainer .listContainer .listDetail {
  padding: 10px;
}

#offersOpenContainer .listContainer .listItemDetail,
#pendingOrdersContainer .listContainer .listItemDetail,
#shippedOrdersContainer .listContainer .listItemDetail {
  padding: 10px;
}

.orderListTable {
  width: 100%;
}

.orderListTable th,
.orderListTable td {
  text-align: left;
}

.orderListTable .thPos {
  width: 30px;
}

.orderListTable .thQty {
  width: 50px;
}

.orderListTable .thPrice,
.orderListTable .thPriceSum,
.orderListTable .tdPrice,
.orderListTable .thQty,
.orderListTable .tdQty {
  text-align: right;
}

.orderListTable .input {
  width: 100%;
}

#listItemsGrid {
  display: -ms-grid;
  -ms-grid-columns: 2fr 1fr 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

#listItemsGrid a {
  text-decoration: none;
  color: #26234e;
}

#listItemsGrid .itemContainer {
  margin: 10px;
  border: 1px solid #87dbfb;
  min-width: 150px;
}

#listItemsGrid .itemContainer h4 {
  margin: 0;
  padding: 5px;
  background-color: #87dbfb;
}

#listItemsGrid .itemContainer:hover {
  border-color: #26234e;
}

#listItemsGrid .itemContainer:hover h4 {
  background-color: #26234e;
  color: #fff;
}

#listItemsGrid .itemContainer .itemDetailContainer {
  width: 100%;
  box-sizing: border-box;
  max-height: 105px;
  height: 105px;
  overflow: hidden;
}

#listItemsGrid .itemContainer .itemDetailContainer .imgContainer {
  box-sizing: border-box;
  width: 33%;
  padding: 5px;
}

#listItemsGrid .itemContainer .itemDetailContainer .imgContainer img {
  width: 100%;
}

#listItemsGrid .itemContainer .itemDetailContainer .descContainer {
  box-sizing: border-box;
  padding: 5px;
  width: 65%;
}

#itemListContainer {
  width: 100%;
}

#itemListContainer .itemListLinkBox {
  color: #26234e;
}

#itemListContainer .itemListLinkBox .itemListRow {
  border: 1px solid #87dbfb;
  margin-bottom: 20px;
  padding: 10px;
}

#itemListContainer .itemListLinkBox .itemListRow .itemListImgContainer {
  width: 150px;
}

#itemListContainer .itemListLinkBox .itemListRow .itemListImgContainer img {
  width: 150px;
}

#itemListContainer .itemListLinkBox .itemListRow .itemListRowcontentContainer {
  margin-left: 20px;
  max-width: 70%;
}

#itemListContainer .itemListLinkBox .itemListRow .itemListRowcontentContainer .itemName {
  font-size: 16px;
  font-color: #0088BB;
}

#itemListContainer .itemListLinkBox .itemListRow .itemListRowcontentContainer .itemDesc {
  margin-top: 20px;
  word-wrap: break-word;
}

#itemListContainer .itemListLinkBox .itemListRow:hover {
  border-color: #0088BB;
}

#itemListContainer .itemListLinkBox:visited {
  color: #26234e;
}

#contactContainer #contactFormCheckBox,
#contactContainer #registrationFormCheckBox,
#registrationContainer #contactFormCheckBox,
#registrationContainer #registrationFormCheckBox,
#requestLoginForCollegue #contactFormCheckBox,
#requestLoginForCollegue #registrationFormCheckBox {
  width: 16px;
  height: 16px;
  vertical-align: sub;
}

#contactContainer label,
#registrationContainer label,
#requestLoginForCollegue label {
  cursor: pointer;
}

#contactContainer label a,
#registrationContainer label a,
#requestLoginForCollegue label a {
  color: #87dbfb;
}

#contactContainer input,
#contactContainer textarea,
#contactContainer select,
#registrationContainer input,
#registrationContainer textarea,
#registrationContainer select,
#requestLoginForCollegue input,
#requestLoginForCollegue textarea,
#requestLoginForCollegue select {
  padding: 10px;
  width: 100%;
  max-width: 350px;
  margin-bottom: 20px;
  outline: none !important;
  border: 1px solid #bbb;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 14px;
}

#contactContainer input:focus,
#contactContainer textarea:focus,
#contactContainer select:focus,
#registrationContainer input:focus,
#registrationContainer textarea:focus,
#registrationContainer select:focus,
#requestLoginForCollegue input:focus,
#requestLoginForCollegue textarea:focus,
#requestLoginForCollegue select:focus {
  outline: none !important;
  border: 1px solid #87dbfb;
}

#contactContainer textarea,
#registrationContainer textarea,
#requestLoginForCollegue textarea {
  max-width: 350px;
}

#registrationContainer .colReg,
#requestLoginForCollegue .colReg {
  min-width: 350px;
}

#registrationContainer .colReg input,
#requestLoginForCollegue .colReg input {
  max-width: 300px;
  font-size: 15px;
  padding: 8px;
}

#registrationContainer .colReg .rowEmpty,
#requestLoginForCollegue .colReg .rowEmpty {
  height: 70px;
}

#registrationContainer .colReg .copyArrow,
#requestLoginForCollegue .colReg .copyArrow {
  font-size: 30px;
  cursor: pointer;
  position: absolute;
  margin: 25px 0 0 -27px;
}

#registrationContainer .colReg .copyArrow:hover,
#requestLoginForCollegue .colReg .copyArrow:hover {
  color: #87dbfb;
}

#registrationContainer .colReg #registerFormatFields,
#requestLoginForCollegue .colReg #registerFormatFields {
  color: #87dbfb;
  cursor: pointer;
  margin-top: 20px;
  display: block;
}

#registrationContainer .colReg #registerFormatFields:hover,
#requestLoginForCollegue .colReg #registerFormatFields:hover {
  color: #26234e;
}

#registrationContainer label,
#requestLoginForCollegue label {
  font-size: 14px;
  display: block;
}

#registrationContainer input,
#requestLoginForCollegue input {
  margin-bottom: 10px;
}

#registrationContainer input:required,
#requestLoginForCollegue input:required {
  background: #f8f1c2;
}

#registrationContainer textarea:required,
#requestLoginForCollegue textarea:required {
  background: #f8f1c2;
}

#registrationContainer .filled,
#requestLoginForCollegue .filled {
  background: #fff !important;
}

#registrationContainer .registrationFormPLZCol,
#requestLoginForCollegue .registrationFormPLZCol {
  width: 110px;
}

#registrationContainer .registrationFormPLZCol input,
#requestLoginForCollegue .registrationFormPLZCol input {
  width: 70px;
}

#registrationContainer #registrationFormCity,
#requestLoginForCollegue #registrationFormCity {
  max-width: 190px;
}

#registrationContainer #registrationFormGreeting,
#registrationContainer #registrationFormCountry,
#requestLoginForCollegue #registrationFormGreeting,
#requestLoginForCollegue #registrationFormCountry {
  max-width: 320px;
  margin-bottom: 10px;
}

#registrationContainer .registrationFormCheckBoxRow label,
#requestLoginForCollegue .registrationFormCheckBoxRow label {
  display: inline-block;
}

.captchaContainer {
  width: 520px;
  margin-top: 20px;
}

.captchaContainer div {
  box-sizing: border-box;
}

.captchaContainer .txtRobot {
  padding: 3px;
  border: 3px solid #fff;
}

.captchaContainer .txtRobot h4 {
  margin: 0;
  padding: 0 0 10px 0;
}

.captchaContainer .error {
  border: 3px solid red;
}

.captchaContainer .capChkBoxDiv {
  position: relative;
  float: left;
  margin-right: 4px;
}

.captchaContainer .capChkBoxDiv .capChkBoxLabel img {
  border: 6px solid #fff;
  width: 150px;
}

.captchaContainer .capChkBoxDiv .capChkBoxLabel img:hover {
  border-color: #87dbfb;
}

.captchaContainer .capChkBoxDiv .capChkBoxLabel .imgActive {
  border: 6px solid #17B40B;
  opacity: 0.5;
}

.captchaContainer .capChkBoxDiv .capChkBoxLabel .imgActive2 {
  border: 6px solid red;
}

.captchaContainer .capChkBoxDiv .capChkBox {
  display: none;
}

.captchaContainer .capChkBoxDiv .fa-check {
  position: absolute;
  top: 40px;
  left: 67px;
  font-size: 30px;
  display: none;
  opacity: 1 !important;
}

.alert-success {
  width: 100%;
  color: green;
  border: 2px solid green;
  background-color: #eeeeee;
  margin-bottom: 20px;
}

.alert-success .message {
  margin: auto;
  padding: 10px;
  font-weight: bold;
}

.alert-success .message i {
  font-size: 25px;
  vertical-align: middle;
  margin-right: 10px;
}

.alert-success .message .alert-close {
  float: right;
  color: #26234e;
  cursor: pointer;
}

.alert-success .message .alert-close:hover {
  color: #87dbfb;
}

#headerMsg {
  font-size: 16px;
}

#headerMsg .headerMsgContent {
  color: #17B40B;
  padding: 2px;
  height: 30px;
}

#headerMsg .headerMsgContent i {
  font-size: 25px;
}

.flashBox {
  width: 100%;
  margin-bottom: 20px;
  position: relative;
}

.flashBox .flashMsg {
  margin: auto;
  padding: 10px;
  text-align: center;
  background: #eeeeee;
  border-radius: 20px;
}

.flashBox .flashMsg .flashBoxHide {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 10px;
  font-weight: normal;
  color: #26234e;
  transform: rotate(45deg);
}

.flashBox .flashMsg .flashBoxHide:hover {
  color: #87dbfb;
}

.flashBox .flashMsg .flashRegistrationSendContainer {
  max-width: 400px;
  margin: auto;
}

.flashBox .flashMsg .flashRegistrationSendContainer .colLeft {
  width: 120px;
  margin-right: 20px;
  text-align: left;
}

.flashBox .flashMsg .flashCartOrderedContainer {
  max-width: 600px;
  margin: auto;
}

.flashBox .flashMsg .flashCartOrderedContainer .colLeftAdress {
  margin-right: 20px;
  width: 44%;
  text-align: left;
}

.flashBox .flashMsg .flashCartOrderedContainer .addressContainer,
.flashBox .flashMsg .flashCartOrderedContainer .itemListContainer {
  padding: 10px 0;
  border-bottom: 1px solid #26234e;
}

#footer {
  bottom: 0;
  float: none;
  border-top: 3px solid #87dbfb;
  background-color: #fff;
  color: #26234e;
  padding: 20px;
}

#footer #footerContainer #footerGrid {
  display: -ms-grid;
  -ms-grid-columns: 25% 25% 25% 25%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-row-gap: 5px;
  max-width: 1900px;
  grid-column-gap: 50px;
}

#footer #footerContainer #footerGrid i {
  color: #26234e;
  font-size: 20px;
}

#footer #footerContainer #footerGrid h4 {
  color: #26234e;
}

#footer #footerContainer #footerGrid a {
  text-decoration: none;
  color: #26234e;
}

#footer #footerContainer #footerGrid a:hover {
  color: #87dbfb;
}

#footer #footerContainer #footerGrid .r1c1 {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
}

#footer #footerContainer #footerGrid .r1c2 {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
}

#footer #footerContainer #footerGrid .r1c3 {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
}

#footer #footerContainer #footerGrid .r1c4 {
  -ms-grid-row: 1;
  -ms-grid-column: 4;
}

.cartRenameContainer .cartRenameContainerRow {
  padding: 10px 0;
}

.cartRenameContainer .cartRenameContainerLabel {
  width: 250px;
}

.cartRenameContainer .divLabelInput {
  height: 32px;
  line-height: 30px;
}

.cartRenameContainer .divLabelInput label {
  cursor: pointer;
}

.cartRenameContainer #cartBoxRenameNewName,
.cartRenameContainer #cartBoxRenameOldName {
  width: 300px;
}

.cartRenameContainer .cartRenameBoxSave {
  margin-top: 20px;
}

.fancyBoxCartAdressList {
  width: 1500px;
  height: 800px;
}

.fancyBoxCartAdressList .EditBtnNoAdressSelected,
.fancyBoxCartAdressList .EditBtnMainAdressSelected {
  display: none;
  padding: 20px;
}

.fancyBoxCartAdressList .fancyBoxCartAdressListBtnCopySelectedAdresss {
  display: none;
}

.fancyBoxCartAdressList #cartAjaxAdressGridSearchContainer {
  margin-bottom: 20px;
}

.fancyBoxCartAdressList #cartAjaxAdressGridSearchContainer label {
  width: 200px;
  vertical-align: -webkit-baseline-middle;
}

.fancyBoxCartAdressList #cartAjaxAdressGridSearchContainer input {
  width: 390px;
}

.fancyBoxCartAdressList #cartAjaxAdressGridSearchContainer input:focus::placeholder {
  color: transparent;
}

.fancyBoxCartAdressList #AdressListContainerNewAdress {
  display: none;
}

.fancyBoxCartAdressList #AdressListContainerNewAdress .AdressListContainerNewAdressLeft {
  min-width: 600px;
}

.fancyBoxCartAdressList #AdressListContainerNewAdress .AdressListContainerNewAdressRight {
  min-width: 300px;
  margin-left: 10px;
}

.fancyBoxCartAdressList #AdressListContainerNewAdress .AdressListContainerNewAdressRight textarea {
  width: 100%;
  height: 300px;
  border-color: red;
}

.fancyBoxCartAdressList #AdressListContainerNewAdress .label {
  float: left;
  width: 200px;
}

.fancyBoxCartAdressList #AdressListContainerNewAdress .divPlzCity {
  width: 200px;
}

.fancyBoxCartAdressList #AdressListContainerNewAdress .newAdressRow {
  margin: 5px;
}

.fancyBoxCartAdressList #AdressListContainerNewAdress input {
  padding: 5px;
  width: 390px;
}

.fancyBoxCartAdressList #AdressListContainerNewAdress #newAdressPlz {
  width: 95px;
  float: left;
  margin-right: 5px;
}

.fancyBoxCartAdressList #AdressListContainerNewAdress #newAdressCity {
  width: 290px;
}

.fancyBoxCartAdressList #AdressListContainerNewAdress #newAdressCountry {
  width: 390px;
  font-size: 16px;
  padding: 5px;
}

.fancyBoxCartAdressList #AdressListContainerNewAdress #newAdressCountry option {
  font-size: 16px;
}

.fancyBoxCartAdressList #AdressListContainerNewAdress .newAdress,
.fancyBoxCartAdressList #AdressListContainerNewAdress .editAdress {
  display: none;
}

.fancyBoxCartAdressList #AdressListContainerNewAdress .newAdressRowButtons {
  margin-top: 20px;
}

.fancyBoxCartAdressList #AdressListContainerNewAdress .borderRed {
  border-color: #87dbfb;
  background-color: #87dbfb;
}

.fancyBoxCartAdressList #cartAjaxAdressGridList {
  display: -ms-grid;
  -ms-grid-columns: 40px 60px auto auto auto auto auto auto auto auto auto;
  display: grid;
  grid-template-columns: 40px 60px auto auto auto auto auto auto auto auto auto;
  grid-row-gap: 5px;
  background-color: #ffffff;
}

.fancyBoxCartAdressList #cartAjaxAdressGridList .adressItem {
  cursor: pointer;
}

.fancyBoxCartAdressList #cartAjaxAdressGridList .rowWrapper {
  display: contents;
}

.fancyBoxCartAdressList #cartAjaxAdressGridList div {
  padding: 5px;
}

.fancyBoxCartAdressList #cartAjaxAdressGridList div:nth-child(10n + 1) {
  -ms-grid-column: 1;
}

.fancyBoxCartAdressList #cartAjaxAdressGridList div:nth-child(10n + 2) {
  -ms-grid-column: 2;
}

.fancyBoxCartAdressList #cartAjaxAdressGridList div:nth-child(10n + 3) {
  -ms-grid-column: 3;
}

.fancyBoxCartAdressList #cartAjaxAdressGridList div:nth-child(10n + 4) {
  -ms-grid-column: 4;
}

.fancyBoxCartAdressList #cartAjaxAdressGridList div:nth-child(10n + 5) {
  -ms-grid-column: 5;
}

.fancyBoxCartAdressList #cartAjaxAdressGridList div:nth-child(10n + 6) {
  -ms-grid-column: 6;
}

.fancyBoxCartAdressList #cartAjaxAdressGridList div:nth-child(10n + 7) {
  -ms-grid-column: 7;
}

.fancyBoxCartAdressList #cartAjaxAdressGridList div:nth-child(10n + 8) {
  -ms-grid-column: 8;
}

.fancyBoxCartAdressList #cartAjaxAdressGridList div:nth-child(10n + 9) {
  -ms-grid-column: 9;
}

.fancyBoxCartAdressList #cartAjaxAdressGridList div:nth-child(10n + 10) {
  -ms-grid-column: 10;
}

.fancyBoxCartAdressList #cartAjaxAdressGridList div:nth-child(11n + 11) {
  -ms-grid-column: 11;
}

.fancyBoxCartAdressList #cartAjaxAdressGridList .bgGray {
  background-color: #eeeeee;
}

.fancyBoxCartAdressList #cartAjaxAdressGridList .adressListCheckMark {
  display: none;
}

.fancyBoxCartAdressList #cartAjaxAdressGridList .rowSelected {
  background-color: #87dbfb;
}

.fancyBoxCartAdressList #cartAjaxAdressGridList .rowSelected .adressListCheckMark {
  display: inline-block;
}

.fancyBoxDeleteCartBtn {
  width: 220px !important;
}

.fancyBoxCartShipmentList {
  min-width: 700px;
}

.fancyBoxCartShipmentList .check {
  width: 30px;
  line-height: 40px;
  font-size: 20px;
}

.fancyBoxCartShipmentList .check span {
  display: none;
}

.fancyBoxCartShipmentList .shipmentImg {
  width: 70px;
}

.fancyBoxCartShipmentList .shipmentName {
  width: 440px;
  line-height: 40px;
}

.fancyBoxCartShipmentList .shipmentName .pickUpTimeContainer {
  margin-left: 10px;
}

.fancyBoxCartShipmentList .shipmentPrice {
  width: 90px;
  line-height: 40px;
  text-align: right;
}

.fancyBoxCartShipmentList .shipmentRowCart {
  padding: 10px;
  cursor: pointer;
  height: 62px;
}

.fancyBoxCartShipmentList .shipmentRowCart img {
  opacity: 0.5;
}

.fancyBoxCartShipmentList .shipmentRowCart:hover {
  background-color: #87dbfb;
}

.fancyBoxCartShipmentList .shipmentRowCart:hover img {
  opacity: 1;
}

.fancyBoxCartShipmentList .shipmentRowCart select {
  padding: 5px;
}

.fancyBoxCartShipmentList .selectedRow {
  background-color: #87dbfb;
}

.fancyBoxCartShipmentList .selectedRow span {
  display: inline-block;
}

.fancyBoxCartShipmentList .selectedRow img {
  opacity: 1;
}

.fancyBoxCartShipmentList .selectedRow .upsPriceInactive {
  color: #26234e !important;
}

.fancyBoxCartShipmentList .UserUPSAccountContainerLabel {
  margin-right: 20px;
  line-height: 30px;
}

.fancyBoxCartShipmentList #cartShipmentUpsAccountNr {
  width: 95px;
  margin-right: 20px;
}

.fancyBoxCartShipmentList .upsPriceInactive {
  color: #cccccc;
}

.fancyBoxCart #cartCommmentTextarea {
  height: 150px;
  width: 100%;
  font-size: 14px;
}

#fancyBoxOrderCart h3 {
  background: url("./../../web/img/ico/ico_CartHeadline.png") no-repeat 0 0;
  height: 40px;
  margin-left: -22px;
  line-height: 36px;
  text-indent: 40px;
}

#fancyBoxOrderCart .fancyOrderCartSumRow {
  margin-top: 20px;
}

#fancyBoxOrderCart .fancyOrderCartSumColLeft {
  width: 160px;
}

#fancyBoxOrderCart .fancyOrderItemListNameCol {
  width: 180px;
  line-height: 50px;
}

#fancyBoxOrderCart .fancyOrderItemListImgCol {
  width: 50px;
}

#fancyBoxOrderCart .fancyOrderItemListImgCol img {
  width: 50px;
}

#fancyBoxOrderCart .fancyOrderItemListTypeCol {
  line-height: 50px;
  padding-left: 10px;
}

#fancyBoxOrderCart .fancyOrderShipmentImg {
  vertical-align: top;
}

#fancyBoxOrderCart #cartOrderBtn {
  padding-right: 40px;
}

#fancyBoxOrderCart .btnClicked .fa-spinner {
  display: inline-block !important;
}

#fancyBoxOrderCart .cartOrderSpin {
  color: #17B40B;
}

#fancyBoxOrderCart .disableOrderButtonBox {
  border: 2px solid red;
  width: 100%;
  padding: 5px;
}

#fancyBoxOrderCart .disableOrderButtonBox i {
  color: #ff0000 !important;
}

.selectDeliveryNoteContainerRow {
  padding: 4px;
  border-bottom: 1px dotted #000;
}

.selectDeliveryNoteContainerRow label:hover {
  color: #3E59A1;
}

.grayed {
  opacity: 0.6;
}

.inputError {
  border-color: #87dbfb;
  background-color: #87dbfb;
}

.numberError {
  border-color: red !important;
  background-color: #f8f1c2 !important;
}

#fancyBoxCartAsEmailOffer {
  width: 800px;
}

#fancyBoxCartAsEmailOffer table {
  width: 100%;
}

#fancyBoxCartAsEmailOffer table input {
  width: 100%;
}

#itemListExport .successMsgContainer {
  color: green;
  margin-top: 20px;
}

#requestPhoneCall table {
  width: 100%;
}

#requestPhoneCall table input {
  width: 100%;
}

.dz-preview {
  margin-top: 30px;
  margin-bottom: 30px;
  height: 252px;
}

.dz-preview .dz-image {
  float: left;
  margin-right: 10px;
  height: 250px;
}

.dz-preview .dz-image img {
  width: 250px;
  border: 4px solid #26234e;
}

.dz-preview .dz-error-mark,
.dz-preview .dz-success-mark {
  display: none;
}

.dz-progress {
  float: right;
  margin-right: 5px;
  width: 395px;
}

.button {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  height: 53px;
  line-height: 30px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  color: #26234e;
}

.button .width225 {
  width: 225px;
}

.button span.icon,
.button span.title {
  display: block;
  line-height: 50px;
  padding: 0 25px;
}

.button span.cartBtnTitle {
  padding: 0 10px;
}

.button span.left {
  float: left;
}

.button span.right {
  float: right;
}

.button span.icon {
  font-size: 23px;
  background-color: #87dbfb;
  -webkit-box-shadow: 0 3px 0 0 #d1d4d3;
  box-shadow: 0 3px 0 0 #d1d4d3;
  background-repeat: no-repeat;
  background-position: center;
}

.button span.icon img {
  margin: 10px 0 0 0;
}

.button span.title {
  -webkit-box-shadow: 0 3px 0 0 #d1d4d3;
  box-shadow: 0 3px 0 0 #d1d4d3;
  background-color: #eeeeee;
}

.button span.icon-itemDetailBtnCart {
  background-image: url("./../../web/img/ico/ico_btnCart32.png");
}

.button span.icon-itemDetailBtnOffer {
  background-image: url("./../../web/img/ico/ico_btnOffer32.png");
}

.button span.icon-cartBtnEdit {
  background-image: url("./../../web/img/ico/ico_btnEdit32.png");
}

.button:hover span.icon {
  background-color: #26234e;
  -webkit-box-shadow: 0 3px 0 0 #26234e;
  box-shadow: 0 3px 0 0 #26234e;
}

.button:hover span.title {
  -webkit-box-shadow: 0 3px 0 0 #26234e;
  box-shadow: 0 3px 0 0 #26234e;
}

.button:hover span.icon-itemDetailBtnCart {
  background-image: url("./../../web/img/ico/ico_btnCart32_hover.png");
}

.button:hover span.icon-itemDetailBtnOffer {
  background-image: url("./../../web/img/ico/ico_btnOffer32_hover.png");
}

.button:hover span.icon-cartBtnEdit {
  background-image: url("./../../web/img/ico/ico_btnEdit32_hover.png");
}

.fancybox-content .fancyBoxOrderCartImgContainer {
  margin-top: -44px;
  margin-left: -44px;
  margin-right: -44px;
  max-height: 100px;
  overflow: hidden;
}

.fancybox-content .fancyBoxOrderCartImgContainer img {
  width: 100%;
  margin-top: -50px;
}

.fancybox-content .fancyBoxCartDelNoteImgContainer {
  margin-top: -44px;
  margin-left: -44px;
  margin-right: -44px;
  max-height: 100px;
  overflow: hidden;
}

.fancybox-content .fancyBoxCartDelNoteImgContainer img {
  width: 100%;
}

.fancybox-container h3 {
  text-align: center;
}

.fancybox-container .fancybox-bg {
  background: #a0a0a0;
}

.smallBtn {
  height: 24px;
  width: 24px;
  overflow: hidden;
  margin-left: 20px;
  cursor: pointer;
}

.smallBtn:hover {
  color: #87dbfb;
}

/* -- quantity box -- */

.quantity {
  display: inline-block;
}

.quantity .input-text.qty {
  width: 35px;
  height: 39px;
  padding: 0 5px;
  text-align: center;
  background-color: transparent;
  border: 1px solid #87dbfb;
}

.quantity.buttons_added {
  text-align: left;
  position: relative;
  white-space: nowrap;
  vertical-align: top;
}

.quantity.buttons_added .qtyChanged {
  font-size: 24px;
  font-weight: bold;
  color: #17B40B;
}

.quantity.buttons_added input {
  display: inline-block;
  margin: 0;
  vertical-align: top;
  box-shadow: none;
}

.quantity.buttons_added .minus,
.quantity.buttons_added .plus {
  padding: 7px 10px 8px;
  height: 41px;
  background-color: #ffffff;
  border: 1px solid #87dbfb;
  cursor: pointer;
  font-size: 20px;
  width: 33px;
}

.quantity.buttons_added .minus {
  border-right: 0;
  margin-right: -3px;
}

.quantity.buttons_added .plus {
  border-left: 0;
  margin-left: -3px;
}

.quantity.buttons_added .minus:hover,
.quantity.buttons_added .plus:hover {
  background: #87dbfb;
}

.quantity input::-webkit-outer-spin-button,
.quantity input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0;
}

.quantity.buttons_added .minus:focus,
.quantity.buttons_added .plus:focus {
  outline: none;
}

.grayScale {
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.row-gap5 {
  row-gap: 5px;
}

.row-gap10 {
  row-gap: 10px;
}

.rowAdmin {
  background-color: #f8f1c2;
}

.backgroundYellow {
  background-color: #f8f1c2;
}

.backgroundRed {
  background-color: red !important;
}

.fontBlack {
  color: black !important;
}

.font18 {
  font-size: 18px;
}

.font16 {
  font-size: 16px;
}

.font14 {
  font-size: 14px;
}

#fixedsticky {
  z-index: 999;
  background-color: #fff;
}

/* The sticky class is added to the navbar with JS when it reaches its scroll position */

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.stickyHeaderTop {
  position: fixed;
  top: 0;
  width: 100%;
}

.stickyContentLeft {
  position: fixed;
  top: 100px;
  width: 400px;
}

/* Add some top padding to the page content to prevent sudden quick movement (as the navigation bar gets a new position at the top of the page (position:fixed and top:0) */

.sticky + .content + .stickyContentLeft {
  padding-top: 60px;
}

.floatLeft {
  float: left;
}

.floatRight {
  float: right;
}

.hidden {
  display: none;
}

.block {
  display: block;
}

.clear:after {
  content: "";
  display: table;
  clear: both;
}

.warnings {
  color: red;
}

.linkA {
  text-decoration: none;
  color: #26234e;
}

.linkA:hover {
  color: #87dbfb;
}

.inputBorderRed {
  border-color: red !important;
}

#userMessages {
  background: #87dbfb;
  opacity: 0.9;
  color: #26234e;
  position: fixed;
  bottom: 40px;
  right: 20px;
  min-width: 300px;
  max-width: 400px;
  height: 0;
  overflow: hidden;
  padding: 10px;
  font-size: 16px;
  font-weight: normal;
  box-shadow: 0 0 15px #26234e;
  outline: none;
  display: none;
}

#userMessages .usermsgArrow {
  margin: 4px 4px 0 0;
}

#userMessages .usermsgCart {
  margin: 0 4px 2px 0;
}

#fancyBoxItemQuestion #fancyBoxItemQuestionTextAreaContainer,
#fancyBoxItemQuestion #fancyBoxItemQuestionEmailContainer {
  margin-bottom: 10px;
}

#fancyBoxItemQuestion #fancyBoxItemQuestionTextAreaContainer input,
#fancyBoxItemQuestion #fancyBoxItemQuestionTextAreaContainer textarea,
#fancyBoxItemQuestion #fancyBoxItemQuestionEmailContainer input,
#fancyBoxItemQuestion #fancyBoxItemQuestionEmailContainer textarea {
  max-width: 600px;
  width: 100%;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 14px;
  padding: 5px;
  outline: none !important;
  border: 1px solid #aaa;
}

#fancyBoxItemQuestion #fancyBoxItemQuestionTextAreaContainer input:focus,
#fancyBoxItemQuestion #fancyBoxItemQuestionTextAreaContainer textarea:focus,
#fancyBoxItemQuestion #fancyBoxItemQuestionEmailContainer input:focus,
#fancyBoxItemQuestion #fancyBoxItemQuestionEmailContainer textarea:focus {
  outline: none !important;
  border: 1px solid #87dbfb;
}

#fancyBoxItemQuestion #fancyBoxItemQuestionReturnMsg {
  margin: 10px 0;
}

#fancyBoxItemQuestion #fancyBoxItemQuestionReturnMsg .inputError {
  border: 1px solid red;
}

#fancyBoxItemQuestion #fancyBoxItemQuestionReturnMsg #fancyBoxItemQuestionSuccess {
  color: #17B40B;
}

#fancyBoxItemQuestion .fancyBoxItemQuestionItemImg {
  width: 180px;
  margin-top: -25px;
  padding-left: 20px;
}

#fancyBoxCartDeleteItem .cartItemDeleteBtn {
  margin-top: 20px;
}

#fancyBoxNewCart .newCartRow,
#fancyBoxNewOffer .newCartRow,
#fancyBoxNewCartAddItem .newCartRow {
  width: 50%;
  line-height: 51px;
}

#fancyBoxNewCart #fancyBoxNewCartInputName,
#fancyBoxNewCart #fancyBoxNewCartInputNameNorm,
#fancyBoxNewCart #fancyBoxNewCartInputNameAddItem,
#fancyBoxNewOffer #fancyBoxNewCartInputName,
#fancyBoxNewOffer #fancyBoxNewCartInputNameNorm,
#fancyBoxNewOffer #fancyBoxNewCartInputNameAddItem,
#fancyBoxNewCartAddItem #fancyBoxNewCartInputName,
#fancyBoxNewCartAddItem #fancyBoxNewCartInputNameNorm,
#fancyBoxNewCartAddItem #fancyBoxNewCartInputNameAddItem {
  width: 90%;
}

.fancyBoxCartAdressList {
  overflow-y: scroll;
}

.dropzone {
  width: 100%;
  height: 50px;
  border: 1px solid #26234e;
  padding: 10px;
  margin-top: 20px;
  background-color: #eeeeee;
  cursor: pointer;
}

.dropzone:hover {
  border: 1px dashed #26234e;
}

.dropzone .dz-message span {
  line-height: 25px;
}

.dropzone .dz-message span:before {
  color: #f8d775 !important;
  content: '\f07c';
  font-family: "Font Awesome 5 Free";
  font-size: 25px;
  font-weight: 900;
  float: left;
  margin-right: 10px;
}

.dz-upload {
  display: block;
  background-color: #87dbfb;
  height: 5px;
  width: 0%;
}

.dz-error-message {
  display: block;
  color: red;
  height: 50px;
}

#fileTransferContainer .filet {
  width: 100%;
}

#fileTransferContainer .filet .dz-message {
  margin-top: 20px;
}

#fileTransferContainer .filet .dz-message span {
  line-height: 25px;
}

#fileTransferContainer .filet .dz-message span:before {
  color: #f8d775 !important;
  content: '\f07c';
  font-family: "Font Awesome 5 Free";
  font-size: 25px;
  font-weight: 900;
  float: left;
  margin-right: 10px;
}

#fileTransferContainer .dz-drag-hover {
  border: 2px dashed #26234e;
  background: #87dbfb;
  width: 100%;
}

#fileTransferContainer .dz-drag-hover span {
  display: none;
}

#fileTransferContainer .dz-drag-hover:before {
  content: '\f019';
  font-family: "Font Awesome 5 Free";
  font-size: 25px;
  font-weight: 900;
  margin: 45%;
}

.dz-drag-hover {
  border: 2px dashed #26234e;
  background: #87dbfb;
}

.dz-drag-hover span {
  display: none;
}

.dz-drag-hover:before {
  content: '\f019';
  font-family: "Font Awesome 5 Free";
  font-size: 25px;
  font-weight: 900;
  margin: 45%;
}

.btnDeleteFile {
  color: red;
  cursor: pointer;
}

.btnDeleteFile:hover {
  color: #87dbfb;
}

.availability-green {
  color: #17B40B;
  font-size: 18px;
  vertical-align: middle;
}

.bold {
  font-weight: bold;
}

.width200 {
  width: 200px;
}

.lineHeight30 {
  line-height: 30px;
}

.marginTop-10px {
  margin-top: -10px;
}

.margin0 {
  margin: 0;
}

.marginTop0 {
  margin-top: 0px;
}

.marginTop5 {
  margin-top: 5px;
}

.marginTop7 {
  margin-top: 7px;
}

.marginTop10 {
  margin-top: 10px;
}

.marginTop13 {
  margin-top: 13px;
}

.marginTop20 {
  margin-top: 20px;
}

.marginTop40 {
  margin-top: 40px;
}

.marginTop-80 {
  margin-top: -80px;
}

.marginBot10 {
  margin-bottom: 10px;
}

.margin20 {
  margin-top: 20px;
}

.marginLeft40px {
  margin-left: 40px;
}

.marginLeft100 {
  margin-left: 100px;
}

.marginLeft10 {
  margin-left: 10px;
}

.marginLeft20 {
  margin-left: 20px !important;
}

.marginLeft40 {
  margin-left: 40px;
}

.marginLeft70 {
  margin-left: 70px;
}

.margingRight5 {
  margin-right: 5px;
}

.marginRight5 {
  margin-right: 5px;
}

.marginRight10 {
  margin-right: 10px;
}

.margingRight20 {
  margin-right: 20px;
}

.marginRight20 {
  margin-right: 20px;
}

.marginLeft25 {
  margin-left: 25px;
}

.marginRight-20 {
  margin-right: -20px;
}

.padding5 {
  padding: 5px;
}

.padding20 {
  padding: 20px;
}

.padding10 {
  padding: 10px;
}

.padding740 {
  padding: 7px 40px !important;
}

.padding12401240 {
  padding: 12px 40px !important;
}

.padding12 {
  padding: 12px !important;
}

.width50per {
  width: 50%;
}

.width45per {
  width: 45%;
}

.width51per {
  width: 51%;
}

.width40per {
  width: 40%;
}

.width71per {
  width: 71%;
}

.width24per {
  width: 24%;
}

.width90per {
  width: 90%;
}

.width100per {
  width: 100%;
}

.width30px {
  width: 30px;
}

.width200 {
  width: 200px;
}

.width100 {
  width: 100px;
}

.width120 {
  width: 120px;
}

.width60 {
  width: 60px;
}

.width30 {
  width: 30px;
}

.width300 {
  width: 300px;
}

.width340px {
  width: 340px;
}

.width400 {
  width: 400px;
}

.maxWidth310 {
  max-width: 310px;
}

.maxWidth400 {
  max-width: 400px;
}

.minHeight200px {
  min-height: 200px;
}

.textAlignLeft {
  text-align: left;
}

.textAlignRight {
  text-align: right;
}

.textAlignCenter {
  text-align: center !important;
}

.verticalAlignTop {
  vertical-align: top;
}

.verticalAlignBottom {
  vertical-align: bottom;
}

.lineHeight10 {
  line-height: 10px;
}

.fontArial {
  font-family: Arial;
}

.font10pt {
  font-size: 10pt;
}

.font11pt {
  font-size: 14pt !important;
}

.font12pt {
  font-size: 12pt;
}

.font22 {
  font-size: 22px;
}

.font32 {
  font-size: 32px;
}

.font40 {
  font-size: 40px !important;
}

.font70 {
  font-size: 70px;
}

.checkedGreen {
  color: #17B40B;
}

.fontBigGreen {
  color: #17B40B;
  font-weight: bold;
  font-size: 30px !important;
}

.displayblock {
  display: block;
}

.borderSolid {
  border: 1px solid #26234e;
}

.whiteSpacePre {
  white-space: pre;
}

.adminRed {
  color: red !important;
}

.blue {
  color: blue !important;
}

.blueBold {
  color: #0088BB;
  font-weight: bold;
}

.screenHide {
  display: none !important;
}

.rotate146 {
  transform: rotate(146deg);
}

.fliesstext_rot {
  color: red;
}

.fliesstext_green {
  color: green;
}

.fliesstext_gruen {
  color: green;
}

.fliesstext_blau {
  color: #0088BB;
}

.fliesstext_blue_bold {
  color: blue;
  font-weight: bold;
}

.fliesstext_turquoise {
  color: #00dfd7;
}

.fliesstext_magenta {
  color: #ff00ea;
}

.fliesstext_yellow {
  color: #ffde00;
}

.tableBackGreen {
  background-color: green !important;
}

.tableBackYellow {
  background-color: #f8f1c2 !important;
}

.tableBackLightRed {
  background-color: #ff9d9d !important;
}

.cursorPointer {
  cursor: pointer;
}

textarea {
  font-family: "Open Sans", Arial, sans-serif;
}

.fancybox-close-small {
  text-weight: bold;
}

#tableTemplate tr td br {
  mso-data-placement: same-cell;
}

#chatShowBtn {
  width: 54px;
  height: 54px;
  -webkit-box-shadow: 7px 5px 10px 2px rgba(0, 0, 0, 0.4);
  box-shadow: 7px 5px 10px 2px rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 200px;
  right: 40px;
  border-radius: 8px 8px;
  padding: 5px;
  font-size: 45px;
  margin: auto;
  color: white;
  background-color: red;
  cursor: pointer;
  z-index: 1000;
}

#chatShowBtn #chatShowBtnIcoContainer {
  margin-top: -5px;
  margin-left: 1px;
}

#chatShowBtn:hover {
  background-color: white;
  color: #26234e;
}

.chatHome {
  transition: all 1s ease-in;
  -webkit-transition: all 1s ease-in;
  -moz-transition: all 1s ease-in;
  -o-transition: all 1s ease-in;
}

#chatBoxRight {
  z-index: 1000;
  height: 600px;
  position: fixed;
  border-radius: 12px 12px;
  overflow: hidden;
  display: none;
  margin: auto;
  top: 200px;
  right: 40px;
  -webkit-box-shadow: 7px 5px 10px 2px rgba(0, 0, 0, 0.4);
  box-shadow: 7px 5px 10px 2px rgba(0, 0, 0, 0.4);
  background: linear-gradient(white 0%, #f9f9f9 100%);
}

#chatBoxRight .chatWrapper #chatHeadlineLeft {
  background-color: #87dbfb;
  font-size: 20px;
  font-weight: bold;
  width: 400px;
  padding: 5px;
  cursor: pointer;
  border-right: 0px solid #fff;
  line-height: 45px;
  height: 40px;
}

#chatBoxRight .chatWrapper #chatHeadlineLeft i {
  font-size: 30px;
}

#chatBoxRight .chatWrapper #chatHeadlineLeft .fa-chevron-down {
  position: absolute;
  right: 10px;
  line-height: 45px;
}

#chatBoxRight .chatWrapper #chatHeadlineLeft .fa-comments {
  color: red;
}

#chatBoxRight .chatWrapper #chatHeadlineLeft .slideBtn {
  position: absolute;
  transform: rotate(90deg);
  font-size: 20px;
  line-height: 30px;
  font-weight: normal;
}

#chatBoxRight .chatWrapper #chatHeadlineLeft .slideBtn:before {
  content: "\00276D" !important;
}

#chatBoxRight .chatWrapper #chatHeadlineLeft:hover {
  background-color: #3E59A1;
  color: #fff;
}

#chatBoxRight .chatWrapper .chatHeadlineLeftActive .slideBtn {
  transform: rotate(270deg) !important;
}

#chatBoxRight .chatWrapper #chatContentCol {
  float: left;
  width: 410px;
}

#chatBoxRight .chatWrapper #chatContentCol #chatContentContainer {
  max-height: 450px;
  padding: 0 0 0 10px;
  position: relative;
  overflow: hidden;
}

#chatBoxRight .chatWrapper #chatContentCol #chatContentContainer .bgHr {
  background-color: #f8f1c2 !important;
}

#chatBoxRight .chatWrapper #chatContentCol #chatContentContainer #chatMsgContainer {
  overflow-y: scroll;
  max-height: 450px;
}

#chatBoxRight .chatWrapper #chatContentCol #chatContentContainer #chatMsgContainer .newMsg {
  background: #f8f1c2 !important;
  font-size: 12px;
  color: red;
}

#chatBoxRight .chatWrapper #chatContentCol #chatContentContainer #chatMsgContainer .chatSender,
#chatBoxRight .chatWrapper #chatContentCol #chatContentContainer #chatMsgContainer .chatDate {
  font-size: 12px;
}

#chatBoxRight .chatWrapper #chatContentCol #chatContentContainer #chatMsgContainer .msgContainer {
  background: #eeeeee;
  margin: 5px 0;
  padding: 5px;
  width: 90%;
  border-radius: 10px;
  overflow-wrap: break-word;
  margin-right: 20px;
}

#chatBoxRight .chatWrapper #chatContentCol #chatContentContainer #chatMsgContainer .chatHideMsg {
  cursor: pointer;
  font-weight: bold;
}

#chatBoxRight .chatWrapper #chatContentCol #chatContentContainer #chatMsgContainer .chatHideMsg:hover {
  color: #17B40B;
}

#chatBoxRight .chatWrapper #chatContentCol label {
  cursor: pointer;
}

#chatBoxRight .chatWrapper #chatContentCol #chatInputEmailForAnswerContainer {
  background: #fff;
}

#chatBoxRight .chatWrapper #chatContentCol #chatInputEmailForAnswerContainer #chatInputEmail {
  padding: 5px;
  margin: 5px 0;
  width: 90%;
}

#chatBoxRight .chatWrapper #chatContentCol .loggedIn {
  display: none;
}

#chatBoxRight .chatWrapper #chatContentCol #chatInputContainer {
  background: #fff;
  padding: 10px;
  width: 100%;
  position: absolute;
  box-sizing: border-box;
  bottom: 0;
}

#chatBoxRight .chatWrapper #chatContentCol #chatInputContainer .chatInputContainerEmailAnswer {
  padding-bottom: 5px;
}

#chatBoxRight .chatWrapper #chatContentCol #chatInputContainer .chatInputContainerEmailAnswer .chatInputContainerEmailAnswerIconContainer {
  margin-right: 3px;
}

#chatBoxRight .chatWrapper #chatContentCol #chatInputContainer .chatInputContainerEmailAnswer .chatInputContainerEmailAnswerIconContainer .fa-envelope {
  font-size: 20px;
}

#chatBoxRight .chatWrapper #chatContentCol #chatInputContainer .chatInputContainerEmailAnswer #chatAnswerAsEmailCheckBox {
  transform: scale(1.5);
}

#chatBoxRight .chatWrapper #chatContentCol #chatInputContainer #chatInputTextareaCol {
  width: 290px;
}

#chatBoxRight .chatWrapper #chatContentCol #chatInputContainer #chatInputTextareaCol textarea {
  width: 100%;
  height: 32px;
  font-size: 14px;
  background-color: #f8f1c2;
  border-radius: 10px;
}

#chatBoxRight .chatWrapper #chatContentCol #chatInputContainer #chatInputMsgSendCol {
  float: right;
  margin-left: 10px;
}

#chatBoxRight .chatWrapper #chatContentCol #chatInputContainer #chatInputMsgSendCol button {
  background-color: #eeeeee;
  padding: 10px;
  border-radius: 10px;
}

#chatBoxRight .chatWrapper #chatContentCol #chatInputContainer .fa-envelope {
  font-size: 16px;
}

.chatEmail {
  overflow-y: scroll;
  max-height: 450px;
}

.chatEmail .newMsg {
  background: #f8f1c2 !important;
  font-size: 12px;
  color: red;
}

.chatEmail .chatSender,
.chatEmail .chatDate {
  font-size: 12px;
}

.chatEmail .msgContainer {
  background: #eeeeee;
  margin: 5px 0;
  padding: 5px;
  width: 90%;
  border-radius: 10px;
  overflow-wrap: break-word;
  margin-right: 20px;
}

.chatEmail .chatHideMsg {
  cursor: pointer;
  font-weight: bold;
}

.chatEmail .chatHideMsg:hover {
  color: #17B40B;
}

@-webkit-keyframes blinker {
  from {
    background-color: #87dbfb;
  }

  to {
    background-color: red;
  }
}

.blink {
  color: #fff;
  text-decoration: blink;
  -webkit-animation-name: blinker;
  -webkit-animation-duration: 0.6s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-direction: alternate;
}

/*
#chatBox{
	position: fixed;
	bottom: 100px;
	right: 0px;
	width: 400px;
	background: #fff;
	border: 1px solid $color-darkBlue;
	border-radius: 8px 8px 0 0;
	z-index: 1000;
	background: #fff;
	min-width: 400px;
	min-height: 253px;
	
	#chatBtnSendMsg {
		padding: 10px;
	}
	
	#chatHeadline {
		background: $color-orange;
		padding: 10px;
		margin: 0;
		cursor: pointer;
		//background: #fff;
		overflow: hidden;
		border-radius: 8px 8px 0 0;
		i {
			font-size: 25px;
			margin-right: 20px;
		}
		.iFloatRight {
			float: right;		
		}
		.chev {
			display: none;
		}
		.fa-window-maximize, .fa-window-minimize, .fa-arrows-alt {
			&:hover {
				color: #fff;
			}	
		}
	
	}
		
	#chatContentContainer {
		//display: none;
		max-height: 500px;
		padding: 10px;
		position: relative;
		background: #fff;
		overflow: hidden;
		
		.bgHr{
			background-color: #e3fdff !important;
		}
		
		.btnExpandLeft{
			position: absolute;
			left: -36px;
			height: 47px;
			width: 35px;
			background: $color-orange;
			border-radius: 6px 0 0 6px;
			font-size: 20px;
			line-height: 51px;
			margin: auto 0;
			cursor: pointer;
			i {
				margin-left: 10px;
				font-size: 25px;
			}
		
			&:hover {
				background: $color-darkBlue;
				color: #fff;
			}
		}
			
		
		#chatMsgContainer{
			overflow-y: scroll;
			max-height: 200px;

			.newMsg {
				background: $color-orange !important;
			}
			
			.chatSender, .chatDate{
				font-size: 12px;
				
			}
			
			.msgContainer{
				background: $color-gray;
				margin: 5px 0;
				padding: 5px;
				//border: 1px solid $color-orange;
				border-radius: 5px;
				.chatHideMsg{
					cursor: pointer;
					font-weight: bold;
					&:hover {
						color: $color-green;
					}
				}
			}
		
		}
	}

	label {
		cursor: pointer;
	}
	#chatInputEmailForAnswerContainer {
		display: none;
		background: #fff;
		#chatInputEmail {
			padding: 5px;
			margin: 5px 0;
			width: 90%;
		}
	}

	#chatInputContainer{
		background: #fff;
		padding: 10px;
		
		//test
		width: 100%;
		position: absolute;
		box-sizing: border-box;
		bottom: 0;
		#chatInputTextareaCol {
			width: 70%;
			
			textarea {
				width: 100%;
				height: 4em;
				font-size: 13px;
				
			}
		}
		#chatInputMsgSendCol{
			float: right;
			margin-right: 10px;
			button {
				height: 4em;
			}
		}
	}
	 .ui-icon-gripsmall-diagonal-se {
	 	//background: #ccc;
	 	height: 20px;
	 	width: 20px;
	 	position: absolute;
	 	right: 0;
	 	display: block;
	 	bottom: 0;
	 	cursor: nwse-resize;
	 	transform: rotate(45deg);
	 	&:after {
	 		font: {
		      family: "Font Awesome 5 Free";
		      size: 20px;
		      weight: 900;
		      
		    }
		   	
	 		content: '\f337';
	 		
	 	}
	 	
	 }

}
*/

.noText {
  opacity: 0.4;
}

.board {
  position: relative;
}

.board-column {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  background: #eeeeee;
  border-radius: 3px;
  z-index: 1;
}

.board-column .muuriListHeaderRow {
  position: relative;
  border: 10px solid #eeeeee;
  border-bottom: 0;
  border-radius: 4px;
  padding: 5px 20px;
  background-color: #fff;
  margin-bottom: -5px;
}

.board-column.muuri-item-releasing {
  z-index: 2;
}

.board-column.muuri-item-dragging {
  z-index: 3;
  cursor: move;
}

.board-column-header {
  position: relative;
  min-height: 50px;
  line-height: 50px;
  overflow: hidden;
  padding: 0 20px;
  background: #fff;
  color: #26234e;
  font-size: 16px;
  font-weight: bold;
}

.board-column.cartItemList .board-column-header {
  background: #87dbfb;
}

/* This is the secret sauce,
   always use a wrapper for setting
   the "overflow: scroll/auto" */

.board-column-content-wrapper {
  position: relative;
  overflow-y: auto;
}

/* Never ever set "overflow: auto/scroll"
   to the muuri element, stuff will break */

.board-column-content {
  position: relative;
  border: 10px solid transparent;
  min-height: 95px;
}

.board-item {
  position: absolute;
  width: 100%;
  margin: 5px 0;
}

.board-item.muuri-item-releasing {
  z-index: 9998;
}

.board-item.muuri-item-dragging {
  z-index: 9999;
  cursor: move;
}

.board-item.muuri-item-hidden {
  z-index: 0;
}

.board-item-content {
  position: relative;
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  -webkit-box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.2);
}

.cartItemListRow {
  cursor: pointer;
}

.locked {
  user-select: text !important;
  cursor: auto !important;
}

.locked .board-item-content {
  cursor: auto;
}

.loading {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  text-align: center;
  top: 0;
  left: 0;
  z-index: 99;
  display: none;
}

.loading div {
  left: 45%;
  margin: -25px 0 0 -25px;
  padding: 20px;
  position: absolute;
  top: 50%;
  z-index: 99999;
  font-size: 80px;
  color: #17B40B;
  background-color: #fff;
  border-radius: 80px;
}

.loading div img {
  height: 60px;
}

@media (max-width: 1580px) {
  body {
    font-size: 14px;
  }

  #itemDetailDisplay #itemDetailGridItemTypes {
    grid-template-columns: 290px 290px;
  }

  #itemDetailDisplay #itemDetailGridItemTypes .itemTypeBox {
    max-width: 275px;
  }

  .wrapperUserNav #mainSearchRight #mainSearchInput {
    width: 70%;
    max-width: 500px;
  }

  .rowGrid .colItemComment {
    grid-column: 6 /span 3;
  }

  .rowGrid .colPrice {
    grid-row: 2;
  }
}

@media (max-width: 1600px) {
  .muuriListHeaderRow .rowGrid {
    grid-template-columns: 1px 70px 50px minmax(170px, auto) 90px 90px 50px !important;
    -ms-grid-template-columns: 1px 70px 50px minmax(170px, auto) 90px 90px 50px !important;
  }

  .muuriListHeaderRow .rowGrid .rowGridHeaderColQty {
    grid-row: 1;
    grid-column: 3;
    -ms-grid-row: 1;
    -ms-grid-column: 3;
  }

  .muuriListHeaderRow .rowGrid .rowGridHeaderColItemName {
    grid-row: 1;
    grid-column: 4;
    -ms-grid-row: 1;
    -ms-grid-column: 4;
  }

  .muuriListHeaderRow .rowGrid .rowGridHeaderColComment {
    display: none;
  }

  .muuriListHeaderRow .rowGrid .rowGridHeaderColPrice {
    grid-row: 1;
    grid-column: 5;
    -ms-grid-row: 1;
    -ms-grid-column: 5;
  }

  .muuriListHeaderRow .rowGrid .rowGridHeaderColPriceSum {
    grid-row: 1;
    grid-column: 6;
    -ms-grid-row: 1;
    -ms-grid-column: 6;
  }

  .board-item-content {
    text-align: left;
  }

  .board-item-content .rowGrid {
    grid-template-columns: 1px 70px 50px minmax(170px, auto) 90px 90px 50px !important;
  }

  .board-item-content .rowGrid .colImg {
    grid-row: 3 / span 2;
    -ms-grid-row: 3 / span 2 !important;
  }

  .board-item-content .rowGrid .colItemQty {
    grid-row: 1 /span 2;
    grid-column: 3;
    justify: center;
    -ms-grid-row: 1 !important;
    -ms-grid-row-span: 2;
    -ms-grid-column: 3 !important;
  }

  .board-item-content .rowGrid .colItemName {
    grid-row: 1;
    grid-column: 4;
    -ms-grid-row: 1 !important;
    -ms-grid-column: 4 !important;
  }

  .board-item-content .rowGrid .colItemType {
    grid-row: 2;
    grid-column: 4;
    -ms-grid-row: 2 !important;
    -ms-grid-column: 4 !important;
  }

  .board-item-content .rowGrid .colItemDesc {
    grid-row: 4;
    grid-column: 3 /span 4;
    -ms-grid-row: 4 !important;
    -ms-grid-column: 3 !important;
    -ms-grid-column-span: 4;
  }

  .board-item-content .rowGrid .colItemComment {
    grid-row: 5 !important;
    grid-column: 3 /span 3;
    -ms-grid-row: 5 !important;
    -ms-grid-column: 3 !important;
    -ms-grid-column-span: 3;
    margin-top: 15px;
  }

  .board-item-content .rowGrid .colCommissionNo {
    grid-row: 6 !important;
    grid-column: 3 /span 3;
    -ms-grid-row: 6 !important;
    -ms-grid-column: 3 !important;
    -ms-grid-column-span: 3;
  }

  .board-item-content .rowGrid .colPriceItem {
    grid-column: 5;
    -ms-grid-column: 5 !important;
  }

  .board-item-content .rowGrid .colPriceSum {
    grid-column: 6;
    -ms-grid-column: 6 !important;
  }

  .board-item-content .rowGrid .colDel {
    grid-column: 7;
    -ms-grid-column: 7 !important;
  }
}

@media (max-width: 1400px) {
  #itemDetailDisplay #itemDetailGridItemTypes {
    grid-template-columns: 290px 290px;
  }

  #itemDetailDisplay #itemDetailGridTop {
    grid-template-columns: 150px minmax(280px, auto);
    -ms-grid-columns: 150px 2fr;
  }

  #itemDetailDisplay #itemDetailGridTop #itemImgLeftCol img {
    width: 150px;
  }

  #itemDetailDisplay #itemDetailGridTop .itemRightColGrid {
    grid-template-columns: 140px auto;
  }

  .no-cssgrid {
    grid-template-columns: 100px minmax(300px, auto);
  }

  .no-cssgrid #itemImgLeftCol img {
    width: 100px;
  }

  #listItemsGrid {
    grid-template-columns: 1fr 1fr 1fr;
  }

  #home #homeContainer .homeSlideContainer .action {
    max-width: 680px;
    text-align: justify;
  }

  #home #homeContainer .homeSlideContainer .action a {
    display: inline-block;
  }

  #chatBoxRight {
    height: 400px;
    top: 90px;
  }

  #chatBoxRight #chatContentContainer {
    height: 250px;
  }

  #chatBoxRight #chatContentContainer #chatMsgContainer {
    height: 250px;
  }
}

@media screen and (max-width: 1090px) {
  body {
    min-width: 310px;
    max-width: 1090px;
  }

  .wrapper {
    max-width: 1090px;
  }

  .mobilHead {
    display: block;
  }

  #header #headerTopContent #logo {
    float: none;
  }

  #header #headerTopContent .mobilHead {
    line-height: 39px;
  }

  #header #headerTopContent .mobilHead .buttonEff {
    padding: 10px;
  }

  #header #headerTopContent .mobilHead a {
    color: #26234e;
  }

  #header #headerTopContent .mobilHead a:hover {
    color: #87dbfb;
  }

  #header #headerTopContent #langSelect {
    float: left;
    width: 100%;
  }

  #header #headerTopContent #langSelect #dataContactHead {
    line-height: 30px;
  }

  #header #headerTopContent #langSelect #dataContactHead div {
    float: none;
  }

  #header #headerTopContent #langSelect #dataContactHead .marginRight20 {
    margin-right: 0;
  }

  .wrapperUserNav #mainSearchRight .searchInputClear {
    display: none;
  }

  .wrapperUserNav #mainSearchRight #mainSearchInput {
    width: 70%;
    max-width: 300px;
  }

  #cartDetailDisplay .cartDetailGridTop {
    display: table;
  }

  #cartDetailDisplay .cartDetailGridTop div {
    margin-bottom: 10px;
  }

  #itemDetailDisplay #itemDetailGridTop {
    display: table;
  }

  #footer #footerContainer #footerGrid {
    display: table;
  }

  .board-item-content {
    text-align: left;
  }

  .board-column-header {
    text-indent: -1000px;
  }

  #itemDetailDisplay #itemDetailGridItemTypes {
    grid-template-columns: 290px 290px;
  }

  #leftContainer {
    display: none;
  }

  #mainSearchRight,
  #contentMain {
    margin-left: 0 !important;
  }

  #contentMainMargin {
    margin-left: 10px !important;
  }

  #userHistoryContainer {
    display: none;
  }

  #chatBox {
    display: none;
  }

  #itemDetailDisplay #itemDetailGridTop {
    grid-template-columns: 100px minmax(300px, auto);
  }

  #itemDetailDisplay #itemDetailGridTop #itemImgLeftCol img {
    width: 100px;
  }

  #listItemsGrid {
    display: -ms-grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  #listItemsGrid .itemCol:nth-Child(2n+1) {
    -ms-grid-column: 1;
  }

  #listItemsGrid .itemCol:nth-Child(2n+2) {
    -ms-grid-column: 2;
  }

  #listItemsGrid .itemCol:nth-Child(n+1):nth-Child(-n+2) {
    -ms-grid-row: 1;
  }

  #listItemsGrid .itemCol:nth-Child(n+3):nth-Child(-n+4) {
    -ms-grid-row: 2;
  }

  #listItemsGrid .itemCol:nth-Child(n+5):nth-Child(-n+6) {
    -ms-grid-row: 3;
  }

  #listItemsGrid .itemCol:nth-Child(n+7):nth-Child(-n+8) {
    -ms-grid-row: 4;
  }

  #listItemsGrid .itemCol:nth-Child(n+9):nth-Child(-n+10) {
    -ms-grid-row: 5;
  }

  #listItemsGrid .itemCol:nth-Child(n+11):nth-Child(-n+12) {
    -ms-grid-row: 6;
  }

  .muuriListHeaderRow .rowGrid {
    grid-template-columns: 50px 170px 90px 90px 50px !important;
  }

  .muuriListHeaderRow .rowGrid .rowGridHeaderColQty {
    grid-row: 1;
    grid-column: 1;
  }

  .muuriListHeaderRow .rowGrid .rowGridHeaderColItemName {
    grid-row: 1;
    grid-column: 2;
  }

  .muuriListHeaderRow .rowGrid .rowGridHeaderColComment {
    display: none;
  }

  .muuriListHeaderRow .rowGrid .rowGridHeaderColPrice {
    grid-row: 1;
    grid-column: 3;
  }

  .muuriListHeaderRow .rowGrid .rowGridHeaderColPriceSum {
    grid-row: 1;
    grid-column: 4;
  }

  .board-item-content {
    text-align: left;
  }

  .board-item-content .rowGrid {
    grid-template-columns: 50px 170px 90px 90px 50px !important;
  }

  .board-item-content .rowGrid .colImg {
    display: none;
  }

  .board-item-content .rowGrid .colPosition {
    display: none;
  }

  .board-item-content .rowGrid .colItemQty {
    grid-row: 1/ span2;
    grid-column: 1;
    justify: center;
  }

  .board-item-content .rowGrid .colItemName {
    grid-row: 1;
    grid-column: 2;
  }

  .board-item-content .rowGrid .colItemType {
    grid-row: 2;
    grid-column: 2;
  }

  .board-item-content .rowGrid .colItemDesc {
    grid-row: 4;
    grid-column: 1 /span 4;
  }

  .board-item-content .rowGrid .colItemComment {
    grid-row: 5 !important;
    grid-column: 1 /span 3;
  }

  .board-item-content .rowGrid .colPriceItem {
    grid-column: 3;
  }

  .board-item-content .rowGrid .colPriceSum {
    grid-column: 4;
  }

  .board-item-content .rowGrid .colDel {
    grid-column: 5;
  }

  .cartDetailFurtherCartsContainer {
    display: none;
  }

  .wrapperNav #mainNav li a {
    padding: 9px 15px;
  }

  .wrapperNav #mainNav li .smallLogin {
    display: block !important;
  }
}

@media (max-width: 720px) {
  header {
    padding: 5px;
  }

  .sticky {
    background-color: yellow;
  }

  .sticky #headerTopContent {
    display: none;
  }

  .hideOnMobile {
    display: none;
  }

  #banner {
    display: none;
  }

  #mainSearchLeft {
    display: none;
  }

  .screenHide {
    display: block !important;
  }

  .mobileTopNewCartBtn,
  .mobileTopNewOfferBtn {
    width: 150px;
  }

  .wrapperNav {
    position: relative;
    z-index: 999 !important;
  }

  .wrapperNav #mobileNavContainer {
    height: 39px;
    cursor: pointer;
  }

  .wrapperNav #mobileNavContainer:hover {
    background-color: #87dbfb;
  }

  .wrapperNav #mobileNavContainer:hover #mobileNavLabel {
    color: #26234e;
  }

  .wrapperNav #mobileNavLabel {
    color: #fff;
    width: 100%;
    text-align: center;
    line-height: 39px;
    font-size: 20px;
  }

  .wrapperNav .mainNavContainer {
    overflow: hidden;
    display: none;
  }

  .wrapperNav .mainNavContainer #mainNav {
    margin-top: 46px;
  }

  .wrapperNav .mainNavContainer #mainNav li {
    float: none;
    background-color: #26234e;
    border: 1px solid #87dbfb;
  }

  .wrapperNav .mainNavContainer #mainNav li:hover {
    background-color: #87dbfb;
    color: #26234e;
  }

  .wrapperNav .mainNavContainer #mainNav .pageReload {
    display: none;
  }

  #cartDetailDisplay h1 .cartDetailFurtherCartsContainer {
    margin-top: 20px;
  }

  .wrapperUserNav #mainSearchRight {
    margin-right: 0;
  }

  .wrapperUserNav #mainSearchRight .marginLeft70 {
    margin-left: 0;
  }

  .wrapperUserNav #mainSearchRight #searchResultContainer #searchResultGrid {
    grid-template-columns: auto 0px auto 1px auto;
  }

  .wrapperUserNav #mainSearchRight #searchResultContainer #searchResultGrid .resultImage {
    display: none;
  }

  #itemDetailDisplay .itemDetailBtnContainerTopRight {
    margin-top: 20px;
    float: left;
  }

  #itemDetailDisplay #itemDetailGridItemTypes {
    display: table;
  }

  #itemDetailDisplay #itemDetailGridItemTypes .itemTypeBox {
    margin-right: 5px;
    max-width: 100%;
    margin-bottom: 10px;
  }

  #listItemsGrid {
    display: table;
  }

  #listItemsGrid .itemContainer .itemDetailContainer .imgContainer {
    width: 75px;
  }

  #home #homeContainer .homeSlideContainer .action a {
    display: block;
  }

  #chatBoxRight {
    display: none;
    max-width: 300px;
    top: 40px;
  }

  #chatBoxRight .chatWrapper #chatContentCol {
    max-width: 260px;
  }

  #chatBoxRight .chatWrapper #chatContentCol #chatContentContainer #chatMsgContainer {
    max-height: 210px;
  }
}

.boxBtnContainer {
  margin-top: 20px;
}

.buttonEff {
  background-color: #fff;
  text-decoration: none;
  color: #26234e;
  border: 1px solid #26234e;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
}

.buttonEff .fas,
.buttonEff .fal {
  font-size: 20px;
}

.buttonEff .fa-spinner {
  display: none;
  position: absolute;
  right: 0;
  margin-right: 5px;
}

.btnClicked .fa-spinner {
  display: block !important;
}

/* don't forget to add your own background-color and text color */

.btn-hover-effect {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-delay: 0s;
  position: relative;
  display: block;
  align-items: center;
  padding: 12px 26px 12px 40px;
  transform: translateZ(0);
  text-decoration: none;
  overflow: hidden;
}

/* effect 1 styles */

.btn-hover-effect--effect-1 {
  /* overlay block styles */
  /* icon styles */
}

.btn-hover-effect--effect-1:before {
  position: absolute;
  top: 0;
  left: 0;
  transition-property: transform;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-delay: 0s;
  display: block;
  width: 100%;
  height: 100%;
  content: '';
  background-color: #87dbfb;
  opacity: 0.2;
  transform: scale(0, 1);
  transform-origin: right top;
  z-index: -1;
}

.btn-hover-effect--effect-1:after {
  position: absolute;
  top: 1px;
  right: -40px;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-delay: 0s;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 40px;
  font-family: "Font Awesome 5 Free";
  font-size: 25px;
  font-weight: 900;
  line-height: inherit;
  opacity: 0;
  left: 0;
  opacity: 1;
}

.btn-hover-effect--effect-1:hover:before {
  transform: scale(1, 1);
  transform-origin: left top;
}

.btn-hover-effect--effect-1:hover:after {
  right: 0;
  opacity: 1;
}

.btn-hover-effect--effect-1:hover .cartSpanEdit {
  color: #17B40B;
  text-decoration: underline;
}

.adminInputPrice {
  color: red;
  width: 60px;
  text-align: right;
}

.adminTableShowCheckoutDB,
.itemListTableContainer {
  font-size: 14px;
}

.adminTableShowCheckoutDB table,
.itemListTableContainer table {
  width: 1050px;
  padding: 3px;
  border-spacing: 0;
  border-collapse: collapse;
}

.adminTableShowCheckoutDB th,
.itemListTableContainer th {
  text-align: left;
}

.adminTableShowCheckoutDB .priceTh,
.itemListTableContainer .priceTh {
  text-align: right;
}

.adminTableShowCheckoutDB .orderedGreen,
.itemListTableContainer .orderedGreen {
  background: rgba(164, 255, 155, 0.5);
}

.adminTableShowCheckoutDB .itemCounterChangeTo,
.itemListTableContainer .itemCounterChangeTo {
  width: 50px;
}

.adminTableShowCheckoutDB .orderedHeadTR,
.adminTableShowCheckoutDB .trRow,
.itemListTableContainer .orderedHeadTR,
.itemListTableContainer .trRow {
  border-top: 1px solid #000;
}

.adminTableShowCheckoutDB .orderedHeadTR:hover,
.adminTableShowCheckoutDB .trRow:hover,
.itemListTableContainer .orderedHeadTR:hover,
.itemListTableContainer .trRow:hover {
  background: #87dbfb !important;
}

.pageCopyCartContainer table {
  width: 1050px;
}

.pageTextContainer .pageTextContainerEntry textarea {
  width: 600px;
}

.fancyBoxLoginList tr:hover {
  background: #eeeeee;
}

.fancyBoxLoginList .activeUserMore {
  cursor: pointer;
}

.fancyBoxLoginList .activeUserMore:hover {
  color: #87dbfb;
}

#fancyBoxItemPosRabattProzent input {
  width: 80px;
  text-align: right;
}

#fancyBoxCartAddDiscountPosition,
#fancyBoxDiscountItems {
  width: 600px;
}

#fancyBoxAddSpecialItemPos {
  width: 1100px;
}

#fancyBoxAddSpecialItemPos select {
  font-size: 14px;
  padding: 5px;
}

.rotate146 {
  transform: rotate(146deg);
}

.textAlignCenter {
  text-align: center;
}

.btnimgUps {
  background-image: url("/img/ups_logo.gif");
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: 5% 50%;
}

.btnIconNewCart:after {
  content: '\f217';
}

.btnIconAddToCart:after {
  content: '\f218';
}

.btnIconOrderCart:after {
  content: '\f07a';
}

.btnIconAddToCartExclamation:after {
  content: '\f071';
  color: #ffde00;
}

.btnIconOrderCartArrow {
  line-height: 24px;
}

.btnIconOrderCartArrow:after {
  content: '\f061';
}

.btnIconAddToOffer:after {
  content: '\f022';
  font-weight: 400;
}

.btnIconOffer:after {
  content: '\f022';
  font-weight: 400;
}

.btnIconItemRequest:after {
  content: '\f059';
  font-weight: 400;
}

.btnIconPrint:after {
  content: '\f02f';
}

.btnIconEdit:after {
  content: '\f044';
  font-weight: 400;
}

.btnIconDelete:after {
  content: '\f2ed';
  font-weight: 400;
  color: red;
}

.btnIconEmail:after {
  content: '\f0e0';
  font-weight: 400;
}

.btnIconEmailAt:after {
  content: '\f1fa';
}

.btnIconNewAdress:after {
  content: '\f1ad';
  font-weight: 400;
}

.btnIconUpload:after {
  content: '\f574';
}

.btnIconUps:after {
  content: '\f7e0';
}

.btnIconSave:after {
  content: '\f0c7';
}

.btnSortLocked:after {
  content: '\f163';
}

.btnFillItemPosCommission:after {
  content: '\f0ca';
}

.btnIconClipboard:after {
  font-weight: 400;
  content: '\f328';
}

.btnIconEye:after {
  content: '\f06e';
}

.btnIconExcel:after {
  font-weight: 400;
  content: '\f1c3';
}

.btnIconPlus:after {
  content: '\f0fe';
}

.btnIconMinus:after {
  content: '\f068';
}

.btnIconPhone:after {
  content: '\f095';
  transform: rotate(146deg);
}

.btnIconCheck:after {
  content: '\f00c';
}

.btnIconCancel:after {
  content: '\f00d';
}

.btnIconDeleteEmail {
  color: red;
}

.btnIconDeleteEmail:after {
  top: -1px !important;
  content: '\f00d';
}

.btnIconCompany:after {
  font-weight: 400;
  content: '\f1ad';
}

.btnIconShippingAdress:after {
  content: '\f3c5';
}

.btnIconShipping:after {
  content: '\f0d1';
}

.btnIconCopy:after {
  font-weight: 400;
  content: '\f0c5';
}

.btnIconUseComment:after {
  font-weight: 400;
  content: '\f0c5';
}

.btnIconPlus:after {
  content: '\f067';
}

.btnIconReload:after {
  content: '\f2f9';
}

.fa-trash-alt:hover {
  color: red !important;
}

.cartAdressListEditIcon {
  cursor: pointer;
}

.cartAdressListEditIcon:hover {
  color: #0088BB;
}

.cartDetailGridTop button {
  font-size: 16px;
  font-weight: bold;
}

label {
  cursor: pointer;
}

html.no-cssgrid {
  /* IE/Edge specific styles go here - hide HTML5 content and show Flash content */
}

html.no-cssgrid .itemTypeBox {
  margin-right: 20px;
}

html.no-cssgrid .itemTypeBox .contentGrid {
  display: -ms-grid;
  -ms-grid-columns: 125px auto;
}

html.no-cssgrid .itemTypeBox .contentGrid div:nth-child(2n+1) {
  -ms-grid-column: 1;
  margin-top: 10px;
}

html.no-cssgrid .itemTypeBox .contentGrid div:nth-child(2n+2) {
  -ms-grid-column: 2;
  margin-top: 10px;
}

html.no-cssgrid .itemTypeBox .contentGrid .rowBtn {
  margin-top: 10px;
  -ms-grid-column: 1 !important;
  -ms-grid-column-span: 2;
}

html.no-cssgrid .cartDetailGridTop {
  display: -ms-grid;
  -ms-grid-columns: 1fr 1fr 1fr;
}

html.no-cssgrid .cartDetailGridTop div:nth-child(3n-2) {
  -ms-grid-column: 1;
  margin-bottom: 10px;
  margin-right: 10px;
}

html.no-cssgrid .cartDetailGridTop div:nth-child(3n) {
  -ms-grid-column: 3;
  margin-bottom: 10px;
  margin-right: 10px;
}

html.no-cssgrid .cartDetailGridTop div:nth-child(3n-1) {
  -ms-grid-column: 2;
  margin-bottom: 10px;
  margin-right: 10px;
}

html.no-cssgrid .rowGrid {
  display: -ms-grid;
  -ms-grid-columns: 40px 70px 70px 220px 200px 1fr 140px 140px 50px;
}

html.no-cssgrid .rowGrid span:nth-child(12n+1) {
  -ms-grid-column: 1;
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  margin-top: -15px;
  display: block;
}

html.no-cssgrid .rowGrid span:nth-child(12n+2) {
  -ms-grid-column: 2;
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  -ms-grid-row-align: center;
  display: block;
}

html.no-cssgrid .rowGrid span:nth-child(12n+3) {
  -ms-grid-column: 3;
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  -ms-grid-row-align: center;
  display: block;
}

html.no-cssgrid .rowGrid span:nth-child(12n+4) {
  -ms-grid-column: 4;
  -ms-grid-row: 1;
  display: block;
}

html.no-cssgrid .rowGrid span:nth-child(12n+5) {
  -ms-grid-column: 5;
  display: block;
}

html.no-cssgrid .rowGrid span:nth-child(12n+6) {
  -ms-grid-column: 6;
  display: block;
}

html.no-cssgrid .rowGrid span:nth-child(12n+7) {
  -ms-grid-column: 7;
  -ms-grid-row-span: 2;
  -ms-grid-row-align: center;
  display: block;
}

html.no-cssgrid .rowGrid span:nth-child(12n+8) {
  -ms-grid-column: 8;
  -ms-grid-row-span: 2;
  -ms-grid-row-align: center;
  display: block;
}

html.no-cssgrid .rowGrid span:nth-child(12n+9) {
  -ms-grid-column: 9;
  -ms-grid-row-span: 2;
  -ms-grid-row-align: center;
  display: block;
}

html.no-cssgrid .rowGrid span:nth-child(12n+10) {
  -ms-grid-column: 4;
  -ms-grid-column-span: 3;
  -ms-grid-row: 2;
  display: block;
}

html.no-cssgrid .rowGrid span:nth-child(12n+11) {
  -ms-grid-column: 4;
  -ms-grid-row: 3;
  display: block;
}

html.no-cssgrid .rowGrid span:nth-child(12n+12) {
  -ms-grid-column: 6;
  -ms-grid-column-span: 3;
  -ms-grid-row: 3;
  display: block;
}

html.no-cssgrid .rowGrid .labelAnimated span {
  display: inline-block;
}

html.no-cssgrid .rowGridHeader {
  display: -ms-grid;
  -ms-grid-columns: 40px 70px 70px 220px 200px auto 140px 140px 50px;
}

html.no-cssgrid #searchResultContainer {
  max-height: 700px;
  overflow-y: scroll;
}

html.no-cssgrid #searchResultGrid {
  display: -ms-grid;
  -ms-grid-columns: auto 30px auto 120px auto;
  height: auto;
  overflow: auto;
  -ms-overflow-style: none;
}

html.no-cssgrid #listItemsGrid {
  display: -ms-grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
}

html.no-cssgrid #listItemsGrid .itemCol:nth-Child(5n+1) {
  -ms-grid-column: 1;
}

html.no-cssgrid #listItemsGrid .itemCol:nth-Child(5n+2) {
  -ms-grid-column: 2;
}

html.no-cssgrid #listItemsGrid .itemCol:nth-Child(5n+3) {
  -ms-grid-column: 3;
}

html.no-cssgrid #listItemsGrid .itemCol:nth-Child(5n+4) {
  -ms-grid-column: 4;
}

html.no-cssgrid #listItemsGrid .itemCol:nth-Child(5n+5) {
  -ms-grid-column: 5;
}

html.no-cssgrid #listItemsGrid .itemCol:nth-Child(n+1):nth-Child(-n+5) {
  -ms-grid-row: 1;
}

html.no-cssgrid #listItemsGrid .itemCol:nth-Child(n+6):nth-Child(-n+10) {
  -ms-grid-row: 2;
}

html.no-cssgrid #listItemsGrid .itemCol:nth-Child(n+11):nth-Child(-n+15) {
  -ms-grid-row: 3;
}

html.no-cssgrid #listItemsGrid .itemCol:nth-Child(n+16):nth-Child(-n+20) {
  -ms-grid-row: 4;
}

html.no-cssgrid #listItemsGrid .itemCol:nth-Child(n+21):nth-Child(-n+25) {
  -ms-grid-row: 5;
}

html.no-cssgrid #listItemsGrid .itemCol:nth-Child(n+26):nth-Child(-n+30) {
  -ms-grid-row: 6;
}

html.no-cssgrid .ms-row1 {
  -ms-grid-row: 1;
}

html.no-cssgrid .ms-row2 {
  -ms-grid-row: 2;
}

html.no-cssgrid .ms-row3 {
  -ms-grid-row: 3;
}

html.no-cssgrid .ms-row4 {
  -ms-grid-row: 4;
}

html.no-cssgrid .ms-row5 {
  -ms-grid-row: 5;
}

html.no-cssgrid .ms-row6 {
  -ms-grid-row: 6;
}

html.no-cssgrid .ms-row7 {
  -ms-grid-row: 7;
}

.father-inner {
  position: absolute;
  top: 40px;
  left: 500px;
  right: 5px;
  bottom: 5px;
}

.father-inner .draggable {
  width: 300px;
  height: 300px;
  background: #eee;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.father-inner .draggable.is-pointer-down {
  background: #09F;
}

.father-inner p {
  background: #F00;
}

.father-inner .child {
  min-width: 100px;
  min-height: 100px;
  height: 100px;
  width: 100px;
}

.father-inner .draggable.is-dragging {
  opacity: 0.7;
}

.father-inner .ui-resizable-helper {
  border: 1px dotted #20a0ff;
}

.father-inner .ui-resizable-se {
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: se-resize;
  background: #aaa;
}

.grecaptcha-badge {
  visibility: hidden;
}

.googleTos {
  font-size: 13px;
}

