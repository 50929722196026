$color-white: #FFFFFF;
$color-blue: #0088BB;
$color-green: #17B40B;
$color-yellow: #f8f1c2;
$color-orange: #87dbfb;
$color-neworange: #ff9601;
$color-red: red;
$color-blueNew: blue;
$color-newGray: #dedede;
// light blue 87dbfb
// blue new 50aed2
//old orange ffb347
$color-darkBlue: #26234e; //263664
//$color-oldBlue: #0088BB;
$color-gray: #eeeeee;
$color-btnBoxShadow: #d1d4d3;
$color-font: #26234e;
$font-base: 'Open Sans', Arial, sans-serif;
$font-sizeNorm: 14px;
$font-size-price: 20px;


body {
    background-color: $color-white;
    font-family: $font-base;
    font-size: $font-sizeNorm;
    color: $color-font;
    margin: 0px;
    
    input {
    	font-size: $font-sizeNorm;
    	padding: 5px;
    }
    
}

* {
	//box-sizing: border-box;
}

#header {
	margin: 0;
	#headerTopContent {
		//margin: auto;
		//max-width: 1200px;
		//zoom: 0.6;
    	//-moz-transform: scale(0.6);
    	
		//transform: scale(0.6);
		background-color: #fff;
		
		
		#logo {
			margin: 15px 20px;	
			float: left;
			img {
				height: 71px;
			}
		}

	
		.logoAnimate{
			width: 309px;
			height: 71px;
			//transition: all 2s;
			/*
			
			&:hover{
				width: 100px;
				height: 50px;
			}
		*/
		}
	
		#langSelect {
			#dataContactHead{
				line-height: 44px;	
				
				i {
					font-size: 20px;
					vertical-align: -3px;
				}
				.fa-phone {
					transform: rotate(146deg);
				}
				a{
					color: $color-darkBlue;
					
					&:hover{
						color: $color-orange;
					}
				}
				
			}
			
			float: right;
			img {
				width:40px;
				margin: 5px;							
			}
		}
			
	}

	#banner {
		//background-image: url('/img/banner/istock1.jpg') repeat 0 0 ;
		//height: 500px;
		overflow:hidden;
			    z-index: -1;
		position:relative;
		img {
		  max-width: 100%;
		  //height: auto;
		  width: 100%;
		  margin:auto;
		  display:block;
		  overflow: hidden;
		  border-bottom: 2px solid $color-darkBlue;

		  //max-height: 500px !important;
		}
	}
}
.goog-te-combo {
	width: 100%;
}

.wrapperNav {
	background-color: #fff;
	width: 100%;
	margin: 0 0 3px 0;
	height: 40px;
	
	#mainNav {
		list-style-type: none;
		padding-inline-start: 0px;
	    margin-block-start: 0em;
	    margin-block-end: 0em;
	    font-size: 16px;
	    
		li {
		  float: left;
		  .active {
		  	color: $color-darkBlue;
		  	background-color: $color-orange;
		  	&:hover{
		  		color: $color-darkBlue;
		  		
		  	}
		  }
		  
		}
		
		li a {
		  border-radius: 18px;
		  display: block;
		  color: $color-darkBlue;
		  text-align: center;
		  padding: 9px 22px;
		  text-decoration: none;
		  text-transform: uppercase;
		  
		  //font-weight: bold;
		}
		
		li a:hover {
		  background-color: $color-orange;
		  color: $color-darkBlue;
		  
		}
		.pageReload {
			float: right !important;
		}
	
		li .adminRed {
			padding: 9px 4px;
		}
  
	}
}
.wrapperUserNav{
	background-color: $color-orange;
	width: 100%;
	height: 40px;
	margin: 0 0 3px 0;

		#mainSearchLeft {
			width: 300px;
			margin-top: -1px;
			
			#userHistoryContainer{
				width: 100%;
				position: relative;
				#userHistoryDiv {
					background-color: #fff;
					border: 1px solid $color-darkBlue;
					margin: 6px 0 5px 20px;
					padding: 5px 0 5px 10px;
					cursor: pointer;
					
					
					i {
					    float: right;
					    background: $color-gray;
					    height: 29px;
					    width: 30px;
					    text-align: center;
					    line-height: 30px;
					    margin-top: -5px;
					    font-size: 20px;
					    border-left: 1px solid $color-darkBlue;
					}
					&:hover {
						i{
							background-color: $color-darkBlue;
							color: #fff;
						}
					}
					
				}
			
			}
			
			#userHistoryContentBoxContainer{
			    	
			    	position: relative;
			    	display: none;
			    	
				    .userHistoryAdminMenuSpinner {
				    	font-size: 20px;
				    	display: none;
				    }
				   
		    	#userHistoryContentBox {
					position: absolute;
					min-width: 1000px;
					max-width: 1000px;
				    margin-left: 20px;
				    margin-top: -5px;
				    z-index: 100;
				    background-color: #fff;
				    border: 1px solid #26234e;
				    border-top: none;
				    padding: 10px;
				    
				    #userHistoryAdmin {
				    	div {
				    		margin-left: 10px;
			    		}
			    		.notDisplayAdmin{
			    			background-color: red;
			    		}
				    }
				    
				    .userHistoryResultContainerAdmin {
				    	max-height: 1500px !important;
				    }
				    
				    #userHistoryResultContainer{
				    	border-top: 1px solid $color-darkBlue;
				    	max-height: 900px;
				    	overflow-y: scroll;
				    	
				    	.spinContainer {
				    		width: 100%;
				    		margin-top: 10px;
				    		font-size: 40px;
				    		text-align: center;
				    	}
				    	
				    	table {
				    		width: 100%;
				    		font-size: 12px;
				    		
				    		a {
				    			color: green;
				    			
				    		}
					    	.adminRed {
					    		color: red !important;
					    	}
					    }
				    
				    	.itemNotFound {
				    		color: #0072ff;
				    	}
				    
				    }
			    }
			}
			
		}
	
		#mainSearchRight {
			padding: 5px;
			margin-left: 300px;
			margin-right: 30px;
			
			.marginTop-1{
				margin-top: -1px;
			}
			
			.searchInputClear {
			    display: inline-block;
			    height: 30px;
			    line-height: 30px;
			    //width: 30px;
			    padding-left: 5px;
			    padding-right: 5px; 
			    text-align: center;
			    margin-right: -5px;
			    border: 1px solid $color-darkBlue;
			    background: #eeeeee;
			    //font-weight: bold;
			    color: $color-darkBlue;
			    cursor: pointer;
			    
			    &:hover {
			    	background: $color-darkBlue;
			    	color: #fff;
			    }
			   
			   .searchLoadingIcon{
			   		position: absolute;
				    margin-left: 10px;
				    margin-top: 7px;
				    display: none;
				    color: $color-green
			   }
			}
			
			.searchInputGo {
				display: inline-block;
			    height: 30px;
			    line-height: 30px;
			    width: 30px;
			    text-align: center;
			    margin-left: -4px;
			    border: 1px solid $color-darkBlue;
			    font-weight: bold;
			    color: $color-darkBlue;
			    cursor: pointer;
			    background: url('./img/ico/ico_btnSearch16.png') no-repeat center center;
			    background-color: #eeeeee;
			    
			    &:hover {
		    	 	background: url('./img/ico/ico_btnSearch16_hover.png') no-repeat center center;
		    	 	background-color: $color-darkBlue;
			    	color: #fff;
			    }
			}
			
			#mainSearchInput {
				background-color: #fff;;
				//border: none;
				border: 1px solid $color-darkBlue;
				font-size: 16px;
				width: 80%;
				//width: -webkit-fill-available;
				max-width: 1200px;
				padding: 0 10px 0 20px;
				height: 30px;
				
			}
			::placeholder { /* Most modern browsers support this now. */
			   color:    $color-darkBlue;
			   //padding: 0 5px;
			   opacity: 0.6;
			}
		
			.searchCartIcon {
				margin-left: 20px;
				width: 32px;
				height: 32px;
				
				position: absolute;
				//overflow: hidden;
				cursor: pointer;
				background: url('./img/ico/ico_cartTop.png') no-repeat top center;
				
				
				#countElement {
					position: relative;
					margin-left: 20px;
					margin-top: -20px;
					width: 25px;
					height: 25px;
					displaY: block;
					background: $color-gray;
					//padding: 2px;
					-webkit-border-radius: 9px;
					-moz-border-radius: 9px;
					border-radius: 9px;
					text-align: center;
					line-height: 25px;
				}
				
				/*&:after{
					position: relative;
					margin-left: 20px;
					margin-top: -20px;
					width: 20px;
					height: 20px;
					displaY: block;
					background: $color-gray;
					//padding: 2px;
					-webkit-border-radius: 9px;
					-moz-border-radius: 9px;
					border-radius: 9px;
					text-align: center;
					
					content: '50';
				} */
				
				
				&:hover {
					background-position: bottom;
					
					#countElement {
						background: $color-darkBlue;
						color: #fff;
					}
					
					/*&:after {
						
					}*/
				}
			}
		
			#searchResultContainer {
				//margin-left: 69px;
				z-index: 100;
				background-color: #fff;
				border: 1px solid $color-darkBlue;
				border-top: none;
				padding: 10px;
				max-width: 1200px;
				margin-left: 31px;
				position: relative;
				
				.searchResultHeadline {
					margin: -10px -10px 0 -10px;
					padding: 10px;
					background-color: $color-gray;
					border-bottom: 1px solid $color-darkBlue;
					font-size: 15px;
				}
				
				.searchResultSearchString {
					font-weight: bold;
				}
				
				#searchResultGrid {
					display: grid;
					grid-template-columns: auto 30px auto 120px auto;
  					grid-template-rows: [header-start] 50px [header-end row-start] 110px [row-end]; 					
				  	grid-auto-rows: auto;
  					grid-auto-columns: auto;
  					overflow-y: scroll;
  					max-height: 700px;
  					background-color: #fff;
  					
  					.rowWrapper {
  						display: contents;
  						cursor: pointer;
  						
  						
  					}
					.rowWrapper:hover div{
					  background-color: $color-orange;
					}
  					
  					.item, .header {
  						//width: calc(100% / 5); /* calc() method */
  						//width: 33.33%; /* percentage method */
  						padding: 5px;
  						
  						max-height: 110px;	
  						box-sizing: border-box;
  					}
  				
  					.item {
  						height: 110px;
  						cursor: pointer;
  						border-top: 2px solid #fff;
		  				border-bottom: 2px solid #fff;
  					}
  					
  					.headerName, .resultName {
  						grid-column: 1;
  						-ms-grid-column:1;
  						a {
  							text-decoration: none;
  							color: $color-darkBlue;
  						}
  					}
  				
  					.resultNoPrice{
  						&:hover {
  							text-decoration: underline;
  						}
  					}
  				
  					.itemType, .resultItemType {
  						-ms-grid-column: 2;
  						grid-column: 2;
  						div {
  							height: 19px;
  						}
  						
  					}
  					.headerPrice, .resultPrice {
  						grid-column: 3;
  						-ms-grid-column:3;
  						text-align: right;
  						div {
  							height: 19px;
  						}
  					}
	  				.headerImg, .resultImage {
  						grid-column: 4;
  						-ms-grid-column:4;
  					}
	  				.headerDesc, .resultDesc {
  						grid-column: 5;
  						-ms-grid-column:5;
  						overflow: hidden;
  					}
	  				
  				
	  				.resultImage{
	  					justify-self:center;
	  					width: 100%;
	  					.itemImg {
	  						width: 100px;	
	  					}
	  				}
	  				.resultPrice, .headerPrice {
	  					div {
	  						width: 100%;
  							 
  							text-align: right;
  						}
	  				}
		  			.hovered{
		  				//background-color: $color-orange;
		  				border-top: 2px solid $color-orange;
		  				border-bottom: 2px solid $color-orange;
		  				cursor: pointer;
		  				//color: blue;
		  				.itemLink{
		  					//color: blue;
		  				}
		  			}
		  			.hoverThis{
		  				color: blue;
		  				text-decoration: underline;
	  					.itemLink{
		  					color: blue;
		  				}
		  			}
				}
			}
		}
}

#fancyBoxNewDesign {
	width: 600px;
	display: none;
	.newDesignTextArea {
		width: 100%;
		height: 200px;
	}
}


#home {
	max-width: 1000px;
	position: relative;
	.arrowUp {
		position: absolute;
		left: 380px;
		top: 0;
	}

	

	//#homeContainer::-webkit-scrollbar { width: 0 !important }
	#homeContainer {
		//max-height: 1100px;
		//overflow-y: scroll;
		//-webkit-scrollbar: width: 0 !important;
		//overflow: -moz-scrollbars-none;
		//-ms-overflow-style: none; 
		//max-width: 770px;
		position: relative;
		
		h1 {
			font-size: 20px;
			.homeHide {
				float: right;
				color: $color-darkBlue;
				cursor: pointer;
				
				&:hover {
					color: $color-orange;
				}
				
			}
			
		}
		.homeNewShop {
			background: url('./img/home_newbadge.png') no-repeat 0 0px;
			text-indent: 60px;
			
			h1 {
				color: $color-orange;
				margin-bottom: 0px;
				margin-top: 5px;
			}

			
		}
		
		.homeSlideContainer {
			max-width: 1000px;
			//overflow: hidden;
			
			//background: #ccc !important;
			
			.main{
				max-width: 580px;
				//width:100%;
				//float: right;
				hyphens: auto; 
	        	text-align: justify;
	        	margin-left: 20px;
			}
			
			.rowImg {
				width: 100%;
				img {
					width: 100%;
				}
			}
			
			.action{
			  //display:block;
			 margin:10px auto;
			 // width:100%;
			 //text-align:center;
			  //float: left;
			 max-width: 330px;
			
			}
			
			.action a {
				//display:inline-block;
				padding:5px 20px; 
				margin-bottom: 5px;
				text-decoration:none;
				border: none;
				font-size: 16px;
			}
			
			.action a:hover{
			  //background:#000;
			}
			
			.slick-next {			
				right: 0;
				&:before {
					content: "\00276D" !important;
					font-size: 150px;
				}
				
			}
			.slick-prev{
				left: 0;
				&:before {
					content: "\00276C" !important;
					font-size: 150px;
				}	
				
			}
			.slick-arrow {
				//background-color: #e4e4e4;
				//opacity: 0.7;
				top: 200px;
				width: 50px;
				height: 516px;
				color: black;
				z-index: 10;
			}
			.activeSlide {
				background-color: $color-orange !important;	
			}
			
		}
		
		
		.flex-container {
			max-width: 1000px;
			padding: 0;
			margin: 0;
			list-style: none;
			
			display: -webkit-box;
			display: -moz-box;
			display: -ms-flexbox;
			display: -webkit-flex;
			display: flex;
			
			-webkit-flex-flow: row wrap;
			justify-content: space-around;
		}
		
		.flex-item {
			background: #fff;
			//padding: 5px;
			width: 300px;
			height: 220px;
			//margin: 10px;
			
			//line-height: 150px;
			//color: $color-darkBlue;
			//font-weight: bold;
			//font-size: 3em;
			text-align: center;
		  	
		  i {
				width: 100%;
				font-size: 40px;
				text-align: center;
				//color: $color-orange;
			}
			h2 {
				//width: 100%;
				text-align: left;
				//color: $color-orange;
				//margin: 5px;
			}
			.text {
				text-align: center;
				font-size: 16px;
				hyphens: auto; 
	        	text-align: justify
				//font-weight: normal;
			}
		  
		}
		
	
		.homeRow {
			/*img {
				width: 400px;
				height: 200px;
				overflow: hidden;
			}*/
		
			.rowtext{
				
				.textContainer {
					width: 100%;
					.text {
						//text-align: center;
						font-size: 16px;
						width: 450px;
						hyphens: auto; 
			        	text-align: justify
						//font-weight: normal;
					}
				}		
			}
		}
	}
}

#faq {
	#faqContentContainer {
		max-width: 800px;
		.faqRow {
			border: 1px dotted $color-darkBlue;
			border-width: 1px 0 0 0;
			
			.active .fas{

				transform: rotate(0deg) !important;
				
				&:hover {
					-webkit-transform: rotate(180deg);
				    -moz-transform: rotate(180deg);
				    -o-transform: rotate(180deg);
				    -ms-transform: rotate(180deg);
					transform: rotate(-90deg) !important;
				}
			}
			
			.faqHeadline {
				//background-color: $color-gray;
				font-weight: normal;
				margin: 0;
				padding: 10px;
				cursor: pointer;
				
				.fas {
				    -webkit-transition: 0.2s ease-in-out;
				    -moz-transition: 0.2s ease-in-out;
				    -o-transition: 0.2s ease-in-out;
					transition: 0.2s ease-in-out;
					transform: rotate(-90deg);
				}
				
				.chevRotateDown {
					transform: rotate(90deg);
				}
			
				
				&:hover {
					background-color: $color-gray;
					
					.fas {
						-webkit-transform: rotate(180deg);
					    -moz-transform: rotate(180deg);
					    -o-transform: rotate(180deg);
					    -ms-transform: rotate(180deg);
						transform: rotate(0deg);
					}
				
					
				}
			}
			.faqContent {
				display:none;
				margin-top: 10px;
				padding: 10px;
				
				h4{
					margin-top: 0px;
				}
				
				.colLeftLogin{
					min-width: 90px;
				}
				.inputLogin{
					width: 300px;
					min-width: 200px;
				}
				select {
					padding: 5px;
					width: 313px;
					min-width: 200px;
				    font-size: 14px;
			        border: 1px solid #bbb;
				}
				input {
				    border: 1px solid #bbb;
					&:required{
						background: $color-yellow;
					}	
				}
			}
		}
	
		.borderBot {
			border-width: 1px 0 1px 0;
		}
	}
}

.cartOrderSubmitContentBox {
	background: $color-gray;
	//margin-top: -20px;
	max-width: 1000px;
	padding: 20px;
	h1 {		
		text-align: center;
		margin: 0;
		
		.contentHide {
			font-size: 16px;
			cursor: pointer;
			font-weight: auto;
			
			&:hover {
				color: $color-orange;
			}
		}
	}
	.cartOrderSubmitText{
		text-align: center;
		
		.buttonEff {
			width: 200px;
			float: left;
		}
	}
}

.cartMainContainerNoCartBox{
	//width: fit-content;
	width: 100%;	
	margin-bottom: 20px;
	position: relative;
	
	
	.cartMainContainerNoCartBoxContent {
		margin: auto;
		padding: 10px;
		text-align: center;
		background: $color-gray;
		border-radius: 20px;
		//font-weight: bold;
		
		.cartMainContainerNoCartBoxHide {
			cursor: pointer;
			position: absolute;
			right: 20px;
			top: 10px;
			font-weight: normal;
			color: $color-darkBlue;
			transform: rotate(45deg);
			font-size:20px;
		
			&:hover {
				color: $color-orange;
			} 
		}
	}
}


#itemDetailDisplay {
	
	h1 {
		margin: 0;

		.headlineAvailability {
			font-size: 14px;
			line-height: 26px;
			margin-left: 10px;
			color: $color-green;
			i{
				font-size: 16px;
			    
			}
		
		}
		#headlineAvailabilityOnRequest{
			font-size: 14px;
			line-height: 26px;
			color: $color-neworange;
			i{
				font-size: 16px;
			    
			}
			div {
				padding-left:10px;
				&:hover{
				text-decoration: underline;		
				}
				
			}
		}
		
		.itemDetailDayPrice{
			font-size:14px;
		    line-height: 26px;
    		margin-left: 10px;
    		
			#itemDetailRequestPrice{
				color: $color-blueNew;
				text-decoration: none;
				
				&:hover{
					text-decoration: underline;
				}
				&:visited,  &, .resultNoPrice:focus, &:active{
					color: blue !important;
				}
			}
    		
		}
	
	}
	.itemDetailBtnContainerTopRight {
		margin-top: -10px;
		width: 465px;

	}

	
	#itemDetailGridTop{
		display: -ms-grid;
		-ms-grid-columns: 300px 2.5fr 320px;
		display: grid;
		grid-template-columns: 300px minmax(300px, auto) 320px;

		column-gap: 20px;
		margin-top: 10px;
		
			div:nth-child(3n+1) {
				-ms-grid-column: 1;
			}
			div:nth-child(3n+2) {
				-ms-grid-column: 2;
			}
			div:nth-child(3n+ 3) {
				-ms-grid-column: 3;
			}

		.itemTableGrid {
			margin-top: 20px;
			display: -ms-grid;
			-ms-grid-columns: 110px auto;
			display: grid;
			grid-template-columns: 110px auto;
			//grid-row-gap: 5px;
			
			div:nth-child(2n + 1) {
				-ms-grid-column: 1;
			}
			div:nth-child(2n + 2) {
				-ms-grid-column: 2;
			}
			
		}
	
		.itemRightColGrid {
			padding: 20px;
			background: $color-gray;
			display: -ms-grid;
			-ms-grid-columns: 140px auto;
			display: grid;
			grid-template-columns: 140px auto;
			position: relative;
			
			div:nth-child(2n + 1) {
				-ms-grid-column: 1;
			}
			div:nth-child(2n + 2) {
				-ms-grid-column: 2;
			}
			
			.copyItemRightColIcon {
			    position: absolute;
			    right: 0;
			    color: red;
			    margin-right: 5px;
			    margin-top: 5px;
			    cursor: pointer;
			    
			    &:hover {
			    	color: $color-orange;
			    }			
			}
			
		}
	}
	

	#itemDetailGridItemTypes{
		display: -ms-grid;
		-ms-grid-columns: 275px 275px 275px 275px;
		display: grid;
		grid-template-columns: 275px 275px 275px 275px;
		column-gap: 20px;
		margin-top: 20px;
		
		.inactive {
			opacity: 0.7;
			//z-index: -1;	
		}
	
		.buttonEff {
			width: 100%;
			text-align: left;
		}
		
		.itemTypeBox {
			border: 1px solid $color-newGray;
			//box-sizing: border-box;
			//border: 1px solid $color-darkBlue;
			//background: $color-gray;
			//width: 100%;
			//box-shadow: 0 0 12px rgb(0,0,0/ 0.9);
			box-shadow: 0 0 10px rgba(0,0,0,0.2);
			border-radius: 10px;
			.headline {
				//background-color: $color-orange;
				background-color: #fff;
				//background-color: $color-darkBlue;
				//color: #fff;
				h3 {
					//padding: 10px;
					margin: 5px 10px;
				}
			}
		
			.contentGrid{
				display: grid;
				grid-template-columns: 125px auto;
				padding: 5px 10px;
				
				
				.itemPrice {
					font-weight: bold;
					//color: $color-orange;
					font-size: $font-size-price;
					.itemDetailRequestPrice{
						font-size: 14px;
						color: $color-blueNew;
						text-decoration: none;
						&:hover{
							text-decoration: underline;
						}
					}
				}
				.rowBtn {
					grid-column: 1 / span 2;	
					//justify-self: center;
				}
				.itemDetailQtyInput {
					width: 50px;
					text-align: center;
				}
				.itemDetailSelectQty {
					padding: 5px; 
					width: 65px;
				}
				.disabled{
					background: #cccccc;
				}
				.itemQtyLimit {
					border: 1px solid red;
					//color: red;
				}
			}
		
		/*	&:hover {
				background-color: $color-gray;
			}
		*/
		
			.itemBoxRep {
				input {
					font-size: 14px !important;
					margin: 0px !important;
					padding: 0px !important;
				}
				textarea {
					font-size: 14px;
					margin: 0px;
					padding: 0px;
					height: 20px;
				}
			}
		
		}
		
	}

	#itemDetailSpareParts{
		margin-top: 20px;
		
		
		a {
			color: $color-darkBlue;
			font-size: 13px;
		}
			
		.spareBox{
			width: 250px;
			margin-right: 10px;
			margin-top: 10px;
			float: left;
			cursor: pointer;
			border: 1px solid $color-newGray;
			box-shadow: 0 0 6px rgba(0,0,0,0.2);
			border-radius: 10px;
			
			.spareImg {
				padding: 5px;
				width: 70px;
				height:50px;
			}
			
			.spareHeadline{
				//background: $color-orange;
				padding: 5px;
				width: 160px;
				
			}
			.spareAvailability{
				padding: 5px;	
			}
			.spareDesc {
				padding: 5px;
				height: 70px;
				overflow-y: hidden;
			}
			&:hover {
				//border: 1px solid $color-darkBlue;
				border: 1px solid $color-orange;
				.spareHeadline{
					//background: $color-darkBlue;
					//color: #fff;
					text-decoration: underline;
				}
			}
		}

	}
}

#cartDetailDisplay, #offerMainContainer {
	
	h1 {
		//margin-bottom: 40px;
		margin: 0;
		.cartImg {
		    margin-bottom: -6px;
    		margin-right: 10px;
    		width: 55px;
		}
	
		.lineHeight50 {
			line-height: 50px;
		}
		.lineHeight38 {
			line-height: 38px;
		}
	
		.cartDetailHeadlineChangeName{
			line-height: 0;
			font-size: $font-sizeNorm;
			font-weight: normal;
			&:hover{
				color: $color-green;
				cursor: pointer;
			}
		}
	
		.cartDetailFurtherCartsContainer {
			float: right;
			font-size: 14px;
			font-weight: normal;
			//background: $color-gray;
			//padding: 0 5px 5px 5px;
			margin-top: -15px;
			position: relative;
			.cartLoopHeadline {
			    padding-top: 22px;
			    padding-right: 10px;
			}
			.cartLoopContainer {
				width: 100px;
				cursor: pointer;
				text-align: center;
				padding: 5px;
				text-decoration: none;
				color: $color-darkBlue;
				//position: relative;
				
				#countElement {
					position: relative;
				    margin-left: 59px;
				    margin-top: -40px;
				    margin-bottom: 15px;
					width: 25px;
					height: 25px;
					display: block;
					background: $color-gray;
					//padding: 2px;
					-webkit-border-radius: 9px;
					-moz-border-radius: 9px;
					border-radius: 9px;
					text-align: center;
					line-height: 25px;
				}
				
				/*&:after{
					position: relative;
					margin-left: 20px;
					margin-top: -20px;
					width: 20px;
					height: 20px;
					displaY: block;
					background: $color-gray;
					//padding: 2px;
					-webkit-border-radius: 9px;
					-moz-border-radius: 9px;
					border-radius: 9px;
					text-align: center;
					
					content: '50';
				} */
				
		
				
				
				&:hover {
					background-color: $color-orange;
										#countElement {
						background: $color-darkBlue;
						color: #fff;
					}
				}
			}
			
			.cartOverViewBox {
				border: 1px solid $color-darkBlue;
				border-radius: 5px;
				background-color: #fff;
				position: absolute;
				top: 80px;
				right: 0;
				z-index: 2;
				width: 350px;
				//padding: 5px;
				h4{
					border-radius: 5px;
					margin: 0;
					padding: 10px;
					border-bottom: 1px solid $color-darkBlue;
					background-color: #fff;
					//background-color: $color-orange;
					//color: #fff;
				}
				.cartOverViewBoxItemList {
					border-radius: 0 0 5px 5px;
					background-color: $color-gray;
					padding: 10px;
					
					.width25px {
						width: 25px;	
					}
					.padding5{
						padding: 3px;
					}
					a {
						color: $color-darkBlue;
						text-decoration: underline;
						&:hover {
							color: $color-orange;
						}
					}
				}

			}
			
			
		}
		.itemDetailBtnContainerTopRight {
			//margin-top: 20px;
			float: right;
		}
	
	}

	#adminCartCommentDelete {
		cursor: pointer;
	}
	
	#cartMenuContainer{
		position: relative;
		width: 450px;
		.cartMenuMainBtn {
			cursor: pointer;
			border: 1px solid $color-darkBlue;
			padding: 5px 10px;
			width: 100%;
			.fa-cog {
				font-size: 20px;
				margin-top: -5px;
				margin-bottom: -5px;
				padding: 0;
				line-height: 27px;

			}
			.fa-chevron-down{
			    background: $color-gray;
			    height: 29px;
			    width: 30px;
			    text-align: center;
			    line-height: 30px;
			    margin-top: -5px;
			    margin-right: -10px;
			    font-size: 20px;
			    border-left: 1px solid $color-darkBlue;				
			}
		
			&:hover {
				.fa-chevron-down {
					color: #fff;
					background-color: $color-darkBlue;
				}
			}
		
		}
		.cartMenuDropDownContainer{
			display: none;
			position: absolute;
			z-index: 50;
			padding: 5px 10px;
			background: #fff;
			border: 1px solid $color-darkBlue;
			border-top: none;
			width: 100%;
			div {
				margin-top: 5px;
				button {
					width: 100%;
				}
				.btnIconOrderCartArrow {
					line-height: inherit;
				}
			}
			
		}
	}

	#cartMenuBtnShort {
		margin-left: 30px;
		.buttonEff{
			width: 42px;
			height: 31px;
			margin-left: 10px;

		}
		.btnIconPrint, .btnIconEdit, .btnIconEmail, .btnIconDelete, .btnIconClipboard, .btnIconOrderCartArrow, .btnIconUpload{
			&:after{
				font-size: 20px !important;
			}
		}
	}
	
/*	.cartDetailGridTop {
		display: grid;
		grid-template-columns: 150px 90px auto;
		column-gap: 0;
		row-gap: 20px;
		
		.btn-hover-effect {
			padding-right: 0 !important;
		}
				
		.cartSpanEdit {
			font-size: 14px;
		    //align-self: right;
		    //float: right;
		    margin-right: 20px;
		    font-weight: normal;
		}
		
		.colHeader {
			padding: 0 20px;
			height: 50px;
			background: $color-gray;
			box-shadow: 0 2px 0 0 #d1d4d3;
			
			h3 {
				margin-top: 15px;
				margin-bottom: 0;
				font-size: 16px;
					
			}

		}
		
		.colContent {
			padding: 0 20px;
			
			.cartSpanSelectedShipment{
				img {
					vertical-align: middle;
				}
			}
			
			.cartChangedShippingCountryWarning {
				//display: none;
				color: red;
			}
		}
	
		.btnDeleteFileDelNote, .btnDeleteFileDelProjectFile {
			cursor: pointer;
			&:hover {
				//olor: $color-orange;
			}
		}
		
		
	} */

	.cartDetailGridTop {
		display: -ms-grid;
		-ms-grid-columns: 1fr 1fr 1fr;
		display: grid;
		//grid-template-columns: max(300px) max(300px) max(350px);
		grid-template-columns: 1fr 1fr 1fr;
		column-gap: 20px;
		row-gap: 20px;
		margin-top: 20px;
		
		.gridColItem {
			background: $color-gray;
			//border-radius: 50px;
		}
		
		.buttonEff {
			background-color: #fff;
		}		
		
		
		.btn-hover-effect {
			padding-left: 20px !important;
			padding-right: 0 !important;
		}
	
		.btn-hover-effect--effect-1 {  
			&:before {
				//background-color: rgba(100,100,100, .2);
				background-color: $color-orange;
				opacity: 0.2;
			}
		}
	
		.btn-hover-effect--effect-1:after {
			left: auto;
			right: 0 !important;
		}
				
		.cartSpanEdit {
			font-size: 14px;
		    margin-right: 20px;
		    font-weight: normal;
		    
		    &:hover {
		    	text-decoration: underline;
		    }
		}
		
		.colHeader {
			padding: 0 20px;
			height: 50px;
			background: $color-gray;
			box-shadow: 0 2px 0 0 #d1d4d3;
			
			h3 {
				margin-top: 15px;
				margin-bottom: 0;
				font-size: 16px;
					
			}

		}
		
		.colContent {
			padding: 10px 20px;
			background-color: $color-gray;
			//border-radius: 0 0 50px 50px;
			//height: 100%;
			//min-height: 120px;
			//display: table-cell;
			//width: 100%;
			
			.cartSpanSelectedShipment{
				img {
					vertical-align: middle;
				}
				
				.shipmentSelectRow{
					opacity: 0.6;
					&:hover{
						opacity: 1;
					}
				}
			
				.selectedShipmentRow{
					opacity: 1 !important;
				}
				.shimpentSelectContainer {
					max-width:500px;
				}
			
			
			}
		.test1234{
			background: red;
		}
			
			.cartChangedShippingCountryWarning {
				//display: none;
				color: red;
			}
			
			
			.fa-check-circle {
				display: none;
				float: left;
				margin: 0 20px 0 0;
				//margin-left: -30px;
				font-size: 24px;
			}
		}
	
		.changed {
			color: $color-green;
			.fa-check-circle {
				display: block;
			}
		}
	
		.btnDeleteFileDelNote, .btnDeleteFileDelProjectFile {
			cursor: pointer;
			&:hover {
				//olor: $color-orange;
			}
		}
	
		.adminERPData{
			#adminCartDetailErpInfoTableSave, #adminCartDetailErpInfoTableShowAllItemPos{
				margin-left: 20px;
				padding: 3px;
				font-weight: normal;
				cursor: pointer;
			}
			#adminERPNotizenVorgang {
				width:90%;
				height: 21px;
				font-size: 16px;	
			} 
		}
	
	
		#adminCartDetailErpInfoTableSave{
			padding-right: 20px !important;
		}
		#adminCartDetailErpInfoTableShowAllItemPos{
			padding-right: 20px !important;
		}

	}
	
	.cartDetailAdress {
		h3 {
			background-color: $color-orange;
			padding: 10px;	
			.button {
				margin: -10px 0 -16px 40px;
			}
		}
	
		.cartDetailAdressContent {
			padding: 10px;	
		}
	}

	.cartDetailItemListGrid {
		//box-sizing: border-box;
		display: grid;
		grid-template-columns: 80px 50px 250px auto auto 75px 130px 160px auto;
		grid-auto-rows: 60px;
		//padding: 10px;
		//align-items: center;
		font-size: 18px;
		//justify-content: space-around;
		
		.rowWrapper {
			display: contents;
		}
		.rowWrapper:hover div{
		 // background-color: $color-orange;
		}
		.bgGray {
			background-color: $color-gray;			
		}
		.col {
			padding: 10px;
		}
		.colImg {
			background-color: #fff !important;
		}
		.cartDetailItemImg {
			width: 50px;
		}
		.numbers {
			width: 100%;
			text-align: right;	
		}
		input {
			padding: 8px 0;
			width: 90%;
			font-size: 16px;
		}
		

		
		

	}
}

#adminCartDetailErpInfoTable, .adminItemListPosErpTable{
	width: 100%;
	text-align: left;
	border-spacing: 5px;
	border-collapse: seperate;
	
	tr{
	
		th, td{
			//padding: 5px;
			
			input{
				width: 100%;
			}
		}
	
		.colPrio {
			width: 40px; 
		}
		.colOrt, .colBesorgen, .colTesten, .colBearbeiter, .colKistenID {
			width: 40px;
		}
		.cartInputVerpackungsart{
			width: 120px;
		}
	
		.H20{
			background-color: #ffa500;  
		}
		.H-0{
			background-color: #add8e6;
		}
		.R{
			background-color: #80ff80;
		}
		.B{
			background-color: #ff80ff;
		}
		.HR{
			background-color: #ffbfff;
		}
		.HF{
			background-color: #bdf7fb;
		}	
		.BT{
			background-color: #9fb3fd;
		}		
		.DS{
			background-color: #80ff00;
		}		
		.SF{
			background-color: #ffff80;
		}		
		.GP{
			background-color: #cfff88;
		}			
		.TJ{
			background-color: #b0A6c6;
		}	
	
	}

}	

.fancybox-content{
	border-radius: 20px;
}

.colCommissionNo .adminBesonderheiten::placeholder{color:red !important;}

.cartDetailItemList, .offerDetailItemList  {
	margin-top: 40px;
	.board-column-header {
		.buttonEff {
			cursor: pointer;
			line-height: 26px;
			//border: 1px solid #bbb;
			font-size: 16px;
			//padding: 0 10px;
		    border: none;
			outline:none;
			font-weight: bold;
		}
	}

	.hideColDesc{
		.board-item-content {
			padding: 10px 20px;
			
			.rowGrid{
				grid-template-columns: 20px 0 50px 190px 180px auto 110px 130px 50px;
			}
			
			.colPosition{
				margin-top: 0;
			}
			
			.colItemDesc{
				display: none;
			}
			.colImg {
				display:none;
				width: 0;
			}
			
			
		}
	}

	.itemLiostPosCommissionNoMissing{
		font-weight: bold;
		i{
			font-size: 28px;
			vertical-align: middle;
		}
	}
	
	#cartDetailListLockToggle, #offerDetailListLockToggle {

		
	}

}

.cartDelNotePreviewContainer{
	padding:40px;
	border:1px solid #000;
	-webkit-box-shadow: 7px 5px 10px 2px rgba(0,0,0,0.4); 
	box-shadow: 7px 5px 10px 2px rgba(0,0,0,0.4);
}


.inputAnimated {
	//container needs position: relative	
	width: 100%;
	color: $color-darkBlue;
	
	&:focus + label{
		top: -10px;
		font-size: 12px;	
		color: $color-darkBlue;		
		
	}
	&:not(:placeholder-shown) + label{
		top: -10px;
		font-size: 12px;
		color: $color-darkBlue;
	}
	&:placeholder{
		opacity: 0;	
	}

	&:not(:-ms-input-placeholder) + label{
		top: -10px;
		font-size: 12px;
		color: $color-darkBlue;
	}
	&:-ms-input-placeholder{
		//opacity: 0;	
		color: #fff;
	}

}

.labelAnimated{
	z-index:0;
	top: 6px;
	left: 5px;
	position: absolute;
	cursor: text;
	font-size:14px;
	padding-left: 2px;
	padding-right: 2px;
	background-color: #fff;
	color: #a0a0a0;
	transition: top 200ms ease-in,
				left 200ms ease-in,
				font-size 200ms ease-in,
				color 200ms;
	.fa-check{
		color: green !important;
	}
	.fa-exclamation-triangle{
		color: red !important;		
	}
}
.posRelative{
	position: relative;
}
		
#cartDetailPriceCotnainer {
	width: 300px;
	margin-top: 20px;
	font-size: 16px;
	.pricetxt {
		margin-bottom: 5px;
	}
	.priceNumber {
		text-align: right;
		margin-right: 78px;
		margin-bottom: 5px;
	}
	.borderBottom {
		border-bottom: 1px solid $color-orange;
	}
}

.muuriListHeaderRow{
	.rowGrid{
		grid-template-columns: 30px 70px 50px 190px 180px auto 110px 130px 50px;
		-ms-grid-template-columns: 30px 70px 50px 190px 180px auto 110px 130px 50px;
		
		.ms-Col1{
			-ms-grid-column: 1;
		}
		.ms-Col2{
			-ms-grid-column: 2;
		}
		.ms-Col3{
			-ms-grid-column: 3;
		}
		.ms-Col4{
			-ms-grid-column: 4;
		}
		.ms-Col5{
			-ms-grid-column: 5;
		}
		.ms-Col6{
			-ms-grid-column: 6;
		}
		.ms-Col7{
			-ms-grid-column: 7;
		}
		.ms-Col8{
			-ms-grid-column: 8;
		}
		.ms-Col9{
			-ms-grid-column: 9;
		}
		
	}

}


.rowGrid {
	display:grid;
	grid-template-columns: 30px 70px 50px 190px 180px auto 110px 130px 50px;
	align-items: center;
	width: 100%;
	//min-height: 74px;
	
	input {
		padding: 5px;	
		font-size: 16px;
		border: 1px solid $color-orange;
	}
	
	.cartDetailItemImg {
		width: 50px !important;
		
	}
	.colImg {
		//width: 60px;
		grid-column: 2;
		grid-row: 1 / span 3;
	}
	.colPosition{
		width: 40px;
		grid-column: 1;
		grid-row: 1 / span 3;
	    //margin-top: -80px;
	    margin-left: -10px;
	    font-size: 12px;
	}
	.colItemName {
		//width: 200px;
		grid-column: 4;
		grid-row: 1;
		color: $color-darkBlue;
			a {
				text-decoration: none;
				color: $color-darkBlue;
				&:hover {
					color: $color-orange;
					text-decoration: underline;
				}
			}
	}
	.colItemType {
		//width: 155px;
		grid-column: 5;
		grid-row: 1;
	}
	.colItemComment {
		max-width: 98%;
		grid-column: 6;
		grid-row: 1;
		position: relative;
		input {
			width: 100%;
		}
	}
	.colItemQty {
		//width: 50px;
		grid-column: 3;
		grid-row: 1 / span 3;
		input {
			width: 26px;
			text-align: right;
		}
	}
	.colPrice {
		//width: 140px;
		text-align: right;
	}
	.colPriceItem {
		grid-column: 7 ;
		grid-row: 1 /span 3;
	}
	.colPriceSum {
		grid-column: 8;
		grid-row: 1 /span 3;
	}
	.colDel {
		text-align: right;
		grid-column: 9;
		grid-row: 1 /span 3;
	}
	.colItemDesc {
		grid-column: 4 / span 3;
		grid-row: 2;
		font-size: 14px;
		padding-top: 5px;
	}
	.itemType{
		padding: 5px;
		width: 180px;
		font-size: 16px;
	}
	.itemQty{
		padding: 5px;
		width: 50px;
		font-size: 16px;
		margin-left: -10px;
	}
	.colWarrenty{
		grid-row: 3;
		grid-column: 4;	
		font-size: 14px;	
		margin-top: 10px;
	}
	.colCommissionNo{
		grid-row: 3;
		grid-column: 6 / span 3;	
		font-size: 14px;
		position: relative;	
		margin-top: 10px;
	}
}

.wrapper {
	.wrapperInner {
		//max-width: 1600px;
		margin: auto;
		position: relative;
	}
}
#tabContentMenu{
	width: 100%;
	background-color: $color-gray;
	padding: 10px;
	margin-bottom: 20px;
	margin-right: 20px;
	
}


.navListContainer {
	font-size:16px;
}

#content {
	width: 100%;
	//position: relative;
	margin-top: 20px;
	margin-bottom: 20px;
	

	#contentMain {
		float: left;
		//margin-top: 50px;
		//min-height: 2000px;
		
		
		//max-width: 1660px;
		width: 100%;
		#contentMainMargin {
			margin-left: 410px;
			max-width: 1660px;
			margin-right: 50px;
		}
		
		//margin-left: 220px;
	}
	
	#contentLeft {
		float: left;
		width: 370px;
		z-index: 1;
		position: sticky;
		top: 100px;
		margin-left: -100%;
		//margin-left:  20px;
		//clear: both;
		//background-color: #00FF00;
		
		#leftContainer {
			border-right: 1px dotted $color-darkBlue;
			width: 370px;
			margin-left:  20px;
			//margin: 0 20px;
		}
		
		.boxLeftBorderRound{
			border-radius: 5px;
		}
		
		.boxLeft {
			//border: 1px solid #000;
			margin: 0 0 20px 0;
			width: 100%;
			
			.collapseBtn {
				cursor: pointer;
				position: absolute;
				right: 10px;
				top: 10px;
				font-size: 20px;
				&:hover {
					color: $color-orange;
				}
				
			}
			
		}
		
		
		h1 {
		font-size: 16px;
		margin: 0;	
		font-weight: bold;
		}
	
	
		#boxLogin {
			
			#boxLogInContainer {
				padding: 20px;
				margin: 0 20px 0 0;
				
				h1 {
				font-size: 24px;
				}
			
				label {
					font-size: 14px;
					font-weight: bold;	
				}
				input {
					height: 31px;
					padding: 2px 4px;
					width: 100%;
					margin: 5px 0 20px 0;
					border: 1px solid #a6a6a6;
					border-top-color: #949494;
					border-radius: 3px;
					outline: 0;
					//border-radius: 20
					&:focus {
						border-color: $color-orange;
						box-shadow: 0 0 3px 2px rgba(228,121,17,.5);
					    
		  				//box-shadow: 0 1px 1px $color-orange inset, 0 0 8px $color-orange;
					}
				}
			
				.field-icon {
				  float: right;
				  //margin-left: -25px;
				  margin-top: -45px;
				  position: relative;
				  z-index: 2;
				  cursor: pointer;
				}
			
				.checkbox {
					label {
						cursor: pointer;
					}
					#boxLoginRememberMe {
						width: 16px;
						height: 16px;
						padding: 1px;
						margin-right: 5px;
						vertical-align: sub;
					}
				}
				
				button {
					cursor: pointer;
		    		-webkit-appearance: button;
					margin-top: 10px;
					width: 100%;
					border-radius: 3px;
					border-width: 1px;
					border-color: #a88734 #9c7e31 #846a29;
					//background-color: #f0c14b;
					background-color: #eff0f3;
					height: 31px;
					&:hover{
						background-color: $color-orange;
						border-color: $color-darkBlue;
						color: #fff;
					}
				
				}
				.divider {
				    text-align: center;
				    position: relative;
				    top: 2px;
				    padding-top: 1px;
				    margin-bottom: 14px;
				    line-height: 0;	
				    &:after{
				        content: "";
					    width: 100%;
					    background-color: transparent;
					    display: block;
					    height: 1px;
					    border-top: 1px solid #e7e7e7;
					    position: absolute;
					    top: 50%;
					    margin-top: -1px;
					    z-index: 1;	
				    }
				    
				    h5 {
			    	    line-height: 1;
					    font-size: 14px;
					    color: #767676;
					    font-weight: 400;
					    z-index: 2;
					    position: relative;
					    display: inline-block;
					    background-color: #fff;
					    padding: 0 8px 0 7px;
					    margin: 0;	
				    }
				}
			 	#registerBtn {
			   		margin: 0;
			   		margin-top: 10px;
			   		//background: #000;
			   		a {
			   			text-decoration: none !important;
			   			color: $color-darkBlue;
			   		}
			   		&:hover {
			   		background-color: #adb1b8;
			   		}
		   		}	
			}
			
			
		}
	
		#boxFaq{
			#boxFaqContainer {
				background-color: $color-gray;
				padding: 20px;
				margin: 0 20px 0 0;
				position: relative;
				//border: 1px solid #000;
				
				.boxFaqLink {
					color: $color-darkBlue;
					h1 {
					line-height: 32px;
					
						.fa-question-circle{ 
							font-size: 32px;
							margin-right: 10px;
						}
					
						.iconArrow {
							display: none;
							margin: 0 0 0 50px;
							text-decoration: none;
							font-size: 20px;
							line-height: 30px;
							padding: 0;
							//max-height: 20px;
						}
					
					}
					&:hover {
						//color: $color-orange;
						.iconArrow {
							display: inline-block;
						}
					}
				}
				
			
			}
		}
	/*
	#boxChat{
		#boxChatContainer {
			background-color: $color-gray;
			padding: 20px;
			margin: 0 20px 0 0;
			position: relative;
			//border: 1px solid #000;
			
			.boxChatLink {
				color: $color-darkBlue;
				h1 {
				line-height: 32px;
				
					.fa-comments{ 
						font-size: 32px;
						margin-right: 10px;
					}
				
					.iconArrow {
						display: none;
						margin: 0 0 0 50px;
						text-decoration: none;
						font-size: 20px;
						line-height: 30px;
						padding: 0;
						//max-height: 20px;
					}
				
				}
				&:hover {
					//color: $color-orange;
					.iconArrow {
						display: inline-block;
					}
				}
			}
			
		
		}
	}
*/
	
	
		#boxLoggedIn {
			//position: relative;
			#boxLoggedInContainer {
				background-color: $color-gray;
				padding: 20px;
				margin: 0 20px 0 0;
				position: relative;
				//border: 1px solid #000;
				
				h1 {
					line-height: 30px;
				}
				
				
				#boxLoggedInImgHeadline {
					background: url('./img/ico_boxLoggedIn32.png') no-repeat 0 -2px;
					width: 40px;
					height: 32px;
				}
			}
		}
	
		#boxCarts, #boxOffers {
			
			#boxCartsContainer, #boxOffersContainer {
				background-color: $color-gray;
				//padding: 20px;
				margin: 0 20px 0 0;
				position: relative;
				
				
				a					
					h1{
						//background-color: $color-orange;
						background-color: #fff;
						//background-color: $color-gray;
						color: $color-darkBlue;
						border: 1px solid $color-darkBlue;
						padding:0 !important; 
						font-size: 16px;
						font-weight: bold;
						
						.boxLeftPosCount{
							font-weight: normal;
							font-size: 14px;
						}
						
						.boxCartName, .boxOfferName{
							
						font-size: 14px;
						font-weight: normal;
						}
						//margin-bottom: 0;
						&:hover{
							//background-color: $color-darkBlue;
							//color: #fff;
							.boxCartName, .boxOfferName {
								span {
									color: $color-green;
									text-decoration: underline;
								}
							}
						}
						
						#boxCartsImgHeadline {
							background: url('./img/ico/ico_CartHeadline.png') no-repeat 0 7px;
							width: 40px;
							height: 42px;
						}
						#boxOffersImgHeadline {
							width: 40px;
							height: 42px;
							font-size: 30px;
							
						}
				
					}
			
				.boxCartHeadlineContentContainer{
					padding: 10px 20px 10px 20px;
					border-radius: 5px;
				/*	&:hover {
						background-color: $color-darkBlue;
						color: #fff;
						
						#boxCartsImgHeadline, #boxOffersImgHeadline {
							background-position: 0 -60px;
						}
						
					} */
				}
				
				#boxCartContent, #boxOfferContent{
					margin-top: 5px;
					padding: 0 20px 20px 20px;
					
					.adminRefreshCartCreated {
						color: red;
						cursor: pointer;
						
						&:hover {
							color: $color-orange;
						}
					}
					
				}
				
			//#boxCartsImgHeadline, #boxOffersImgHeadline {
				//	background: url('./img/ico_boxCart32.png') no-repeat 0 -2px;
				//	width: 40px;
				//	height: 32px;
				//}
				
				.boxCartsActiveCartName {
					margin-left: 41px;
					margin-top: -15px;
					a {
					    margin-top: -5px;
					}
				}
				
				.showCartDeleteBox{
					font-size: 20px;
					color: $color-darkBlue;
				}
				
			
				#boxCartItemListContainer, #boxOfferItemListContainer {
					border-top: 1px dotted $color-orange;
					border-bottom: 1px dotted $color-orange;
					margin-top: 10px;
					
					#boxCartItemList, #boxOfferItemList {
						margin: 5px 0 5px 20px;
						width: 90%;
						display: -ms-grid;
						display: grid;
						-ms-grid-columns: 40px 3fr 30px 1fr;
						grid-template-columns: 40px [col-start] 3fr [col-start] 30px [col-start] 1fr [col-start];
						align-items: center;
						
						div:nth-child(4n + 1) {
							-ms-grid-column: 1;
						}
						div:nth-child(4n + 2) {
							-ms-grid-column: 2;
						}
						div:nth-child(4n + 3) {
							-ms-grid-column: 3;
						}
						div:nth-child(4n + 4) {
							-ms-grid-column: 4;
						}
						
						.boxCartItemLink {
							text-decoration: none;
							color: $color-darkBlue;
							&:hover {
								text-decoration: underline;
							}
						}
									
						.delete {
							justify-self: end;
						}
						.boxCartNoItems {
							grid-row: 1;
							grid-column: 1 / span 3;
							-ms-grid-column-span: 3;
							
						}
						
						.addedItemCheck {
							display: none;
							margin-left: -17px;
						}
						
						.addedItemDelay {
							.addedItemCheck {
								color: $color-green;
								display: inline-block;
							}
						}
					
						.addedItemDelay{
							color: $color-green;
							font-weight: bold;
							.boxCartItemLink {
								color: $color-green;
							}
						}
						
					}
				
				}
				#boxCartsCartList, #boxOffersOfferList {
					margin: 20px 0 0 0;
					opacity: 0.6;
					#boxCartsCartListGrid, #boxOffersOfferListGrid{
						
						width: 90%;
						display: -ms-grid;
						-ms-grid-columns: 2fr 1fr 40px;
						display: grid;
						grid-template-columns: 2fr [col-start] 1fr [col-start] 40px [col-start];
						align-items: center;
						
						
						div:nth-child(3n + 1) {
							-ms-grid-column: 1;
						}
						div:nth-child(3n + 2) {
							-ms-grid-column: 2;
						}
						div:nth-child(3n + 3) {
							-ms-grid-column: 3;
						}
						
						.cartDel {
							justify-self: end;
						}
						.cartName{						
							padding: 0 0 0 25px;
							i {
								margin-left: -25px;
								font-size: 16px;
								margin-right: 4px;
							}
						}
						.cartImgSmall{
							background: url('./img/ico/ico_btnCart20.png') no-repeat 0 0;
						}
						.boxCartSwitchLink {
							text-decoration: none;
							color: $color-darkBlue;
							&:hover {
								text-decoration: underline;
							}
						}
					}
					.boxContentBtn {
						font-size: 14px !important;
					}
				}	
			}
			
		}
	
	#boxOrderStatus {
		#boxOrderStatusContainer {
			position: relative;
			background-color: $color-gray;
			padding: 20px;
			margin: 0 20px 0 0;
			
			.boxOrderStatusNavContainer {
				
				.marginTop10{
					margin-top: 10px;
				}
				
				i {
						font-size: 20px;
						width: 30px;
						text-align: center;
				}
			
				a {
					color: $color-darkBlue;
					
					&:hover {
						color: $color-orange;
						text-decoration: underline;
						
					}
				}
				
			}

			
		}
	}
	
		
	}
}
//loginPage
#loginPageContainer{
	.inputFormLabel{
		width:150px;
		display: block;
	}
	.form-control {
		width: 80%;
	}
}

#upsContainer, #repairShipmentContainer{

	.inputRow{
		margin-top: 5px;
	}
	#upsShippmentType{
		padding: 5px;
		font-size: 16px;
		width: 314px;
		margin-bottom: 10px;
		height: 44px;
	}
	input{
		margin-bottom: 10px;
		width: 300px;
		height: 30px;
		font-size: 16px;
	}
	textarea{
		font-size: 16px;
		color: #0030ff !important;
	}
	.select {
		.labelAnimated {
			margin-top: -16px;
			font-size: 12px;
		}
	}
	#upsNo {
		width: 165px;		
	}
	
	.fa-exclamation-triangle {
		color: #efd001 !important;
	}
	.upsHeadline{
		background-image: url("/img/ups_logo.gif");
		background-repeat: no-repeat;
		background-size: 40px;
		background-position:  0 50%;
		height: 50px;
		span{
			padding-left: 50px;
		    padding-top: 13px;
    		display: inline-block;
		}
	}
	
	#imgUpsLabel{
		//transform: rotate(90deg);
		height: 672px;	
		margin-left: 40px;	
		margin-top: -40px;
	}

	#repairShippingItemNoInput{
		width: 200px;
	}
	#itemQtyInput{
		width:40px;
	}
	.upsItemQty{
	    height: 46px;
	    font-size: 16px;

	}

	#repairShippingItemSearchResultContainer{
		width: 192px;
		overflow: hidden;
		border: 1px solid $color-blue;
		padding: 10px;
	    position: absolute;
	    z-index: 100;
	    background-color: #fff;
	    top: 44px;
	    display: none;
	    
	    .upsShippingSearchResultListItem{
	    	padding:3px;
	    	border-bottom: 1px solid $color-blue;
	    	cursor: pointer;
	    	width: 100%;
	    	
	    	&:hover{
	    		background-color: $color-orange;
	    	}
	    }
		
	}

	.rowGridRepairShipment{
		display: grid;
		grid-template-columns: 70px 200px 320px 320px 50px !important;
		width: 100%;
		align-items: center;
		.listItems{
			position: relative;
			
			.repShipmentItemQty {
				height: 44px;
				font-size: 16px;
			    margin-top: -9px;
			}
		
			.repShipmentItemName{
		
					width: 180px !important;
		
			}
		}
	}
}


//openOffers
#offersOpenContainer, #pendingOrdersContainer, #shippedOrdersContainer{
	.listContainer{
		border: 1px solid $color-orange;
		margin-bottom: 20px;
		h3 {
			background-color: $color-orange;
			padding: 10px;
			margin: 0;
			
			button {
				margin-top: -10px;
				border: 0;
			}
		}
		.listDetail {
			padding: 10px;	
		}
		.listItemDetail {
			padding: 10px;	
		}

	}
}
//orderListTable
.orderListTable {
	width: 100%;
	
	th, td {
		text-align: left;
	}
	
	.thPos {
		width: 30px;
	}

	.thQty {
		width: 50px;
	}
	
	.thPrice, .thPriceSum, .tdPrice, .thQty, .tdQty {
		text-align: right;
	}

	.input {
		width: 100%;
	}
}

//artikelliste
#listItemsGrid{
	display: -ms-grid;
	-ms-grid-columns: 2fr 1fr 40px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	
	a{
		text-decoration: none;
		color: $color-darkBlue;
	}
	
	.itemContainer {
		margin: 10px;
		border: 1px solid $color-orange;
		min-width: 150px;
		h4 {
			margin: 0;
			padding: 5px;
			background-color: $color-orange;
		}
	
	&:hover{
		border-color: $color-darkBlue;
		h4 {
			background-color: $color-darkBlue;
			color: #fff;
		}
	}
	
	.itemDetailContainer {
		width: 100%;
		box-sizing: border-box;
		max-height: 105px;
		height: 105px;
		overflow: hidden;
		.imgContainer {
			box-sizing: border-box;
			width: 33%;
			padding: 5px;
			img {
				width: 100%;
			}
		}
		.descContainer {
			box-sizing: border-box;
			padding: 5px;
			width: 65%;
		}
	}
		
	
	}
	
}

//itmelist.html neu
#itemListContainer{
	width: 100%;
	.itemListLinkBox{
		color: $color-font;
			.itemListRow{
			border: 1px solid $color-orange;
			margin-bottom: 20px;
			padding: 10px;
			.itemListImgContainer{
				width: 150px;	
				img{
					width:150px;
				}
			}
			.itemListRowcontentContainer{
				margin-left: 20px;
				max-width:70%;
				.itemName {
					font-size: 16px;
					font-color: $color-blue;
				}
				.itemDesc {
					margin-top:20px;
				 	word-wrap: break-word
				}
			}	
		
			&:hover{
				border-color: $color-blue;
			}
	
		}
		&:visited{
			color: $color-font;
		}
	}

}

//kontakt
#contactContainer, #registrationContainer, #requestLoginForCollegue {
	#contactFormCheckBox, #registrationFormCheckBox {
		width: 16px;
		height: 16px;
		vertical-align: sub;
	}
	label {
		cursor: pointer;
		a {
			color: $color-orange;
		}
	}
		
	input, textarea, select {
		padding: 10px;
		width: 100%;
		max-width: 350px;
		margin-bottom: 20px;
		outline: none !important;
		border: 1px solid #bbb;
		font-family: $font-base;
		font-size: $font-sizeNorm;
		
		&:focus {
			outline: none !important;
			border: 1px solid $color-orange;
		}
	}

	textarea {
		max-width: 350px;
	}
	.btnSubmitContainer {

		
	}
}
#registrationContainer, #requestLoginForCollegue {
	.colReg {
		min-width: 350px;
		
		input {
			max-width: 300px;
			font-size: 15px;
			padding: 8px;
		}
		
		.rowEmpty {
			height: 70px;
		}
		.copyArrow {
			font-size: 30px;
			cursor: pointer;
			position: absolute;
			margin: 25px 0 0 -27px;
			
			&:hover {
				color: $color-orange;
			}
		}
		#registerFormatFields {
			color: $color-orange;
			cursor: pointer;
			margin-top: 20px;
			display: block;
			
			&:hover{
				color: $color-darkBlue;
			}	
		}
		
	}
	
	label { 
		font-size: 14px;
		//font-weight: bold;
		display: block;
	}
	input {
		margin-bottom: 10px;
		&:required{
			background: $color-yellow;
		}	
	}
	textarea {
		&:required{
			background: $color-yellow;
		}	
	}
	.filled {
		background: #fff !important;
	}
	.registrationFormPLZCol {
		width: 110px;		
		input {
			width: 70px;
		}
	}
	#registrationFormCity {
		max-width: 190px;		
	}
	#registrationFormGreeting, #registrationFormCountry {
		max-width: 320px;
		margin-bottom: 10px;	
	}
	.registrationFormCheckBoxRow {
		label {
			display: inline-block;
		}
	}

}

//capcha
.captchaContainer {
	width: 520px;
	margin-top: 20px;
	
	div{
		box-sizing: border-box;
	}
	
	.txtRobot {
		padding: 3px;
		border: 3px solid #fff;
		h4{
			margin:0;
			padding: 0 0 10px 0;
		}
	}
	.error {
		border: 3px solid red;
	}
		
	.capChkBoxDiv{
		position: relative;
		float: left;
		//width: 166px;
		margin-right: 4px;
		.capChkBoxLabel{
			img{
				border: 6px solid #fff;
				width: 150px;
				&:hover{
					border-color: $color-orange;
				}
			}
			.imgActive{
				border: 6px solid $color-green;
				opacity: 0.5;
				
			}
			.imgActive2{
				border: 6px solid red;
			}
		}
		.capChkBox{
			display:none;
		}
		.fa-check {
			position: absolute;
			top: 40px;
			left: 67px;
			font-size: 30px;
			//color: $color-orange;
			display: none;
			opacity: 1 !important;
			
		}
		
	}
}


//alerts 
.alert-success {
	width: 100%;
	color: green;
	border: 2px solid green;
	background-color: $color-gray; 
	margin-bottom: 20px;

	.message {
		margin: auto;
		padding: 10px;
		font-weight: bold;
		
		i {
			font-size: 25px;
			vertical-align: middle;
			margin-right: 10px;
		}
	
		.alert-close {
			float: right;
			color: $color-darkBlue;
			cursor: pointer;
			&:hover {
				color: $color-orange;
			}
		}
	}
}
//heacerMsg
#headerMsg{
	font-size: 16px;
	
	.headerMsgContent {
		color: $color-green;
		padding: 2px;
		//background: $color-gray;
		height: 30px;
		i {
			font-size: 25px;
			
		}
	}

}

//flashBox
.flashBox{
	//width: fit-content;
	width: 100%;	
	margin-bottom: 20px;
	position: relative;
	
	
	.flashMsg {
		margin: auto;
		padding: 10px;
		text-align: center;
		background: $color-gray;
		border-radius: 20px;
		//font-weight: bold;
		
		.flashBoxHide {
			cursor: pointer;
			position: absolute;
			right: 20px;
			top: 10px;
			font-weight: normal;
			color: $color-darkBlue;
			transform: rotate(45deg);
		
			&:hover {
				color: $color-orange;
			} 
		}
	
		.flashRegistrationSendContainer{
			max-width: 400px;
			margin: auto;
			.colLeft {
				width: 120px;
				margin-right: 20px;
				text-align: left;
			}
		}
	
		.flashCartOrderedContainer {
			max-width: 600px;
			margin: auto;

			.colLeftAdress {
				margin-right: 20px;
				width:44%;
				text-align: left;
			}
			.addressContainer, .itemListContainer{
				padding: 10px 0;
				border-bottom: 1px solid $color-darkBlue
			}
			
		}
	}
	

}

//footer
#footer {
//	position: abolute;
	bottom: 0;
	float: none;
	border-top: 3px solid $color-orange;
	background-color: #fff;
	color: $color-darkBlue;
	padding: 20px;
	
	#footerContainer{
		#footerGrid{
			display: -ms-grid;
			-ms-grid-columns: 25% 25% 25% 25%;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			grid-row-gap: 5px;
			max-width: 1900px;
			grid-column-gap: 50px;

			i {
				color: $color-darkBlue;
				font-size: 20px;
			}
			
			h4{
				color: $color-darkBlue;
			}
			a {
				text-decoration: none;
				color: $color-darkBlue;
				&:hover {
					color: $color-orange;
				}
			}
		
			.r1c1 {
				-ms-grid-row: 1;
				-ms-grid-column: 1;
			}
			.r1c2 {
				-ms-grid-row: 1;
				-ms-grid-column: 2;
			}
			.r1c3 {
				-ms-grid-row: 1;
				-ms-grid-column: 3;
			}
			.r1c4 {
				-ms-grid-row: 1;
				-ms-grid-column: 4;
			}
			
		}
	}
}

//ajaxContent

.cartRenameContainer {
	.cartRenameContainerRow {
		padding: 10px 0;
	}
	.cartRenameContainerLabel {
		width: 250px;
	}
	.divLabelInput {
		height: 32px;
		line-height: 30px;
		label {
			cursor: pointer;
		}
	}
	#cartBoxRenameNewName, #cartBoxRenameOldName{
		width: 300px;
	}
	.cartRenameBoxSave {
		margin-top: 20px;
	}

}

.fancyBoxCartAdressList {
	width: 1500px;
	height: 800px;
	.EditBtnNoAdressSelected, .EditBtnMainAdressSelected {
		display: none;
		padding: 20px;
	
	}

	.fancyBoxCartAdressListBtnCopySelectedAdresss {
		display: none;		
	}

	#cartAjaxAdressGridSearchContainer {
		margin-bottom: 20px;
		label {
			width: 200px;
			vertical-align: -webkit-baseline-middle;
		}
		input {
			width: 390px;
			&:focus::placeholder{
       			color:transparent;
			}
		}
	}

	#AdressListContainerNewAdress {
		display: none;
		//max-width: 600px;
		
		.AdressListContainerNewAdressLeft {
			min-width: 600px;
		}
		.AdressListContainerNewAdressRight{
			min-width:300px;
			margin-left: 10px;
			textarea {
				width: 100%;
				height: 300px;
				border-color: red;
			}
		}
		.label {
			float: left;
			width: 200px;
			//font-weight: bold;
		}
		
		.divPlzCity {
			width: 200px;
		}
	
		.newAdressRow {
			margin: 5px;		
		}
		
		input {
			padding: 5px;
			width: 390px;
		}
		#newAdressPlz {
			width: 95px;
			float: left;
	    	margin-right: 5px;
		}
		#newAdressCity {
			width: 290px;
		}
		#newAdressCountry {
			width: 390px;
			font-size: 16px;
			padding: 5px;
			option {
				font-size: 16px;
			}
		}
		.newAdress, .editAdress {
			display:none;
		}
		.newAdressRowButtons{
			margin-top: 20px;
		}
		.borderRed {
			border-color: $color-orange;
			background-color: $color-orange;
		}
	}

	#cartAjaxAdressGridList {
		display: -ms-grid;
		-ms-grid-columns: 40px 60px auto auto auto auto auto auto auto auto auto;
		display: grid;
		grid-template-columns: 40px 60px auto auto auto auto auto auto auto auto auto;
		grid-row-gap: 5px;
		background-color: #ffffff;
		
		.adressItem {
			cursor:pointer;
		}
		
		.rowWrapper {
			display: contents;
		}
		
		div {
			padding: 5px;
		}
		
		div:nth-child(10n + 1) {
			-ms-grid-column: 1;
		}
		div:nth-child(10n + 2) {
			-ms-grid-column: 2;
		}
		div:nth-child(10n + 3) {
			-ms-grid-column: 3;
		}
		div:nth-child(10n + 4) {
			-ms-grid-column: 4;
		}
		div:nth-child(10n + 5) {
			-ms-grid-column: 5;
		}
		div:nth-child(10n + 6) {
			-ms-grid-column: 6;
		}
		div:nth-child(10n + 7) {
			-ms-grid-column: 7;
		}
		div:nth-child(10n + 8) {
			-ms-grid-column: 8;
		}
		div:nth-child(10n + 9) {
			-ms-grid-column: 9;
		}
		div:nth-child(10n + 10) {
			-ms-grid-column: 10;
		}
		div:nth-child(11n + 11) {
			-ms-grid-column: 11;
		}
	
		.bgGray {
			background-color: $color-gray;
		}
	
		.adressListCheckMark {
			display: none;
		}
		
		.rowSelected {
			background-color: $color-orange;
			
			.adressListCheckMark {
				display: inline-block;
			}
		}
	
	}
	
}

.fancyBoxDeleteCartBtn{
	width: 220px !important;
}

//cart fancy shipment list
.fancyBoxCartShipmentList {
	min-width: 700px;
	
	.check {
		width: 30px;
		line-height: 40px;
		font-size: 20px;
		span {
			display:none;
		}
	}
	.shipmentImg {
		width: 70px;
	}
	.shipmentName {
		width: 440px;
		line-height: 40px;
		
		.pickUpTimeContainer {
			//color: red;
			margin-left: 10px;
		}
		
	}
	.shipmentPrice {
		width: 90px;
		line-height: 40px;
		text-align: right;
	}

	.shipmentRowCart {
		
		padding: 10px;
		cursor: pointer;
		height: 62px;
		
		img {
			opacity: 0.5;
		}
		
		&:hover {
			background-color: $color-orange;
			img {
				opacity: 1;
			}
		}
	
		select {
			padding:5px;
		}
	}

	.selectedRow {
		background-color: $color-orange;
		span {
			display:inline-block;
		}
			img {
				opacity: 1;
			}
		
		.upsPriceInactive {
			color: $color-darkBlue !important;
		}
				
	}
	.UserUPSAccountContainerLabel {
		margin-right: 20px;
		line-height: 30px;
	}
	#cartShipmentUpsAccountNr {
		width: 95px;
		margin-right: 20px;
	}
	.upsPriceInactive {
		color: #cccccc;
	}
}
.fancyBoxCart {
	#cartCommmentTextarea {
		height: 150px;
		width: 100%;
		font-size: $font-sizeNorm;
	}
}
#fancyBoxOrderCart{
	
	h3 {
		background: url('./img/ico/ico_CartHeadline.png') no-repeat 0 0;
	    height: 40px;
	    margin-left: -22px;
	    line-height: 36px;
	    text-indent: 40px;
	}
	.fancyOrderCartSumRow{
		margin-top: 20px;
	}
	.fancyOrderCartSumColLeft {
		width: 160px;
	}
	.fancyOrderItemListNameCol{
		width: 180px;
	    line-height: 50px;
	}
	.fancyOrderItemListImgCol{
		width: 50px;
		img {
			width: 50px;
		}
	}
	.fancyOrderItemListTypeCol{
		line-height: 50px;
		padding-left: 10px;
	}
	.fancyOrderShipmentImg {
		vertical-align: top;
	}
	#cartOrderBtn{
		padding-right: 40px;
	}
	.btnClicked{
		.fa-spinner{
			display: inline-block !important;
		}
	}
	.cartOrderSpin {
		color: $color-green;
	}
	.disableOrderButtonBox {
		border: 2px solid red;
		width: 100%;
		padding: 5px;
		
		i {
			color: #ff0000 !important;
		}
	}
}
.selectDeliveryNoteContainerRow {
	padding: 4px;
	border-bottom: 1px dotted #000;
	

	
	label{
		&:hover{
			color: #3E59A1;
			//opacity: 0.6;
			//background-color: $color-orange;
			div{
				//background-color: $color-orange;
			}
		
		
		}
	}
}

.grayed{
	opacity: 0.6;
}
.inputError {
	border-color: $color-orange;
	background-color: $color-orange;
}
.numberError{
	border-color: red !important;
	background-color: $color-yellow !important;
}


//fancybox CartAsEmailOffer
#fancyBoxCartAsEmailOffer{
	width: 800px;
	table {
		width: 100%;
		input {
			width: 100%;
		}
	}
}

//fancy itemListExport
#itemListExport {
	.successMsgContainer {
		color: green;
		margin-top: 20px;
	}
}

//fancy callback
#requestPhoneCall{
	table{
		width: 100%;
			input{
				width: 100%;
			}
	}
}


.dz-preview{
	margin-top: 30px;
	margin-bottom: 30px;
	height: 252px;
	//border: 1px solid $color-darkBlue;
	.dz-image {
		float: left;
		margin-right: 10px;
		height: 250px;
		
		img {
			width: 250px;
			border: 4px solid $color-darkBlue;
		}
	}
	.dz-error-mark, .dz-success-mark {
		display: none;
	}
	//.dz-success-mark {
	//	filter: invert(70%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%);
	//}
}
.dz-progress{
	float:right;
	margin-right: 5px;
	width: 395px;
}


//standard btn
.button {
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	//position: relative;
	display: inline-block;
	overflow: hidden;
	height: 53px;
	//border-radius: 6px;
	//-moz-border-radius: 6px;
	//-webkit-border-radius: 6px;
	line-height: 30px;
	font-size: 14px;
	font-weight: bold;
	//text-shadow: 0px 1px 1px #888;
	cursor: pointer; 
	color: $color-darkBlue;
	
	.width225 {
		width: 225px;
	}
	
	span.icon, span.title {
		display: block;
		//position: relative;
		line-height: 50px;
		padding: 0 25px;
		//border-radius: 6px;
		//-moz-border-radius: 6px;
		//-webkit-border-radius: 6px;
	}
	span.cartBtnTitle {
		padding: 0 10px;		
	}
	span.left {
		float: left;
		//border-radius: 6px 0 0 6px;
		//-moz-border-radius: 6px 0 0 6px;
		//-webkit-border-radius: 6px 0 0 6px;
	}
	span.right {
		float: right;
		//border-radius: 0 6px 6px 0;
		//-moz-border-radius: 0 6px 6px 0;
		//-webkit-border-radius: 0 6px 6px 0;
	}
	span.icon {
		font-size: 23px;
		background-color: $color-orange;;
		-webkit-box-shadow: 0 3px 0 0 $color-btnBoxShadow;
		box-shadow: 0 3px 0 0 $color-btnBoxShadow;
		//text-shadow: 0px 1px 1px #888;
		background-repeat: no-repeat;
		background-position: center;
		
		img {
			margin: 10px 0 0 0;
		}
	}
	span.title {
		-webkit-box-shadow: 0 3px 0 0 $color-btnBoxShadow;
		box-shadow: 0 3px 0 0 $color-btnBoxShadow;
		background-color: $color-gray;
	}
	
	
	//button icons HOVER nicht vergessen!!
	span.icon-itemDetailBtnCart {
		background-image: url("./img/ico/ico_btnCart32.png");
	}

	span.icon-itemDetailBtnOffer {
		background-image: url("./img/ico/ico_btnOffer32.png");
	}
	
	span.icon-cartBtnEdit {
		background-image: url('./img/ico/ico_btnEdit32.png');
	}


	&:hover {
		span.icon {
			background-color: $color-darkBlue;
			-webkit-box-shadow: 0 3px 0 0 $color-darkBlue;
			box-shadow: 0 3px 0 0 $color-darkBlue;
		}
		span.title {
			//background-color: $color-darkBlue;
			-webkit-box-shadow: 0 3px 0 0 $color-darkBlue;
			box-shadow: 0 3px 0 0 $color-darkBlue;
		}
		span.icon-itemDetailBtnCart {
			background-image: url("./img/ico/ico_btnCart32_hover.png");
		}
		span.icon-itemDetailBtnOffer {
			background-image: url("./img/ico/ico_btnOffer32_hover.png");
		}
		span.icon-cartBtnEdit {
			background-image: url('./img/ico/ico_btnEdit32_hover.png');
		}
	}

}

.fancybox-content {
	.fancyBoxOrderCartImgContainer {
		margin-top: -44px;
    	margin-left: -44px;
    	margin-right: -44px;
    	max-height: 100px;
    	overflow: hidden;
    	img{
    		width: 100%;
    		margin-top: -50px;
    	}
	}

	.fancyBoxCartDelNoteImgContainer {
			margin-top: -44px;
	    	margin-left: -44px;
	    	margin-right: -44px;
	    	max-height: 100px;
	    	overflow: hidden;
	    	img{
	    		width: 100%;
	    		//margin-top: -50px;
	    	}
		}
}
.fancybox-container {
	h3 {
		text-align: center;
	}
	.fancybox-bg{
		background: #a0a0a0;
	}
}

//small button
.smallBtn {
	height: 24px;
	width: 24px;
	//background-color: $color-orange;
	overflow: hidden;
	//background: url('./img/ico/ico_btnCartDeleteItem24.png') no-repeat top center;
	margin-left: 20px;
	cursor: pointer;
	&:hover {
		//background-position: bottom center;
		color: $color-orange;
	}
}


//button with +-
/* -- quantity box -- */

.quantity {
 display: inline-block; }

.quantity .input-text.qty {
 width: 35px;
 height: 39px;
 padding: 0 5px;
 text-align: center;
 background-color: transparent;
 border: 1px solid  $color-orange;
}

.quantity.buttons_added {
 text-align: left;
 position: relative;
 white-space: nowrap;
 vertical-align: top; 
.qtyChanged {
	//background: $color-orange !important;
	font-size: 24px;
	font-weight: bold;
	color: $color-green;
} 
}

.quantity.buttons_added input {
 display: inline-block;
 margin: 0;
 vertical-align: top;
 box-shadow: none;
}


.quantity.buttons_added .minus,
.quantity.buttons_added .plus {
 padding: 7px 10px 8px;
 height: 41px;
 background-color: #ffffff;
 border: 1px solid $color-orange;
 cursor:pointer;
 font-size: 20px;
 width: 33px;
 }

.quantity.buttons_added .minus {
 border-right: 0; 
 margin-right: -3px;
 }

.quantity.buttons_added .plus {
 border-left: 0; 
 margin-left: -3px;
 }

.quantity.buttons_added .minus:hover,
.quantity.buttons_added .plus:hover {
 background: $color-orange; }

.quantity input::-webkit-outer-spin-button,
.quantity input::-webkit-inner-spin-button {
 -webkit-appearance: none;
 -moz-appearance: none;
 margin: 0; }
 
 .quantity.buttons_added .minus:focus,
.quantity.buttons_added .plus:focus {
 outline: none; }
 
 //end button

.grayScale {
	-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
	filter: grayscale(100%);
}

.row-gap5 {
	row-gap: 5px;
}
.row-gap10 {
	row-gap: 10px;
}
.rowAdmin {
	background-color: $color-yellow;
}
.backgroundYellow {
	background-color: $color-yellow;
}
.backgroundRed {
	background-color: red !important;
}
.fontBlack{
	color: black !important;
}
.font18 {
	font-size: 18px;
}
.font16{
	font-size: 16px;
}
.font14{
	font-size: 14px;
}
#fixedsticky{
	z-index: 999;
	background-color: #fff;
}

/* The sticky class is added to the navbar with JS when it reaches its scroll position */
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}
.stickyHeaderTop{
  position: fixed;
  top: 0;
  width: 100%;
};

.stickyContentLeft {
  position: fixed;
  top: 100px;
  width: 400px;
}

/* Add some top padding to the page content to prevent sudden quick movement (as the navigation bar gets a new position at the top of the page (position:fixed and top:0) */
.sticky + .content + .stickyContentLeft {
  padding-top: 60px;
}

.floatLeft {
	float:left;
}
.floatRight {
	float:right;
}
.hidden {
	display:none;
}
.block {
	display:block;
}

.clear {
	//clear:both;
}	
.clear:after {
  content: "";
  display: table;
  clear: both;
}

.warnings {
	color: red;
}
.linkA {
	text-decoration: none;
	color: $color-darkBlue;
	&:hover {
		color: $color-orange;
	}
}

.inputBorderRed{
	border-color: red !important;	
}

#userMessages {

  background: $color-orange; 
  opacity: 0.9;
  color: $color-darkBlue;
  position: fixed; 
  bottom: 40px; 
  right: 20px; 
  min-width: 300px; 
  max-width: 400px; 
  height: 0; 
  overflow: hidden; 
  padding: 10px; 
  font-size: 16px; 
  font-weight: normal; 
  //border: 1px solid @blue;
  //border-radius: 3px;
  box-shadow: 0 0 15px $color-darkBlue;
  outline: none;
  display: none;
  .usermsgArrow {
    margin: 4px 4px 0 0;
  }
  .usermsgCart {
    margin: 0 4px 2px 0;
  }
}

//fancybox Classes
#fancyBoxItemQuestion {
	
	#fancyBoxItemQuestionTextAreaContainer, #fancyBoxItemQuestionEmailContainer {
		
		margin-bottom: 10px;
		
		input, textarea {
			max-width: 600px;
			width: 100%;
			font-family: $font-base;
			font-size: $font-sizeNorm;
			padding: 5px;
			outline: none !important;
			border: 1px solid #aaa;
			
			&:focus {
				outline: none !important;
				border: 1px solid $color-orange;
			}
		}
	

	}
	#fancyBoxItemQuestionReturnMsg {
		margin: 10px 0;
		
		.inputError {
			border: 1px solid red;
		}
		#fancyBoxItemQuestionSuccess {
			color: $color-green;
		}
	}
	.fancyBoxItemQuestionItemImg{
		width: 180px;
    	margin-top: -25px;
    	padding-left: 20px;
	}

	
}

#fancyBoxCartDeleteItem{
	.cartItemDeleteBtn {
		margin-top: 20px;
	}
}
#fancyBoxNewCart, #fancyBoxNewOffer, #fancyBoxNewCartAddItem{
	.newCartRow {
		width: 50%;
	//	margin: 20px 20px 20px 0;
		line-height: 51px;
	}
	#fancyBoxNewCartInputName, #fancyBoxNewCartInputNameNorm, #fancyBoxNewCartInputNameAddItem {
		width: 90%;
	}
	.fancyBoxNewCartBtn{

	}
}

.fancyBoxCartAdressList {
	//width: 1300px;
	overflow-y: scroll;
}

.dropzone{
	width: 100%;
	height: 50px;
	border: 1px solid $color-darkBlue;
	padding: 10px;
	margin-top: 20px;
	background-color: $color-gray;
	cursor: pointer;
	&:hover{
		border: 1px dashed $color-darkBlue;	
	} 
	.dz-message {
		span {
		line-height: 25px;
			&:before {
				color: #f8d775 !important;
				content: '\f07c';
				font: {
				  family: "Font Awesome 5 Free";
				  size: 25px;
				  weight: 900;
				  
				}
				float: left;
				margin-right: 10px;
			}
		}
	}

}
.dz-upload { 
    display: block; 
    background-color: $color-orange; 
    height: 5px;
    width: 0%;
}
.dz-error-message {
	display: block; 
	color: red;
	height: 50px;
}


#fileTransferContainer {
	.filet {
	width: 100%;
		.dz-message {
			margin-top:20px;
			span {
			line-height: 25px;
				&:before {
					color: #f8d775 !important;
					content: '\f07c';
					font: {
					  family: "Font Awesome 5 Free";
					  size: 25px;
					  weight: 900;
					  
					}
					float: left;
					margin-right: 10px;
				}
			}
		}
	}
	.dz-drag-hover {
	border: 2px dashed $color-darkBlue;
	background: $color-orange;
	width: 100%;
		span {
			display: none;
		}
		&:before {
			content: '\f019';
			font: {
			  family: "Font Awesome 5 Free";
			  size: 25px;
			  weight: 900;
			}
			margin: 45%;
		}
	}
}


.dz-drag-hover {
	border: 2px dashed $color-darkBlue;
	background: $color-orange;
	span {
		display: none;
	}
	&:before {
		content: '\f019';
		font: {
		  family: "Font Awesome 5 Free";
		  size: 25px;
		  weight: 900;
		}
		margin: 45%;
	}
}
.btnDeleteFile {
	color: red;
	cursor: pointer;
	&:hover {
		color: $color-orange;
	}
}
.availability-green {
	color: $color-green;
	font-size:18px;
	vertical-align: middle;
}
.bold{
	font-weight: bold;
}
.width200 {
	width:200px;
}
.lineHeight30 {
	line-height: 30px;
}
.marginTop-10px{
	margin-top: -10px;
}
.margin0{
	margin: 0;
}
.marginTop0 {
	margin-top: 0px;
}
.marginTop5 {
	margin-top: 5px;
}
.marginTop7 {
	margin-top: 7px;
}
.marginTop10 {
	margin-top: 10px;
}
.marginTop13 {
	margin-top: 13px;
}
.marginTop20 {
	margin-top: 20px;
}
.marginTop40 {
	margin-top: 40px;
}
.marginTop-80 {
	margin-top: -80px;
}
.marginBot10{
	margin-bottom: 10px;
}
.margin20 {
	margin-top: 20px;
}
.marginLeft40px{
	margin-left: 40px;
}
.marginLeft100 {
	margin-left: 100px;
}
.marginLeft10 {
	margin-left: 10px;
}
.marginLeft20 {
	margin-left: 20px !important;
}
.marginLeft40 {
	margin-left: 40px;
}
.marginLeft70 {
	margin-left: 70px;
}
.margingRight5 {
	margin-right: 5px;
}
.marginRight5 {
	margin-right: 5px;
}
.marginRight10 {
	margin-right: 10px;
}
.margingRight20 {
	margin-right: 20px;
}
.marginRight20 {
	margin-right: 20px;
}
.marginLeft25 {
	margin-left: 25px;
}
.marginRight-20 {
	margin-right: -20px;
}
.padding5 {
	padding: 5px;
}
.padding20 {
	padding: 20px;
}
.padding10 {
	padding: 10px;
}
.padding1240 {
	//padding: 12px 40px 12px 20px !important;
}
.padding740{
	padding: 7px 40px !important;
}
.padding12401240{
	padding: 12px 40px !important;
}
.padding12{
	padding: 12px !important;
}
.width50per {
	width: 50%;
}
.width45per {
	width: 45%;
}
.width51per {
	width: 51%;
}
.width40per {
	width: 40%;
}
.width71per {
	width: 71%
}
.width24per{
	width: 24%;	
}
.width90per {
	width: 90%;
}
.width100per {
	width: 100%;
}
.width30px {
	width: 30px;
}
.width200 {
	width: 200px;
}
.width100 {
	width: 100px;
}
.width120 {
	width: 120px;
}
.width60 {
	width: 60px;
}
.width30 {
	width: 30px;
}
.width300{
	width:300px;	
}
.width340px{
	width:340px;	
}
.width400{
	width:400px;	
}
.maxWidth310{
	max-width: 310px;
}
.maxWidth400{
	max-width: 400px;
}
.minHeight200px{
	min-height: 200px;	
}
.textAlignLeft {
	text-align: left;
}
.textAlignRight {
	text-align: right;
}
.textAlignCenter {
	text-align: center !important;
}

.verticalAlignTop {
	vertical-align: top;
}
.verticalAlignBottom {
	vertical-align: bottom;
}
.lineHeight10 {
	line-height: 10px;
}
.fontArial{
	font-family: Arial;
}
.font10pt{
	font-size:10pt;
}
.font11pt{
	font-size:14pt !important;
}
.font12pt{
	font-size:12pt;
}
.font22{
	font-size:22px;
}
.font32{
	font-size:32px;
}
.font40{
	font-size:40px !important;
}
.font70{
	font-size:70px;
}
.checkedGreen {
	color: $color-green;
}
.fontBigGreen{
	color: $color-green;
	font-weight: bold;
	font-size: 30px !important;
}
.displayblock {
	display: block;
}
.borderSolid{
	border: 1px solid $color-darkBlue;
}
.whiteSpacePre{
	white-space: pre;
}
// modernizer classes
.no-cssgrid {
	#itemDetailDisplay {
		#itemDetailGridTop {
			//display: table;
		}
		#itemDetailGridItemTypes {
			//display: table;
		}
	}
	#cartDetailDisplay {
		.cartDetailGridTop{
			//display: table;
		}
	}
}
.cssgridlegacy {
	
}
.adminRed {
	color: red !important;
}
.blue{
	color: blue !important;
}
.blueBold{
	color: $color-blue;
	font-weight: bold;
}
.screenHide {
	display: none !important;
}
.rotate146{
    transform: rotate(146deg)
}
.fliesstext_rot {
	color: red;
}
.fliesstext_green {
	color: green;
}
.fliesstext_gruen {
	color: green;
}
.fliesstext_blau {
	color: #0088BB;
}
.fliesstext_blue_bold {
	color: blue;
	font-weight: bold;
}
.fliesstext_turquoise {
	color: #00dfd7;
}
.fliesstext_magenta{
	color: #ff00ea;
}
.fliesstext_yellow{
	color: #ffde00;
}
.tableBackGreen{
	background-color: green !important;
}
.tableBackYellow{
	background-color: $color-yellow !important;
}
.tableBackLightRed{
	background-color: #ff9d9d !important;
}
.cursorPointer{
	cursor: pointer;
}
textarea {
	font-family: $font-base;
}
.fancybox-close-small{
	text-weight: bold;
}

#tableTemplate{
	tr td br{
		mso-data-placement:same-cell;
	}
}

//chatShoweBtn
#chatShowBtn{
	width: 54px;
	height: 54px;
	-webkit-box-shadow: 7px 5px 10px 2px rgba(0,0,0,0.4); 
	box-shadow: 7px 5px 10px 2px rgba(0,0,0,0.4);
  	position: fixed;
 	//bottom: 40px; 
 	//right: 40px;
 	top: 200px;
 	right: 40px;
 	border-radius: 8px 8px;
 	padding: 5px;
 	font-size: 45px;
 	margin: auto;
 	color: white;
 	background-color: $color-red;
 	cursor: pointer;
 	z-index: 1000;
 	#chatShowBtnIcoContainer{
 		margin-top: -5px;
 		margin-left: 1px;
 	}
 	
 	&:hover {
 		background-color: white;
 		color: $color-darkBlue;
 	}
}
.chatHome{
	//top: 200px;
	//left: 30px !important;
	transition: all 1s ease-in;
	-webkit-transition: all 1s ease-in;
	-moz-transition: all 1s ease-in;
	-o-transition: all 1s ease-in;

}

//chatBoxRight
#chatBoxRight {
	z-index: 1000;
	height: 600px;
//	max-width: 700px;
	position: fixed;
	//background-color: #fff;
	//border-radius: 8px 0 0 8px;
	border-radius: 12px 12px;
	//border: 1px solid $color-darkBlue;
	overflow: hidden;
	display: none;
	
	margin: auto;
 	top: 200px; 
 	
 	//left: 20px;
 	
 	//bottom: 40px; 
 	right: 40px;
	-webkit-box-shadow: 7px 5px 10px 2px rgba(0,0,0,0.4); 
	box-shadow: 7px 5px 10px 2px rgba(0,0,0,0.4);
  	background: linear-gradient(rgb(255, 255, 255) 0%, rgb(249, 249, 249) 100%);
	
	.chatWrapper{
	
		#chatHeadlineLeft {
			background-color: $color-orange;
			font-size: 20px;
			font-weight: bold;
			//writing-mode: vertical-lr; 
			//float:left;
			width: 400px;
			//height: 600px;
			padding:5px;
			cursor: pointer;
			border-right: 0px solid #fff;
			//height: 100%;
			line-height: 45px;
			height: 40px;
			
			i {
				font-size: 30px;
			}
		
			.fa-chevron-down{
				position: absolute;
				right: 10px;
				line-height: 45px;
			}
			.fa-comments{
				color: $color-red;
			}
		
			.slideBtn {
				position: absolute;
				//margin: auto;
				transform: rotate(90deg);
				font-size: 20px;
				line-height: 30px;
				font-weight: normal;
				&:before{
					content: "\00276D" !important;
					
					//transform: rotate(90deg);
					}
			}
		
			&:hover {
				//background-color: $color-darkBlue;
				background-color: #3E59A1;
				color: #fff;
			}
			
		}
	
		.chatHeadlineLeftActive {
			.slideBtn {
				transform: rotate(270deg) !important;
			}
		}
	
		#chatContentCol{
			float:left;
			width: 410px;
			//display: none;
			
			
			#chatContentContainer {
				//display: none;
				max-height: 450px;
				padding: 0 0 0 10px;
				position: relative;
				//background: #fff;
				overflow: hidden;
				
				.bgHr{
					background-color: $color-yellow !important;
				}
					
				
				#chatMsgContainer{
					overflow-y: scroll;
					max-height: 450px;
		
					.newMsg {
						background: $color-yellow !important;
						font-size: 12px;
						color: red;
					}
					
					.chatSender, .chatDate{
						font-size: 12px;
						
					}
					
					.msgContainer{
						background: #eeeeee;
						margin: 5px 0;
						padding: 5px;
						width: 90%;
						border-radius: 10px;
						overflow-wrap: break-word;
						margin-right: 20px;
						
					}
					.chatHideMsg{
						cursor: pointer;
						font-weight: bold;
						&:hover {
							color: $color-green;
						}
					}
				
				}
			}
		
			label {
				cursor: pointer;
			}
		
			#chatInputEmailForAnswerContainer {
				//display: none;
				background: #fff;
				#chatInputEmail {
					padding: 5px;
					margin: 5px 0;
					width: 90%;
				}
			}
			.loggedIn{
				display:none;
			}

			#chatInputContainer{
				background: #fff;
				padding: 10px;
				
				//test
				width: 100%;
				position: absolute;
				box-sizing: border-box;
				bottom: 0;
				.chatInputContainerEmailAnswer{
					padding-bottom: 5px;
					.chatInputContainerEmailAnswerIconContainer{
						margin-right: 3px;
						.fa-envelope{
							font-size: 20px;
						}
					}

					#chatAnswerAsEmailCheckBox{
						transform: scale(1.5);
					}
				}

				#chatInputTextareaCol {
					width: 290px;
					
					textarea {
						width: 100%;
						height: 32px;
						font-size: 14px;
						background-color: $color-yellow;
						border-radius: 10px;
						
					}
				}
				#chatInputMsgSendCol{
					float: right;
					margin-left: 10px;
					//width: 25%;
					button {
						background-color: $color-gray;
						padding:10px;
						//height: 4em;
						border-radius: 10px;
					}
				}
				
					.fa-envelope{
						font-size: 16px;
					}
			
			
			}
			
		}
	}
}


.chatEmail{
	overflow-y: scroll;
	max-height: 450px;

	.newMsg {
		background: $color-yellow !important;
		font-size: 12px;
		color: red;
	}
	
	.chatSender, .chatDate{
		font-size: 12px;
		
	}
	
	.msgContainer{
		background: #eeeeee;
		margin: 5px 0;
		padding: 5px;
		width: 90%;
		border-radius: 10px;
		overflow-wrap: break-word;
		margin-right: 20px;
		
	}
	.chatHideMsg{
		cursor: pointer;
		font-weight: bold;
		&:hover {
			color: $color-green;
		}
	}

}


@-webkit-keyframes blinker {
  from {background-color: $color-orange;}
  to {background-color: red;}
}
.blink{
	color: #fff;
	//color: red;
	text-decoration: blink;
	-webkit-animation-name: blinker;
	-webkit-animation-duration: 0.6s;
	-webkit-animation-iteration-count:infinite;
	-webkit-animation-timing-function:ease-in-out;
	-webkit-animation-direction: alternate;
}

//chatbox
/*
#chatBox{
	position: fixed;
	bottom: 100px;
	right: 0px;
	width: 400px;
	background: #fff;
	border: 1px solid $color-darkBlue;
	border-radius: 8px 8px 0 0;
	z-index: 1000;
	background: #fff;
	min-width: 400px;
	min-height: 253px;
	
	#chatBtnSendMsg {
		padding: 10px;
	}
	
	#chatHeadline {
		background: $color-orange;
		padding: 10px;
		margin: 0;
		cursor: pointer;
		//background: #fff;
		overflow: hidden;
		border-radius: 8px 8px 0 0;
		i {
			font-size: 25px;
			margin-right: 20px;
		}
		.iFloatRight {
			float: right;		
		}
		.chev {
			display: none;
		}
		.fa-window-maximize, .fa-window-minimize, .fa-arrows-alt {
			&:hover {
				color: #fff;
			}	
		}
	
	}
		
	#chatContentContainer {
		//display: none;
		max-height: 500px;
		padding: 10px;
		position: relative;
		background: #fff;
		overflow: hidden;
		
		.bgHr{
			background-color: #e3fdff !important;
		}
		
		.btnExpandLeft{
			position: absolute;
			left: -36px;
			height: 47px;
			width: 35px;
			background: $color-orange;
			border-radius: 6px 0 0 6px;
			font-size: 20px;
			line-height: 51px;
			margin: auto 0;
			cursor: pointer;
			i {
				margin-left: 10px;
				font-size: 25px;
			}
		
			&:hover {
				background: $color-darkBlue;
				color: #fff;
			}
		}
			
		
		#chatMsgContainer{
			overflow-y: scroll;
			max-height: 200px;

			.newMsg {
				background: $color-orange !important;
			}
			
			.chatSender, .chatDate{
				font-size: 12px;
				
			}
			
			.msgContainer{
				background: $color-gray;
				margin: 5px 0;
				padding: 5px;
				//border: 1px solid $color-orange;
				border-radius: 5px;
				.chatHideMsg{
					cursor: pointer;
					font-weight: bold;
					&:hover {
						color: $color-green;
					}
				}
			}
		
		}
	}

	label {
		cursor: pointer;
	}
	#chatInputEmailForAnswerContainer {
		display: none;
		background: #fff;
		#chatInputEmail {
			padding: 5px;
			margin: 5px 0;
			width: 90%;
		}
	}

	#chatInputContainer{
		background: #fff;
		padding: 10px;
		
		//test
		width: 100%;
		position: absolute;
		box-sizing: border-box;
		bottom: 0;
		#chatInputTextareaCol {
			width: 70%;
			
			textarea {
				width: 100%;
				height: 4em;
				font-size: 13px;
				
			}
		}
		#chatInputMsgSendCol{
			float: right;
			margin-right: 10px;
			button {
				height: 4em;
			}
		}
	}
	 .ui-icon-gripsmall-diagonal-se {
	 	//background: #ccc;
	 	height: 20px;
	 	width: 20px;
	 	position: absolute;
	 	right: 0;
	 	display: block;
	 	bottom: 0;
	 	cursor: nwse-resize;
	 	transform: rotate(45deg);
	 	&:after {
	 		font: {
		      family: "Font Awesome 5 Free";
		      size: 20px;
		      weight: 900;
		      
		    }
		   	
	 		content: '\f337';
	 		
	 	}
	 	
	 }

}
*/

.noText {
	opacity: 0.4;
}

// muuri

.board {
  position: relative;
//  margin-left: 1%;
}
.board-column {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
 // margin: 0 1.5%;
  background: $color-gray;
  border-radius: 3px;
  z-index: 1;
  
  .muuriListHeaderRow {
  	position: relative; 
  	border: 10px solid #eeeeee;
  	border-bottom: 0;
  	border-radius: 4px;
  	padding: 5px 20px;
  	background-color: #fff;
	margin-bottom: -5px;
  }
  
}
.board-column.muuri-item-releasing {
  z-index: 2;
}
.board-column.muuri-item-dragging {
  z-index: 3;
  cursor: move;
}
.board-column-header {
  position: relative;
  min-height: 50px;
  line-height: 50px;
  overflow: hidden;
  padding: 0 20px;
  //text-align: center;
  //background: $color-orange;
  background: #fff;
  color: $color-darkBlue;
  //border-radius: 3px 3px 0 0;
  font-size: 16px;
  font-weight: bold;
}

.board-column.cartItemList .board-column-header {
  background: $color-orange;
}

/* This is the secret sauce,
   always use a wrapper for setting
   the "overflow: scroll/auto" */
.board-column-content-wrapper {
  position: relative;
  //max-height: 300px;
  overflow-y: auto;
}
/* Never ever set "overflow: auto/scroll"
   to the muuri element, stuff will break */
.board-column-content {
  position: relative;
  border: 10px solid transparent;
  min-height: 95px;
}
.board-item {
  position: absolute;
  width: 100%;
  margin: 5px 0;
}
.board-item.muuri-item-releasing {
  z-index: 9998;
}
.board-item.muuri-item-dragging {
  z-index: 9999;
  cursor: move;
}
.board-item.muuri-item-hidden {
  z-index: 0;
}
.board-item-content {
  position: relative;
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  -webkit-box-shadow: 0px 1px 3px 0 rgba(0,0,0,0.2);
  box-shadow: 0px 1px 3px 0 rgba(0,0,0,0.2);
}
.cartItemListRow {
	cursor: pointer;
}
.locked {
	user-select: text !important;
	cursor: auto !important;
	.board-item-content {
		cursor: auto;
	}
}

.loading {
	  position: fixed;
	  width: 100%;
	  height: 100%;
	  background: rgba(255,255,255,.7);
	  text-align: center;
	  top: 0;
	  left: 0;
	  z-index: 99;
	  display: none;
	  
	  div{
	    left: 45%;
	    margin: -25px 0 0 -25px;
	    //opacity: .7;
	    padding: 20px;
	    position: absolute;
	    top: 50%;
	    z-index: 99999;
	    font-size: 80px;
	    color: $color-green;
	    //color: #0998A3;
	    background-color: #fff;
	    border-radius: 80px;
	    
	    img{
	    	height: 60px;	
    	   // margin-bottom: -10px;
	    }
	  }
	  
	i {


	  }
  
}
//@keyframes loading-rotate {
//    100% {
//        transform: rotate(360deg);
//    }
//}

@media (max-width: 1580px) {
	$font-size-price: 20px;
	body {
		font-size: 14px;
	}
	#itemDetailDisplay #itemDetailGridItemTypes {
		grid-template-columns: 290px 290px;
		.itemTypeBox {
			max-width: 275px;
		}
	}

	.wrapperUserNav #mainSearchRight #mainSearchInput {
		width: 70%;
		max-width: 500px;
	}
	.rowGrid {
		.colItemComment {
			grid-column: 6 /span 3;	
		}
		.colPrice{
			grid-row: 2;	
		}
	}
}

@media (max-width: 1600px) {
	.muuriListHeaderRow{
		.rowGrid{
			grid-template-columns: 1px 70px 50px minmax(170px, auto) 90px 90px 50px !important;
			-ms-grid-template-columns: 1px 70px 50px minmax(170px, auto) 90px 90px 50px !important;
			.rowGridHeaderColQty{
				grid-row: 1;
				grid-column: 3;		
				-ms-grid-row: 1;
				-ms-grid-column: 3;		
			}
			.rowGridHeaderColItemName{
				grid-row: 1;
				grid-column: 4;
				-ms-grid-row: 1;
				-ms-grid-column: 4;	
			}
			.rowGridHeaderColComment{
				display: none;
			}
			.rowGridHeaderColPrice{
				grid-row: 1;
				grid-column: 5;
				-ms-grid-row: 1;
				-ms-grid-column: 5;	
			}
			.rowGridHeaderColPriceSum{
				grid-row: 1;
				grid-column: 6;	
				-ms-grid-row: 1;
				-ms-grid-column: 6;
			}

		}
	}	
			
	.board-item-content {
		text-align: left;
		.rowGrid {
		 //   display: table;
		 //grid-template-columns: 1px 70px 50px 200px 200px 100px 130px 50px;
			grid-template-columns: 1px 70px 50px minmax(170px, auto) 90px 90px 50px !important;
			.colImg{
			 	//display: none;
			 	grid-row: 3 / span 2;
			 	-ms-grid-row: 3 / span 2 !important;
			 }
			.colPosition{
				//display: none;
				//grid-row: 3/ span 2;
			}
			.colItemQty{
				grid-row: 1 /span 2;
				grid-column: 3;
				justify: center;
				-ms-grid-row: 1 !important;
				-ms-grid-row-span: 2;
			 	-ms-grid-column: 3 !important;
			}
			.colItemName{
				grid-row: 1;
				grid-column: 4; 	
				-ms-grid-row: 1 !important;
			 	-ms-grid-column: 4 !important;
			}
			.colItemType{
				grid-row: 2;
				grid-column: 4; 
				-ms-grid-row: 2 !important;
			 	-ms-grid-column: 4 !important;	
			 }
			.colItemDesc{
				grid-row: 4;
				grid-column: 3 /span 4;
				-ms-grid-row: 4 !important;
			 	-ms-grid-column: 3!important;
			 	-ms-grid-column-span: 4;
			}		
			.colItemComment {
			 	grid-row: 5 !important;
			 	grid-column: 3 /span 3;
			 	-ms-grid-row: 5 !important;
			 	-ms-grid-column: 3!important;
			 	-ms-grid-column-span: 3;
			 	margin-top: 15px;
			 }
			.colCommissionNo {
				grid-row: 6 !important;
			 	grid-column: 3 /span 3;
			 	-ms-grid-row: 6 !important;
			 	-ms-grid-column: 3 !important;
			 	-ms-grid-column-span: 3;
			}
			.colPriceItem{
				grid-column: 5;
				-ms-grid-column: 5 !important;
			}
			.colPriceSum{
				grid-column: 6;
				-ms-grid-column: 6 !important;
			}			
			.colDel{
				grid-column: 7;
				-ms-grid-column: 7 !important;
			}
		
		}
	}


}

@media (max-width: 1400px) {
	
	#itemDetailDisplay #itemDetailGridItemTypes {
		grid-template-columns: 290px 290px;
	}
	#itemDetailDisplay #itemDetailGridTop {
	grid-template-columns: 	150px minmax(280px, auto);
	-ms-grid-columns: 150px 2fr; 	
	
		#itemImgLeftCol {
			img {
				width: 150px;
			}
		}
		.itemRightColGrid{
			grid-template-columns: 140px auto;
		}
	}

	.no-cssgrid{
		grid-template-columns: 	100px minmax(300px, auto) ;	
		
		#itemImgLeftCol {
			img {
				width: 100px;
			}
		}
		.itemRightColGrid {
			//width:100%;	
		}		
	}
	#listItemsGrid{
		grid-template-columns: 1fr 1fr 1fr;
	}
	#home #homeContainer .homeSlideContainer .action {
		max-width: 680px;
		text-align: justify;
		
		a {
			display: inline-block;
		}
		
	}

	#chatBoxRight {
		height: 400px;
		top: 90px;
		#chatContentContainer {
			//max-height: 250px;
			height: 250px;
			#chatMsgContainer {
				height: 250px;
			}
		}
	}

	

}


@media screen and (max-width: 1090px) {
	
	body {
	  min-width: 310px;
	  max-width: 1090px;
	}
	.wrapper {
	  max-width: 1090px;
	}
	 
 	.mobilHead {
		display: block;
	}
	 
	 #header #headerTopContent {

		#logo {
			float: none;		
		}
	 	
	 	.mobilHead{
 		    line-height: 39px;
 			.buttonEff{
				padding: 10px;
			}
			a {
				color: $color-darkBlue;
				
				&:hover{
					color: $color-orange;
				}
			}
			
	 	}

 		#langSelect {
	 	
	 	float: left;
	 	width: 100%;
	 	#dataContactHead{
		 	line-height: 30px;
		 	div {
		 		float: none;
		 	}
		 	.marginRight20{
		 		margin-right: 0;
		 	}
		 }
		}
	 }
	
	.wrapperUserNav #mainSearchRight .searchInputClear{
		display: none;
	}
	
	.wrapperUserNav #mainSearchRight #mainSearchInput {
		width: 70%;
		max-width: 300px;
	}

	#cartDetailDisplay .cartDetailGridTop {
		display: table;
		div {
			margin-bottom: 10px;
		}
	}
	#itemDetailDisplay #itemDetailGridTop {
		display: table;
	}
	 
	#footer #footerContainer #footerGrid {
		display: table;
	}
	.board-item-content {
		text-align: left;
	
		
	}
	.board-item-content span {
	  //display: none;
	}
	.board-column-header {
		text-indent: -1000px;
	}
	#itemDetailDisplay #itemDetailGridItemTypes {
		grid-template-columns: 290px 290px
	}
	#leftContainer {
		display:none;
	}
	#mainSearchRight, #contentMain {
		margin-left: 0 !important;
	}
	#contentMainMargin{
		margin-left: 10px !important;
	}
	#userHistoryContainer {
		display: none;
	}
	#chatBox{
		display: none;
	}

	#itemDetailDisplay #itemDetailGridTop {
		grid-template-columns: 	100px minmax(300px, auto) ;	
		
		#itemImgLeftCol {
			img {
				width: 100px;
			}
		}
		.itemRightColGrid {
			//width:100%;	
		}
	}
 
	#listItemsGrid {
		display: -ms-grid;
		-ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
		
		.itemCol:nth-Child(2n+1){
			-ms-grid-column: 1;
		}
		.itemCol:nth-Child(2n+2){
			-ms-grid-column: 2;
		}

	
		.itemCol:nth-Child(n+1):nth-Child(-n+2){
			-ms-grid-row: 1;
			}
		.itemCol:nth-Child(n+3):nth-Child(-n+4){
			-ms-grid-row: 2;
		}
		.itemCol:nth-Child(n+5):nth-Child(-n+6){
			-ms-grid-row: 3;
		}
		.itemCol:nth-Child(n+7):nth-Child(-n+8){
			-ms-grid-row: 4;
		}
		.itemCol:nth-Child(n+9):nth-Child(-n+10){
			-ms-grid-row: 5;
		}
		.itemCol:nth-Child(n+11):nth-Child(-n+12){
			-ms-grid-row: 6;
		}
	
		
	}

	.muuriListHeaderRow{
		.rowGrid{
			grid-template-columns: 50px 170px 90px 90px 50px !important;
			.rowGridHeaderColQty{
				grid-row: 1;
				grid-column: 1;				
			}
			.rowGridHeaderColItemName{
				grid-row: 1;
				grid-column: 2;
			}
			.rowGridHeaderColComment{
				display: none;
			}
			.rowGridHeaderColPrice{
				grid-row: 1;
				grid-column: 3;	
			}
			.rowGridHeaderColPriceSum{
				grid-row: 1;
				grid-column: 4;	
			}
		}
	}	
			
	.board-item-content {
		text-align: left;
		.rowGrid {
		 //   display: table;
		 //grid-template-columns: 1px 70px 50px 200px 200px 100px 130px 50px;
			grid-template-columns: 50px 170px 90px 90px 50px !important;
			.colImg{
			 	display: none;
			 }
			.colPosition{
				display: none;
			}
			.colItemQty{
				grid-row: 1/ span2;
				grid-column: 1;
				justify: center;
			}
			.colItemName{
				grid-row: 1;
				grid-column: 2; 	
			}
			.colItemType{
				grid-row: 2;
				grid-column: 2; 	
			 }
			.colItemDesc{
				grid-row: 4;
				grid-column: 1 /span 4;
			}		
			.colItemComment {
			 	grid-row: 5 !important;
			 	grid-column: 1 /span 3;
			 }
			.colPriceItem{
				grid-column: 3;
			}
			.colPriceSum{
				grid-column: 4;
			}			
			.colDel{
				grid-column: 5;
			}
		}
	}
	.cartDetailFurtherCartsContainer {
		display: none;
	}

	.wrapperNav #mainNav li {
		a {
			padding: 9px 15px;	
		}
		.smallLogin {
			display: block !important;
		}	
	}

}

@media (max-width: 720px) {
	
	header{
		padding: 5px;
	}

	.sticky{
		background-color: yellow;
		#headerTopContent{
			display: none;
		}
	}
	
	.hideOnMobile {
		display: none;
	}
	#banner{
		display: none;
	}
	#mainSearchLeft {
		display:none;
	}
	.screenHide {
		display: block !important;
	}
				
	.mobileTopNewCartBtn, .mobileTopNewOfferBtn{
		width: 150px;
	}

	.wrapperNav {
		//z-index: 2000;
		position: relative;
		z-index: 999 !important;
		
		#mobileNavContainer{
			height: 39px;
			cursor: pointer;
			//padding: 0 10px;
			
			&:hover{
				
				background-color: $color-orange;
				
				#mobileNavLabel{
					color: $color-darkBlue;
				}
				
			}
			
		}
		#mobileNavLabel {
			color: #fff;
			width: 100%;
		    text-align: center;
		    line-height: 39px;
		    font-size: 20px;
		}
		.mainNavContainer{
			overflow: hidden;
			//position: sbsolute;
			display: none;
			#mainNav{

				margin-top: 46px;
				li {
					float: none;
					background-color: $color-darkBlue;
					border: 1px solid $color-orange;
					
					&:hover{
						background-color: $color-orange;
						color: $color-darkBlue;
					}
				}
				.pageReload {
					display: none;
				}
			}
		}	
		
	}


	
	#cartDetailDisplay h1 .cartDetailFurtherCartsContainer {
		margin-top: 20px;
	}
	.wrapperUserNav #mainSearchRight{
		.marginLeft70{
			margin-left: 0;
		}
		margin-right: 0;
	}
	.wrapperUserNav #mainSearchRight #searchResultContainer #searchResultGrid {
		grid-template-columns: auto 0px auto 1px auto;
		
		.resultImage {
			display: none;
		}
	}


	#itemDetailDisplay {
		
		.itemDetailBtnContainerTopRight {
			margin-top: 20px;
			float: left;	
		}
		#itemDetailGridItemTypes{
			display: table;
			.itemTypeBox {
				margin-right: 5px;
				max-width: 100%;
				margin-bottom: 10px;
			}
		}
	}
	#listItemsGrid{
		display: table;
		.itemContainer{
			.itemDetailContainer{
				.imgContainer{
					width: 75px;
					img{
						//height: 50px;
					}
				}
			}
		}
	}

	#home #homeContainer .homeSlideContainer .main .slick-slide {
		//max-width: 500px !important;
		
	}

	#home #homeContainer .homeSlideContainer .action {
		//max-width: 680px;
		//text-align: justify;
		
		a {
			display: block;
		}
			
	}

	#chatBoxRight {
		display: none;
		max-width: 300px;
		top: 40px;
		.chatWrapper #chatContentCol {
			max-width: 260px;
			
			#chatContentContainer #chatMsgContainer {
				max-height: 210px;
			}
		}
	}

}

//VARIABLES
$accent-theme-color: #119DA4;

$btn-bg-1: #880044;

$btn-hover-effect-padding-vert: 12px;
$btn-hover-effect-padding-horz: 26px;
$icon-block-width: 40px;

//MIXINS

//transitions mixin
@mixin transition-mix($property: all, $duration: 0.2s, $timing: linear, $delay: 0s) {
  transition-property: $property;
  transition-duration: $duration;
  transition-timing-function: $timing;
  transition-delay: $delay;
}

//position absolute mixin
@mixin position-absolute ($top: null, $left: null, $right: null, $bottom: null) {
  position: absolute;
  top: $top;
  left: $left;
  right: $right;
  bottom: $bottom;
}
.boxBtnContainer {
	margin-top: 20px;
}

.contentBtnContainer {
	
}

.buttonEff {
	background-color: #fff;
	text-decoration: none;
	color: $color-darkBlue;
	//border: 1px dashed $color-darkBlue;
	//border: 1px dotted $color-darkBlue;
	border: 1px solid $color-darkBlue;
	font-size: 14px;
	//box-shadow: 2px 2px $color-orange;
	//border: none;
	cursor: pointer;
	border-radius: 5px;
	//box-shadow: 0 0 10px rgba(0,0,0,0.2);
	
	.fas, .fal {
		font-size: 20px;
	}
	
	.fa-spinner {
		display: none;
		//margin-left: -20px;
		//margin-right: 5px;
		position: absolute;
		right: 0;
		margin-right: 5px;
		//padding-left: 7px;
		
		//font-size: 20px;
		//float: right;
	}
	

}
	.btnClicked {
//	display: none !important;
		.fa-spinner {
			display: block !important;
			
		}
	}

	

/* don't forget to add your own background-color and text color */
.btn-hover-effect {
  @include transition-mix();
  
  position: relative;
  
  display: block;
  align-items: center;
  //padding: $btn-hover-effect-padding-vert $btn-hover-effect-padding-horz;
  
  padding: 12px 26px 12px 40px;
  
  transform: translateZ(0);
  
  text-decoration: none;
  
  overflow: hidden;



}

/* effect 1 styles */
.btn-hover-effect--effect-1 {  
  
  /* overlay block styles */
  &:before {
    @include position-absolute($top: 0, $left: 0);
    @include transition-mix($property: transform);
    
    display: block;
    width: 100%;
    height: 100%;
    
    content: '';
    
    //background-color: rgba(255,255,255, .2);
    background-color: $color-orange;
	opacity: 0.2;
    
    transform: scale(0, 1);
    transform-origin: right top;
    
    z-index: -1;
  }
  
  /* icon styles */
  &:after {
    @include position-absolute($top: 1px, $right: (- $icon-block-width));
    @include transition-mix();
    
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: $icon-block-width;
    
    //content: '\f105';
    //content: '\f217';
    
    
    font: {
      family: "Font Awesome 5 Free";
      size: 25px;
      weight: 900;
    }
    line-height: inherit;
    opacity: 0;
    
   //icon always visible
	left: 0;
    opacity: 1;
    
  }
  
  //hover styles
  &:hover {
    //padding-left: $btn-hover-effect-padding-horz - 10;
    //padding-right: $btn-hover-effect-padding-horz + 10;
    
    &:before {
      transform: scale(1, 1);
      
      transform-origin: left top;
    }
    
    &:after {
      right: 0;
      
      opacity: 1;
    }
   	.cartSpanEdit {
   		color: $color-green;
   		text-decoration: underline;
   		
   	}
  }
  
}
.adminInputPrice {
	color: red;
	width: 60px;
	text-align: right;
	
}

.adminTableShowCheckoutDB, .itemListTableContainer, {
	  font-size: 14px;
	  
	  table {
	  	width: 1050px;
	  	padding: 3px;
	  	border-spacing: 0;
	  	border-collapse:collapse;
	  }
	  
	  th {
	    text-align: left;
	  }
	 .priceTh {
	 	text-align: right;
	 }
	  .orderedGreen {
	   // background: #a4ff9b !important;
	    background: rgba(164,255,155,0.5);
	
	  }
	 .itemCounterChangeTo{
	 	width:50px;
	 }
	  .orderedHeadTR, .trRow {
	  	border-top: 1px solid #000;
	    &:hover {
	      background: $color-orange !important;
	     // color: #fff;
	    }
	  }
}
.pageCopyCartContainer{
	table{
		 width: 1050px;
	}
}

.pageTextContainer{
	.pageTextContainerEntry{
		textarea {
			width: 600px;
		}
	}
}

.fancyBoxLoginList {
	
	tr {
		&:hover{
			background: $color-gray;
		}
	}
	
	.activeUserMore {
		cursor: pointer;
		&:hover {
			color: $color-orange;
		}
	}
}

#fancyBoxItemPosRabattProzent {
	input {
		width: 80px;
		text-align: right;
	}
}
#fancyBoxCartAddDiscountPosition, #fancyBoxDiscountItems {
	width: 600px;
}
#fancyBoxAddSpecialItemPos {
	width: 1100px;
	select {
		font-size: 14px;
		padding: 5px;
	}
}

.rotate146{
	transform: rotate(146deg);
}
.textAlignCenter {
	text-align: center;
}
.btnimgUps {
		background-image: url("/img/ups_logo.gif");
		background-repeat: no-repeat;
		background-size: 24px;
		background-position:  5% 50%;
	
}
.btnIconNewCart {
	&:after {
		content: '\f217';
	}
}
.btnIconAddToCart {
	&:after {
		content: '\f218';
	}
}
.btnIconOrderCart {
	&:after {
		content: '\f07a';
	}
}
.btnIconAddToCartExclamation {
	&:after {
		content: '\f071';
		color: #ffde00;
	}
}
.btnIconOrderCartArrow {
	line-height: 24px;
	&:after {
		content: '\f061';
	}
}
.btnIconAddToOffer {
	&:after {
		content: '\f022';
		font-weight: 400;
	}
}
.btnIconOffer {
	&:after {
		content: '\f022';
		font-weight: 400;
	}
}
.btnIconItemRequest {
	&:after {
		content: '\f059';
		font-weight: 400;
	}
}
.btnIconPrint {
	&:after {
		content: '\f02f';
	}
}
.btnIconEdit{
	&:after {
		content: '\f044';
		font-weight: 400;
	}
}
.btnIconDelete {
	&:after {
		content: '\f2ed';
		font-weight: 400;
		color: red;
	}
}
.btnIconEmail {
	&:after {
		content: '\f0e0';
		font-weight: 400;
	}
}
.btnIconEmailAt {
	&:after {
		content: '\f1fa';
	}
}
.btnIconNewAdress {
	&:after {
		content: '\f1ad';
		font-weight: 400;
	}
}
.btnIconUpload {
	&:after {
		content: '\f574';
	}
}
.btnIconUps {
		&:after {
		content: '\f7e0';
	}
	
}
.btnIconSave {
	&:after {
		content: '\f0c7';
	}
}
.btnSortLocked {
	&:after {
		content: '\f163';
	}
}
.btnFillItemPosCommission{
	&:after {
		content: '\f0ca';
	}
}
.btnIconClipboard {
	&:after {
		font-weight: 400;
		content: '\f328';
	}
}
.btnIconEye {
	&:after {
		content: '\f06e';
	}
}
.btnIconExcel {
	&:after {
		font-weight: 400;
		content: '\f1c3';
	}
}
.btnIconPlus {
	&:after {
		content: '\f0fe';
	}
}
.btnIconMinus{
	&:after {
		content: '\f068';
	}
}
.btnIconPhone{
	&:after {
		content: '\f095';
		transform: rotate(146deg);
	}
}
.btnIconCheck{
	&:after {
		content: '\f00c';
	}
}
.btnIconCancel{
	&:after {
		content: '\f00d';
	}
}
.btnIconDeleteEmail{
	color: red;
	&:after {
		top: -1px !important;
		content: '\f00d';
	}
}
.btnIconCompany{
	&:after {
		font-weight: 400;
		content: '\f1ad';
	}
}
.btnIconShippingAdress{
	&:after {
		content: '\f3c5';
	}
}
.btnIconShipping{
	&:after {
		content: '\f0d1';
	}
}
.btnIconCopy{
	&:after {
		font-weight: 400;
		content: '\f0c5';
	}
}
.btnIconUseComment{
	&:after {
		font-weight: 400;
		content: '\f0c5';
	}
}
.btnIconPlus{
	&:after {
		content: '\f067';
	}
}
.btnIconReload{
	&:after {
		content: '\f2f9';
	}
}


.fa-trash-alt {
	&:hover {
		color: red !important;
	}
}
.cartAdressListEditIcon {
	cursor: pointer;
	&:hover {
		color: $color-blue;
	}
}

.cartDetailGridTop {
	button {
		font-size: 16px;
		font-weight: bold;
	}
}

label{
	cursor: pointer;
}
html.no-cssgrid {
  /* IE/Edge specific styles go here - hide HTML5 content and show Flash content */
  	.itemTypeBox{
	  	margin-right: 20px;
	  	
	  	.contentGrid {
	  		display: -ms-grid;
	  		-ms-grid-columns: 125px auto;
	  		
	  		div:nth-child(2n+1){
	  			-ms-grid-column: 1;
	  			margin-top: 10px;
	  		}
	  		div:nth-child(2n+2){
	  			-ms-grid-column: 2;
	  			margin-top: 10px;
	  		}
	  		.rowBtn {
	  			margin-top: 10px;
	  			-ms-grid-column: 1 !important;
	  			-ms-grid-column-span: 2;
	  		}
	  	} 	
	}

	.cartDetailGridTop{
		display: -ms-grid;
  		-ms-grid-columns: 1fr 1fr 1fr;
  		
  		div:nth-child(3n-2){
  			-ms-grid-column: 1;
  			//-ms-grid-column-span: 2;
  			margin-bottom: 10px;
  			margin-right: 10px;
  		}
	  		div:nth-child(3n){
  			-ms-grid-column: 3;
  			
  			margin-bottom: 10px;
  			margin-right: 10px;
  			
  		}
  		div:nth-child(3n-1){
  			-ms-grid-column: 2;
  			margin-bottom: 10px;
  			margin-right: 10px;
  		}
  		
	}

	.rowGrid {
		display: -ms-grid;
		-ms-grid-columns: 40px 70px 70px 220px 200px 1fr 140px 140px 50px;
		
		span:nth-child(12n+1){
  			-ms-grid-column: 1;
  			-ms-grid-row: 1; 	
  			-ms-grid-row-span: 2;
  			margin-top: -15px;
  			display: block;
		}
		span:nth-child(12n+2){
  			-ms-grid-column: 2;
  			-ms-grid-row: 1;
  			-ms-grid-row-span: 2;
  			-ms-grid-row-align: center;
  			display: block;
		}
		span:nth-child(12n+3){
  			-ms-grid-column: 3;
  			-ms-grid-row: 1;
  			-ms-grid-row-span: 2;
  			-ms-grid-row-align: center;
  			display: block;
		}
		span:nth-child(12n+4){
  			-ms-grid-column: 4;
  			-ms-grid-row: 1;
  			display: block;
		}
		span:nth-child(12n+5){
  			-ms-grid-column: 5;
  			display: block;
		}
		span:nth-child(12n+6){
  			-ms-grid-column: 6;
  			display: block;
		}
		span:nth-child(12n+7){
  			-ms-grid-column: 7;
  			-ms-grid-row-span: 2;
  			-ms-grid-row-align: center;
  			display: block;
		}
		span:nth-child(12n+8){
  			-ms-grid-column: 8;
  			-ms-grid-row-span: 2;
  			-ms-grid-row-align: center;
  			display: block;
		}
		span:nth-child(12n+9){
  			-ms-grid-column: 9;
  			-ms-grid-row-span: 2;
  			-ms-grid-row-align: center;
  			display: block;
		}
		span:nth-child(12n+10){
  			-ms-grid-column: 4;
  			-ms-grid-column-span: 3;
  			-ms-grid-row: 2;
  			display: block;
		}
		span:nth-child(12n+11){
			-ms-grid-column: 4;
			-ms-grid-row: 3;
			display: block;
		}
			span:nth-child(12n+12){
			-ms-grid-column: 6;
			-ms-grid-column-span: 3;
			-ms-grid-row: 3;
			display: block;
		}
	
		.labelAnimated span{
			display: inline-block;
		}
		
	}
	.rowGridHeader{
		display: -ms-grid;
		-ms-grid-columns: 40px 70px 70px 220px 200px auto 140px 140px 50px;
	}
	
	#searchResultContainer {
		max-height: 700px;
		overflow-y: scroll;
	}
	
	#searchResultGrid {
		display: -ms-grid;
		-ms-grid-columns: auto 30px auto 120px auto;
		height: auto;
		overflow: auto;
		-ms-overflow-style: none;
		
	}

	#listItemsGrid {
		display: -ms-grid;
		-ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
		
		.itemCol:nth-Child(5n+1){
			-ms-grid-column: 1;
		}
		.itemCol:nth-Child(5n+2){
			-ms-grid-column: 2;
		}
		.itemCol:nth-Child(5n+3){
			-ms-grid-column: 3;
		}
		.itemCol:nth-Child(5n+4){
			-ms-grid-column: 4;
		}
		.itemCol:nth-Child(5n+5){
			-ms-grid-column: 5;
		}
	
		.itemCol:nth-Child(n+1):nth-Child(-n+5){
			-ms-grid-row: 1;
		}
		.itemCol:nth-Child(n+6):nth-Child(-n+10){
			-ms-grid-row: 2;
		}
		.itemCol:nth-Child(n+11):nth-Child(-n+15){
			-ms-grid-row: 3;
		}
		.itemCol:nth-Child(n+16):nth-Child(-n+20){
			-ms-grid-row: 4;
		}
		.itemCol:nth-Child(n+21):nth-Child(-n+25){
			-ms-grid-row: 5;
		}
		.itemCol:nth-Child(n+26):nth-Child(-n+30){
			-ms-grid-row: 6;
		}
	
		
	}


	.ms-row1{
		-ms-grid-row: 1;
	}
	.ms-row2{
		-ms-grid-row: 2;
	}
	.ms-row3{
		-ms-grid-row: 3;
	}
	.ms-row4{
		-ms-grid-row: 4;
	}
	.ms-row5{
		-ms-grid-row: 5;
	}
	.ms-row6{
		-ms-grid-row: 6;
	}
	.ms-row7{
		-ms-grid-row: 7;
	}  
  
}

.father-inner {
  position: absolute;
  top: 40px;
  left: 500px;
  right: 5px;
  bottom: 5px;
  
	.draggable {
	width: 300px;
	height: 300px;
	background: #eee;
	border: 1px solid #ccc;
	border-radius: 10px;
	}
	
	.draggable.is-pointer-down {
	  background: #09F;
	}
	
	p {
	  background: #F00;
	}
	
	.child {
	  min-width: 100px;
	  min-height: 100px;
	  height: 100px;
	  width: 100px;
	}
	
	.draggable.is-dragging {
	  opacity: 0.7;
	}
	
	.ui-resizable-helper {
	  border: 1px dotted #20a0ff;
	}
	
	.ui-resizable-se {
	  width: 20px;
	  height: 20px;
	  position: absolute;
	  bottom: 0;
	  right: 0;
	  cursor: se-resize;
	  background: #aaa;
	}
	  
}
.grecaptcha-badge { 
    visibility: hidden;
}
.googleTos{
	font-size: 13px;
	//color: $color-gray;
}


